import { ReactElement } from 'react';
import BasicLayout from '../layouts/BasicLayout';
import { Outlet } from 'react-router-dom';
import ContactUsSectionPage from './ContactUsSection/ContactUsSectionPage';
import ContactUsOnlineEnquiry from './ContactUsSection/ContactUsOnlineEnquiry';
import ContactUsLocationSectionPage from './ContactUsSection/ContactUsLocationSectionPage';
import ContactUsLocationList from './ContactUsSection/ContactUsLocationList';
export const contactUsRoute=[
    {
      id:1,
      path:'#',
      component:<ContactUsSectionPage/>
    },
    {
      id:2,
      path:'overview',
      component:<ContactUsSectionPage/>
    },
    {
      id:3,
      path:'online-enquiry',
      component:<ContactUsOnlineEnquiry/>
    },
    {
      id:3,
      path:'locations',
      component:<ContactUsLocationSectionPage/>
    },
    {
      id:4,
      path:'locations-list',
      component:<ContactUsLocationList/>
    },
  ]
const ContactUsPageRoute = (props:any): ReactElement => {
    const data = props.loginData;
    const permissions = props.permissions;
    return  <BasicLayout>
    <Outlet />
  </BasicLayout>
}
export default ContactUsPageRoute;
