import { FC, useRef, useState } from "react";
import CommonLanguage from "../common/CommonLanguage";
import { twJoin } from "tailwind-merge";
import LabelAndEditor from "./LabelAndEditor";
import LabelAndTextbox from "./LabelAndTextbox";
import LabelAndImage from "./LabelAndImage";

interface ISeoData {
  showPageTitle?:boolean;
  pagetitle_en?: string;
  pagetitle_zh?: string;
  pagetitle_cn?: string;
  metatitle_en: string;
  metatitle_zh: string;
  metatitle_cn: string;
  metadescription_en: string;
  metadescription_zh: string;
  metadescription_cn: string;
  meta_image: string;
  setPageTitleEn?: React.Dispatch<React.SetStateAction<string>>;
  setPageTitleZh?: React.Dispatch<React.SetStateAction<string>>;
  setPageTitleCn?: React.Dispatch<React.SetStateAction<string>>;
  setMetaTitleEn: React.Dispatch<React.SetStateAction<string>>;
  setMetaTitleZh: React.Dispatch<React.SetStateAction<string>>;
  setMetaTitleCn: React.Dispatch<React.SetStateAction<string>>;
  setMetaDescriptionEn: React.Dispatch<React.SetStateAction<string>>;
  setMetaDescriptionZh: React.Dispatch<React.SetStateAction<string>>;
  setMetaDescriptionCn: React.Dispatch<React.SetStateAction<string>>;
  setMetaImage: React.Dispatch<React.SetStateAction<string>>;
  setMetaImageFile:(value: React.SetStateAction<File|undefined>) => void;
}
const SeoData: FC<ISeoData> = ({
  pagetitle_en,
  pagetitle_zh,
  pagetitle_cn,
  metatitle_en,
  metatitle_zh,
  metatitle_cn,
  metadescription_en,
  metadescription_zh,
  metadescription_cn,
  meta_image,
  setPageTitleEn,
  setPageTitleZh,
  setPageTitleCn,
  setMetaTitleEn,
  setMetaTitleZh,
  setMetaTitleCn,
  setMetaDescriptionEn,
  setMetaDescriptionZh,
  setMetaDescriptionCn,
  setMetaImage,
  setMetaImageFile,
  showPageTitle
}) => {
  const pagetitleRef_en: any = useRef(null);
  const pagetitleRef_tc: any = useRef(null);
  const pagetitleRef_sc: any = useRef(null);
  const pagedescriptionRef_en: any = useRef(null);
  const pagedescriptionRef_tc: any = useRef(null);
  const pagedescriptionRef_sc: any = useRef(null);
  const metatitleRef_en: any = useRef(null);
  const metatitleRef_tc: any = useRef(null);
  const metatitleRef_sc: any = useRef(null);
  const metaDescriptionRef_en: any = useRef(null);
  const metaDescriptionRef_tc: any = useRef(null);
  const metaDescriptionRef_sc: any = useRef(null);
  const [activeLang, setActiveLang] = useState<string>("en");
  const changeLanguage = (code: string) => {
    setActiveLang(code);
  };
  return (
    <div className="w-full">
      <div className="w-full">
        <div className="text-left">
          <CommonLanguage
            changeLanguage={changeLanguage}
            activeLang={activeLang}
          />
          <div className="mt-5">
            <div
              className={twJoin(
                activeLang == "en"
                  ? ""
                  : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
              )}
            >
              {showPageTitle==true?
              <LabelAndEditor
                refId={pagetitleRef_en}
                label="Page Title(EN)"
                value={pagetitle_en}
                setValue={setPageTitleEn}
              />:null}
              <LabelAndTextbox
                refId={metatitleRef_en}
                label="Meta Title (EN)"
                value={metatitle_en}
                setValue={setMetaTitleEn}
              />
              <LabelAndEditor
                refId={metaDescriptionRef_en}
                label="Meta Description (EN)"
                value={metadescription_en}
                setValue={setMetaDescriptionEn}
              />
            </div>
            <div
              className={twJoin(
                activeLang == "tc"
                  ? ""
                  : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
              )}
            >
              {showPageTitle==true?
              <LabelAndEditor
                refId={pagetitleRef_tc}
                label="Page Title(TC)"
                value={pagetitle_zh}
                setValue={setPageTitleZh}
              />:null}
              <LabelAndTextbox
                refId={metatitleRef_tc}
                label="Meta Title (TC)"
                value={metatitle_zh}
                setValue={setMetaTitleZh}
              />
              <LabelAndEditor
                refId={metaDescriptionRef_tc}
                label="Meta Description (TC)"
                value={metadescription_zh}
                setValue={setMetaDescriptionZh}
              />
            </div>
            <div
              className={twJoin(
                activeLang == "sc"
                  ? ""
                  : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
              )}
            >
              {showPageTitle==true?
              <LabelAndEditor
                refId={pagetitleRef_sc}
                label="Page Title(SC)"
                value={pagetitle_cn}
                setValue={setPageTitleCn}
              />:null}
              <LabelAndTextbox
                refId={metatitleRef_sc}
                label="Meta Title (SC)"
                value={metatitle_cn}
                setValue={setMetaTitleCn}
              />
              <LabelAndEditor
                refId={metaDescriptionRef_sc}
                label="Meta Description (SC)"
                value={metadescription_cn}
                setValue={setMetaDescriptionCn}
              />              
            </div>
          </div>
          <LabelAndImage label="Meta Image" value={meta_image} setValue={setMetaImage} setFile={(value:any)=>{
            const url=value?URL.createObjectURL(value):'';
            setMetaImageFile(value);
            setMetaImage(url);
          }}/>
        </div>
      </div>
    </div>
  );
};
export default SeoData;
