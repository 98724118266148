import { twJoin } from "tailwind-merge";
import CommonLanguage from "../../components/common/CommonLanguage";
import { useState } from "react";
import DownloadItem, { DownloadItemType } from "../components/DownLoadItem";
import DownloadItemSectionList from "../components/download-items/DownloadItemSectionList";

const DownloadItemPage = () => {
  const [activeLang, setActiveLang] = useState<string>("en");
  const [DownloadItemsData, setDownloadItemsData] = useState<DownloadItemType>({
    id: 0,
    category:"1",
    title_en: "",
    title_sc: "",
    title_tc: "",
    image_url: "",
    image: undefined,
    icon_url: "",
    icon: undefined,
  });
  const [showList, setShowList] = useState<boolean>(true);
  const changeLanguage = (code: string) => {
    setActiveLang(code);
  };
  return (
    <div
      className={twJoin(
        "text-left px-2 sm:px-4 py-4 bg-white sm:mt-[25px] mt-0 font-poppins text-13 font-semibold text-[#858795] tracking-[-0.03rem] w-full h-full rounded-xl pt-3 pb-8 sm:pt-3 sm:pb-9 "
      )}
    >
      {showList ? (
        <DownloadItemSectionList setShowList={setShowList} />
      ) : (
        <div>
          <CommonLanguage
            changeLanguage={changeLanguage}
            activeLang={activeLang}
          />
          <DownloadItem
            activeLang={activeLang}
            initialData={DownloadItemsData}
            setValue={setDownloadItemsData}
            setShowList={setShowList}
          />
        </div>
      )}
    </div>
  );
};
export default DownloadItemPage;
