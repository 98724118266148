import { FC, useEffect, useRef, useState } from "react";
import { twJoin } from "tailwind-merge";
import LabelAndEditor from "../../components/SeoData/LabelAndEditor";
import LabelAndTextbox from "../../components/SeoData/LabelAndTextbox";
import { ICookie } from "../../app/services/home/home";
interface IData {
  activeLang: string;
  initialData: ICookie;
  setCookieData: React.Dispatch<React.SetStateAction<ICookie>>;
}
const CookiePopupData: FC<IData> = ({ activeLang, initialData,setCookieData }) => {
  const [data,setData]=useState<ICookie>({
    cookie_btn_text_en:'',
    cookie_btn_text_tc:'',
    cookie_btn_text_sc:'',
    cookie_description_en:'',
    cookie_description_tc:'',
    cookie_description_sc:'',
  })
  const cookieDescriptionRef_en: any = useRef(null);
  const cookieDescriptionRef_tc: any = useRef(null);
  const cookieDescriptionRef_sc: any = useRef(null);
  const change_data=(value:any,key:string)=>{
    setData({...data,[key]:value});
    setCookieData({...initialData,[key]:value});
  }
  useEffect(()=>{
    if(initialData){
      setData(initialData)
    }
  },[initialData]);
  return (
    <div>
      <h2 className="text-15 text-black font-bold text-left my-5">
        Cookie Popup
      </h2>
      <div className="mt-5">
        <div
          className={twJoin(
            activeLang == "en"
              ? ""
              : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
          )}
        >
          <LabelAndEditor
            name="cookie_description"
            refId={cookieDescriptionRef_en}
            label="Cookie Description (EN)"
            value={data?.cookie_description_en}
            setValue={(value)=>change_data(value,'cookie_description_en')}
          />
          <LabelAndTextbox
            label="Cookie Button Text (EN)"
            value={data?.cookie_btn_text_en}
            setValue={(value)=>change_data(value,'cookie_btn_text_en')}
          />
        </div>
        <div
          className={twJoin(
            activeLang == "tc"
              ? ""
              : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
          )}
        >
          <LabelAndEditor
            name="cookie_description"
            refId={cookieDescriptionRef_tc}
            label="Cookie Description (Zh)"
            value={data?.cookie_description_tc}
            setValue={(value)=>change_data(value,'cookie_description_tc')}
          />
          <LabelAndTextbox
            label="Cookie Button Text (Zh)"
            value={data?.cookie_btn_text_tc}
            setValue={(value)=>change_data(value,'cookie_btn_text_tc')}
          />
        </div>
        <div
          className={twJoin(
            activeLang == "sc"
              ? ""
              : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
          )}
        >
          <LabelAndEditor
            name="cookie_description"
            refId={cookieDescriptionRef_sc}
            label="Cookie Description (CN)"
            value={data?.cookie_description_sc}
            setValue={(value)=>change_data(value,'cookie_description_sc')}
          />
          <LabelAndTextbox
            label="Cookie Button Text (CN)"
            value={data?.cookie_btn_text_sc}
            setValue={(value)=>change_data(value,'cookie_btn_text_sc')}
          />
        </div>
      </div>
    </div>
  );
};
export default CookiePopupData;
