import { ReactElement, useState } from "react";
import BasicLayout from "../../layouts/BasicLayout";
import Terms from "./Terms";

const TermsRoute = (props: any): ReactElement => {
  const data = props.loginData;
  const permissions = props.permissions;
  return (
    <BasicLayout userData={data} permissions={permissions}>
       <Terms/>
    </BasicLayout>
  );
};

export default TermsRoute;
