import { FC } from "react";
import BasicLayout from "../layouts/BasicLayout";
import { Outlet } from "react-router-dom";
import NewsList from "./views/NewsList";
import NewsComponent from "./views/NewsComponent";

interface INewsRoute {
  loginData: any,
  permissions: any;
}

export const newsRoutes = [{ id: 1, path: "", component: <NewsComponent /> }];

const NewsRoute: FC<INewsRoute> = ({ loginData, permissions }) => {
  const data = loginData;

  return (
    <BasicLayout userData={data} permissions={permissions}>
      <div className="pt-0 pb-[58px]">
        <Outlet />
      </div>
    </BasicLayout>
  );
};

export default NewsRoute;
