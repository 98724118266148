import { twJoin } from "tailwind-merge";
import { useState } from "react";
import LatestNews, { LatestNewsType } from "../components/LatestNews";
import LatestNewsSectionList from "../components/services/LatestNewsSectionList";

const LatestNewsPage = () => {
  const [servicesData, setServicesData] = useState<LatestNewsType>({
    id: 0,
    meta_title_en: "",
    meta_title_tc: "",
    meta_title_sc: "",
    meta_description_en: "",
    meta_description_tc: "",
    meta_description_sc: "",
    meta_image: "",
    category: "1",
    title_en: "",
    title_sc: "",
    title_tc: "",
    description_en: "",
    description_sc: "",
    description_tc: "",
    image: undefined,
    image_url: "",
    image_list: undefined,
    image_url_list: [
      "https://satshkphase2.visibleone.pro/_next/image?url=https%3A%2F%2Fs3-alpha-sig.figma.com%2Fimg%2F755d%2F6a4c%2Ff85d0e655df354d97e9a0aab41912aec%3FExpires%3D1734307200%26Key-Pair-Id%3DAPKAQ4GOSFWCVNEHN3O4%26Signature%3DMUwItnCMMcw0KPXYtAO6FsC9uY2Qa2oj2RPweDEiaRQnroL-b77LqtgDdd8rFbrvx-Q9DQ0geXPjlYBrgzuNqVmqf1594c6kevG9T74pyy9AgZK1H4U5ei3YaKIjpMvqpyuKcHSYbhxShCE3lw1vIJmOdGb9Bh~FlG0GKJjIbRH0S-HCrIfvaUmc0Y6oR~ZLJ-x0jSLe0WAlmU~8R5ZWyw4UfvAIptwRKQr41NYEGsynTmlYhp2PQoStekD4nuFaIhZdbyuvbkVvj0vWMXQVcM-3nONhngB98gKxUSAVxy0IQUmsLLTCZuNOPy5JSlNiltPkp-ehqH21tlZR0oTAvA__&w=1080&q=75",
      "https://fakeimg.pl/200x100"
    ],
  });

  const [showList, setShowList] = useState<boolean>(true);

  return (
    <div
      className={twJoin(
        "text-left px-2 sm:px-4 py-4 bg-white sm:mt-[25px] mt-0 font-poppins text-13 font-semibold text-[#858795] tracking-[-0.03rem] w-full h-full rounded-xl pt-3 pb-8 sm:pt-3 sm:pb-9 "
      )}
    >
      {showList ? (
        <LatestNewsSectionList setShowList={setShowList} />
      ) : (
        <div>
          <LatestNews
            initialData={servicesData}
            setValue={setServicesData}
            setShowList={setShowList}
          />
        </div>
      )}
    </div>
  );
};
export default LatestNewsPage;
