import { FC, useEffect, useRef, useState } from "react";
import CommonLanguage from "../../components/common/CommonLanguage";
import { twJoin } from "tailwind-merge";
import LabelAndTextbox from "../../components/SeoData/LabelAndTextbox";
import LabelAndImage from "../../components/SeoData/LabelAndImage";
import LabelAndEditor from "../../components/SeoData/LabelAndEditor";
import { CancelIcon, SaveIcon } from "../../components/common/Icons";
import { IContactLocation } from "./ContactUsLocationList";
export interface IMissionData {
    detailData: IContactLocation;
    setDetailData: React.Dispatch<React.SetStateAction<IContactLocation>>;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    handle_submit: (data: IContactLocation) => Promise<void>;
    isSave: boolean;
}
const ContactUsLocationInput: FC<IMissionData> = ({
    detailData,
    setDetailData,
    setShow,
    handle_submit,
    isSave
}) => {
    const [data, setData] = useState<IContactLocation>({
        id: 0,
        map_en: '',
        map_tc: '',
        map_sc: '',
        phone_en: '',
        phone_tc: '',
        phone_sc: '',
        email1_en: '',
        email1_tc: '',
        email1_sc: '',
        email2_en: '',
        email2_tc: '',
        email2_sc: '',
        whatsapp_en: '',
        whatsapp_tc: '',
        whatsapp_sc: '',
        location_en: '',
        location_tc: '',
        location_sc: '',
    });
    const [activeLang, setActiveLang] = useState<string>("en");
    const mapRef = useRef(null);
    const changeLanguage = (code: string) => {
        setActiveLang(code);
    };
    useEffect(() => {
        if (detailData) {
            setData({
                id: 0,
                map_en: detailData?.map_en,
                map_tc: detailData?.map_tc,
                map_sc: detailData?.map_sc,
                phone_en: detailData.phone_en,
                phone_tc: detailData.phone_tc,
                phone_sc: detailData.phone_sc,
                email1_en: detailData.email1_en,
                email1_tc: detailData.email1_tc,
                email1_sc: detailData.email1_sc,
                email2_en: detailData.email2_en,
                email2_tc: detailData.email2_tc,
                email2_sc: detailData.email2_sc,
                whatsapp_en: detailData.whatsapp_en,
                whatsapp_tc: detailData.whatsapp_tc,
                whatsapp_sc: detailData.whatsapp_sc,
                location_en: detailData.location_en,
                location_tc: detailData.location_tc,
                location_sc: detailData.location_sc,
            });
        }
    }, [detailData]);
    const change_data = (value: any, key: string) => {
        if (data) {
            setData({ ...data, [key]: value })
        }
    }
    return <div>
        <CommonLanguage
            changeLanguage={changeLanguage}
            activeLang={activeLang}
        />
        <div className="mt-5">
            <div
                className={twJoin(
                    activeLang == "en"
                        ? ""
                        : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
                )}
            >
                <div className="">
                    <LabelAndEditor refId={mapRef} label="Map EN" value={data?.map_en} setValue={(value) => change_data(value, 'map_en')} />
                </div>
                <div className="mt-5">
                    <LabelAndTextbox label="Phone EN" value={data?.phone_en} setValue={(value) => change_data(value, 'phone_en')} />
                </div>
                <div className="mt-5">
                    <LabelAndTextbox label="Email1 EN" value={data?.email1_en} setValue={(value) => change_data(value, 'email1_en')} />
                </div>
                <div className="mt-5">
                    <LabelAndTextbox label="Email2 EN" value={data?.email2_en} setValue={(value) => change_data(value, 'email2_en')} />
                </div>
                <div className="mt-5">
                    <LabelAndTextbox label="WhatsApp EN" value={data?.whatsapp_en} setValue={(value) => change_data(value, 'whatsapp_en')} />
                </div>
                <div className="mt-5">
                    <LabelAndTextbox label="Location EN" value={data?.location_en} setValue={(value) => change_data(value, 'location_en')} />
                </div>
            </div>
            <div
                className={twJoin(
                    activeLang == "tc"
                        ? ""
                        : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
                )}
            >
                 <div className="">
                    <LabelAndEditor refId={mapRef} label="Map TC" value={data?.map_tc} setValue={(value) => change_data(value, 'map_tc')} />
                </div>
                <div className="mt-5">
                    <LabelAndTextbox label="Phone TC" value={data?.phone_tc} setValue={(value) => change_data(value, 'phone_tc')} />
                </div>
                <div className="mt-5">
                    <LabelAndTextbox label="Email1 TC" value={data?.email1_tc} setValue={(value) => change_data(value, 'email1_tc')} />
                </div>
                <div className="mt-5">
                    <LabelAndTextbox label="Email2 TC" value={data?.email2_tc} setValue={(value) => change_data(value, 'email2_tc')} />
                </div>
                <div className="mt-5">
                    <LabelAndTextbox label="WhatsApp TC" value={data?.whatsapp_tc} setValue={(value) => change_data(value, 'whatsapp_tc')} />
                </div>
                <div className="mt-5">
                    <LabelAndTextbox label="Location TC" value={data?.location_tc} setValue={(value) => change_data(value, 'location_tc')} />
                </div>
            </div>
            <div
                className={twJoin(
                    activeLang == "sc"
                        ? ""
                        : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
                )}
            >
                <div className="">
                    <LabelAndEditor refId={mapRef} label="Map SC" value={data?.map_sc} setValue={(value) => change_data(value, 'map_sc')} />
                </div>
                <div className="mt-5">
                    <LabelAndTextbox label="Phone SC" value={data?.phone_sc} setValue={(value) => change_data(value, 'phone_sc')} />
                </div>
                <div className="mt-5">
                    <LabelAndTextbox label="Email1 SC" value={data?.email1_sc} setValue={(value) => change_data(value, 'email1_sc')} />
                </div>
                <div className="mt-5">
                    <LabelAndTextbox label="Email2 SC" value={data?.email2_sc} setValue={(value) => change_data(value, 'email2_sc')} />
                </div>
                <div className="mt-5">
                    <LabelAndTextbox label="WhatsApp SC" value={data?.whatsapp_sc} setValue={(value) => change_data(value, 'whatsapp_sc')} />
                </div>
                <div className="mt-5">
                    <LabelAndTextbox label="Location SC" value={data?.location_sc} setValue={(value) => change_data(value, 'location_sc')} />
                </div>
            </div>
        </div>
        <div className="mt-5 last:mb-0 flex items-center">
            <button
                className={twJoin(
                    isSave ? "opacity-50 pointer-events-none" : "",
                    "flex items-center justify-center bg-vorpblue mr-2.5 text-white font-primary text-14 font-medium rounded-md py-[8.5px] px-3"
                )}
                onClick={() => handle_submit(data)}
            >
                <SaveIcon className="mr-2" /> Save
            </button>
            <button
                className="flex items-center justify-center bg-offwhite font-primary text-14 font-medium text-black2 rounded-md py-[8.5px] px-3"
                onClick={() => {
                    setShow(true);
                }}
            >
                <CancelIcon className="mr-2" color="#9E3039" />
                Cancel
            </button>
        </div>
    </div>
}
export default ContactUsLocationInput;