import { FC, useState } from "react";
import { twJoin } from "tailwind-merge";
interface ILabelAndEditor {
  label: string;
  value: string | undefined;
  setValue?: (value: React.SetStateAction<any>) => void;
  setFile: (value: React.SetStateAction<File | undefined>) => void;
  margin?: string;
}
const LabelAndImage: FC<ILabelAndEditor> = ({
  label,
  value,
  setValue,
  setFile,
  margin
}) => {
  const [filename,setFileName]=useState<string>('');
  return (
    <div className={twJoin(margin ? margin : "mt-5", " last:mb-0")}>
      <label className="text-black2 text-15 font-medium font-primary mb-[0.688rem] block">
        {label}
      </label>
      {value
        ? <img
            src={value}
            className="w-full max-w-[100px] mb-5 aspect-[100/100] object-cover"
          />
        : null}
      <div className=" border border-gray-300 text-gray-400">
        <div className="flex items-center p-4 relative">
          <input
            type="file"
            name="meta_image"
            onChange={event => {
              if (event.target.files) {
                setFileName(event.target.files[0]?.name)
                setFile(event.target.files[0]);
                var url = URL.createObjectURL(event.target.files[0]);
               // setValue(url ? url : "");
              }
            }}
            className="focus:outline-none absolute opacity-0 focus-visible:outline-none w-full rounded py-3.5 px-3 border border-goalinputborder text-black2 text-14 font-medium font-primary placeholder:text-graydark"
          />
          <button className="">Choose file</button>
          <p className="font-primary text-10 sm:text-12 text-black2 ml-3">
           {filename?filename:(value?value.split('/')[value.split('/').length-1]:'')}
          </p>
        </div>
      </div>
    </div>
  );
};
export default LabelAndImage;
