import { redirectToLogin } from "./annoucement-sheet";
import { api, language } from "./api";
import authHeader from "./auth-header";
export type Header = {
  id: number;
  title_en: string;
  title_tc: string;
  title_sc: string;
  type: string;
};
export type HeaderData={
    current_page:number,
    data:Header[],
    total:number;
}
export type PaginationData = {
  page: number;
  limit: number | string | undefined;
  search: string | number;
  sort?: string;
};
export type MenuData = {
  menu_list: Header[];
};

export const manualApi = api.injectEndpoints({
  endpoints: (build) => ({
    getMenus: build.query<HeaderData, PaginationData>({
      query: ({ limit, page, search }) => ({
        url: `menus?per_page=${limit}&page=${page}&search=${search}`,
        headers: authHeader(),
      }),
      keepUnusedDataFor:0,
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      providesTags: () => [{ type: "Header" as const, id: "LIST" }],
    }),
    createManual: build.mutation<MenuData, Header>({
      query(body) {
        return {
          headers: authHeader(),
          url: `menus`,
          body,
          method: "POST",
        };
      },
    }),
    updateMenu: build.mutation<Header, Header>({
      query(body) {
        return {
          headers: authHeader(),
          url: `menus`,
          body,
          method: "POST",
        };
      },
    }),
  }),
});

export const {
  useGetMenusQuery,
  useUpdateMenuMutation
} = manualApi;

export const {
  endpoints: { getMenus, updateMenu },
} = manualApi;
