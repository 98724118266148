import { FC, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { baseObj } from "../../utils/constants";
import { ColumnFiltersState, PaginationState } from "@tanstack/react-table";
import { templateList } from "../../active-time-report/views/ActiveTimeReport";
import { useGetServiceInfoMutation, useGetServicesListQuery } from "../../app/services/services/services";
import { endpointUrl } from "../../app/services/api";
import axios from "../../axios";
import { twJoin } from "tailwind-merge";
import Button from "../../components/common/Button";
import Service from "../components/Service";
import ServiceSectionTable from "../../homepage/sections/Services/ServiceSectionTable";
import ServicePageSectionTable from "./ServicePageSectionTable";
export interface IServicePageData {
    id: number;
    title_en: string;
    title_tc: string;
    title_sc: string;
    image: File | undefined;
    image_url: string;
    icon: File | undefined;
    icon_url: string;
    page_type: string;
}
const ServicePageList = ({
}) => {
    const [editRowData, setEditRowData] = useState<any>();
    const [detailData, setDetailData] = useState<IServicePageData>({
        id: 0,
        title_en: '',
        title_tc: '',
        title_sc: '',
        image: undefined,
        image_url: '',
        icon: undefined,
        icon_url: '',
        page_type: 'passenger-service'
    });
    const [show, setShow] = useState<boolean>(true);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [viewCols, setCols] = useState<any>([]);
    const [isLayoutControlOpen, setIsLayoutControlOpen] = useState<boolean>(
        false
    );
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    let [localModuleId, setLocalModuleId] = useState<number>(0);
    const [data, setData] = useState<baseObj[]>([]);
    const [itemCount, setItemCount] = useState<number>(0);
    const [tempList, setTempList] = useState<any>(templateList);
    const [hiddenFields, setHiddenFields] = useState<string[]>([]);
    const [globalSearch, setGlobalSearch] = useState<string>("");
    const [errorPopup, setErrorPopup] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [isSave, setIsSave] = useState<boolean>(false);
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 20
    });
    const [getSustainabilityInfo] = useGetServiceInfoMutation();
    const { data: services } = useGetServicesListQuery({
        page: currentPage,
        limit: pagination.pageSize,
        search: globalSearch
    })
    useEffect(() => {
        if (services) {
            setData(services?.data);
            setItemCount(services.total);
        }
    }, [services])
    const handle_submit = async (data: IServicePageData) => {
        const formData = new FormData();
        if (data) {
            formData.set('id', data.id?.toString() ?? 0)
            formData.set('title_en', data.title_en);
            formData.set('title_tc', data.title_tc);
            formData.set('title_sc', data.title_sc);
            formData.set('page_type', data.page_type!=''?data.page_type:'passenger-service');
            if (data.image) {
                formData.set('image', data.image);
            }
            if (data.icon) {
                formData.set('icon', data.icon);
            }
            const url = detailData?.id ? 'update-service-content' : 'service-content';
            await axios(endpointUrl + url, {
                method: "POST",
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                data: formData,
            })
                .then((res: any) => {
                    if (res?.data?.status == true) {
                        getSustainabilityInfo({
                            page: currentPage,
                            limit: pagination.pageSize,
                            search: '',
                            type: data.page_type!=''?data.page_type:'passenger-service'
                        }).then((res: any) => {
                            setData(res?.data?.data)
                        })
                        toast(res?.data?.message, {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                            type: "success",
                        });
                        setShow(true);
                    } else {
                        setErrorPopup(true);
                        setMessage(res?.response?.data?.message);
                    }
                    setIsSave(false);
                })
                .catch((err) => {
                    setErrorPopup(true);
                    setMessage(err?.data?.message);
                });
        }

    }
    const clear_data = () => {
        setDetailData({
            id: 0,
            title_en: '',
            title_tc: '',
            title_sc: '',
            image: undefined,
            image_url: '',
            icon: undefined,
            icon_url: '',
            page_type: ''
        })
    }
    return <div
        className={twJoin(
            "px-2 sm:px-4 py-4 bg-white sm:mt-[25px] mt-0 font-poppins text-13 font-semibold text-[#858795] tracking-[-0.03rem] w-full h-full rounded-xl pt-3 pb-8 sm:pt-3 sm:pb-9 "
        )}
    >
        <div className="text-left">
            {show == false ? <Service
                initialData={detailData}
                setValue={setDetailData}
                setShowList={setShow}
                handle_submit={handle_submit}
            /> :
                <>
                    <div className="items-center xs:mt-1 sm:mt-1 flex justify-end mb-3">
                        <Button
                            label="+ Add New"
                            onClick={() => { clear_data(); setShow(false) }}
                            type="button"
                            variant="primary"
                            customClass="py-[3px] my-1 px-2 focus:outline-none hover:bg-vorpmyanmar hover:border-vorpmyanmar hover:text-vorpblue 2xl:text-[14px] text-12 leading-5 font-medium text-white"
                            size="base"
                        />
                    </div>
                    <div className="text-left">
                        <ServicePageSectionTable
                            // columnsData={columnsData}
                            isLayoutControlOpen={isLayoutControlOpen}
                            setIsLayoutControlOpen={setIsLayoutControlOpen}
                            globalSearch={globalSearch}
                            setGlobalSearch={setGlobalSearch}
                            columnFilters={columnFilters}
                            setColumnFilters={setColumnFilters}
                            pagination={pagination}
                            data={data}
                            setData={setData}
                            setPagination={setPagination}
                            hiddenFields={hiddenFields ? hiddenFields : []}
                            setHiddenFields={setHiddenFields}
                            checkManagement={true}
                            total={itemCount}
                            localModuleId={localModuleId}
                            // visArray={visArray}
                            templateList={tempList}
                            setCurrentView={() => 1}
                            setCols={setCols}
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                            setServicesData={setDetailData}
                            setShow={setShow}
                        />
                    </div>
                </>
            }
        </div>
    </div>
}
export default ServicePageList;