import { FC, useEffect, useRef, useState } from "react";
import { twJoin } from "tailwind-merge";
import LabelAndEditor from "../../components/SeoData/LabelAndEditor";
import LabelAndNumber from "../../components/SeoData/LabelAndNumber";
import LabelAndImage from "../../components/SeoData/LabelAndImage";
import CommonLanguage from "../../components/common/CommonLanguage";
import { CancelIcon, SaveIcon } from "../../components/common/Icons";
import axios from "../../axios";
import { endpointUrl } from "../../app/services/api";
import { ToastContainer, toast } from "react-toastify";
import ErrorPopup from "../../components/ErrorPopup/ErrorPopup";
import { useGetCompanyInfoDataMutation } from "../../app/services/company/company";

export interface ICompanyHistoryInput {
    history: ICompanyHistoryInputFields | undefined;
    setHistory: React.Dispatch<React.SetStateAction<ICompanyHistoryInputFields>>;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    handle_submit: (data: ICompanyHistoryInputFields | undefined) => Promise<void>;
    isSave: boolean;
}
export interface ICompanyHistoryInputFields {
    id: number;
    start_year: number;
    end_year: number;
    image: File | undefined;
    image_url: string;
    description_en: string;
    description_tc: string;
    description_sc: string;

}
const CompanyHistoryInput: FC<ICompanyHistoryInput> = ({
    history,
    setHistory,
    setShow,
    handle_submit,
    isSave
}) => {    
    const [data, setData] = useState<ICompanyHistoryInputFields | undefined>(history);
    const description_en_Ref = useRef();
    const description_tc_Ref = useRef();
    const description_sc_Ref = useRef();
    const [activeLang, setActiveLang] = useState<string>("en");    
    const change_data = (value: any, key: string) => {
        if (data) {
            if (key == 'image') {
                const url=value?URL.createObjectURL(value):'';
                setData((prev) => {
                    if (prev) {
                        return {
                            ...prev,
                            image: value,
                            image_url: url
                        }
                    }
                });
                return;
            }
            setData({ ...data, [key]: value });

        }
        if (history) {
            if (key == 'image') {
                const url=value?URL.createObjectURL(value):'';
                setHistory((prev: any) => {
                    if (prev) {
                        return {
                            ...prev,
                            image: value,
                            image_url: url
                        }
                    }
                });
                return;
            }
            setHistory({ ...history, [key]: value });
        }
    }
    const changeLanguage = (code: string) => {
        setActiveLang(code);
    };
    useEffect(() => {
        if (history) {
            const image:any=history?.image;
            setData({
                id:history.id,
                description_en:history.description_en,
                description_tc:history.description_tc,
                description_sc:history.description_sc,
                start_year:history.start_year,
                end_year:history.end_year,
                image:undefined,
                image_url:image??''
            });
        }
    }, [history]);
   
    return <div>        
        <CommonLanguage
            changeLanguage={changeLanguage}
            activeLang={activeLang}
        />
        <div className="mt-5">
            <div
                className={twJoin(
                    activeLang == "en"
                        ? ""
                        : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
                )}
            >
                <LabelAndEditor refId={description_en_Ref} label="Description EN" value={data?.description_en} setValue={(value) => change_data(value, 'description_en')} />

            </div>
            <div
                className={twJoin(
                    activeLang == "tc"
                        ? ""
                        : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
                )}
            >
                <div className="">
                    <LabelAndEditor refId={description_tc_Ref} label="Description TC" value={data?.description_tc} setValue={(value) => change_data(value, 'description_tc')} />
                </div>
            </div>
            <div
                className={twJoin(
                    activeLang == "sc"
                        ? ""
                        : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
                )}
            >
                <div className="">
                    <LabelAndEditor refId={description_sc_Ref} label="Description SC" value={data?.description_sc} setValue={(value) => change_data(value, 'description_sc')} />
                </div>
            </div>
            <div className="mt-5">
                <LabelAndNumber label="Start Year" value={data?.start_year ?? 0} setValue={(value) => change_data(value, 'start_year')} />
            </div>
            <div className="mt-5">
                <LabelAndNumber label="End Year" value={data?.end_year ?? 0} setValue={(value) => change_data(value, 'end_year')} />
            </div>
            <div className="mt-5">
                <LabelAndImage label="Image" value={data?.image_url} setValue={(value) => change_data(value, 'image_url')} setFile={(value) => change_data(value, 'image')} />
            </div>
        </div>
        <div className="mt-5 last:mb-0 flex items-center">
            <button
                className={twJoin(
                     isSave ? "opacity-50 pointer-events-none" : "",
                    "flex items-center justify-center bg-vorpblue mr-2.5 text-white font-primary text-14 font-medium rounded-md py-[8.5px] px-3"
                )}
                onClick={() => handle_submit(data)}
            >
                <SaveIcon className="mr-2" /> Save
            </button>
            <button
                className="flex items-center justify-center bg-offwhite font-primary text-14 font-medium text-black2 rounded-md py-[8.5px] px-3"
                onClick={() => {
                    setShow(true);
                }}
            >
                <CancelIcon className="mr-2" color="#9E3039" />
                Cancel
            </button>
        </div>
    </div>
}
export default CompanyHistoryInput;