import { FC, useEffect, useRef, useState } from "react";
import CommonLanguage from "../../components/common/CommonLanguage";
import { twJoin } from "tailwind-merge";
import LabelAndTextbox from "../../components/SeoData/LabelAndTextbox";
import LabelAndImage from "../../components/SeoData/LabelAndImage";
import LabelAndEditor from "../../components/SeoData/LabelAndEditor";
export interface IOverviewData {
    overview: IOverview | undefined;
    activeLang: string;
    setActiveLang: React.Dispatch<React.SetStateAction<string>>;
    setOverview: React.Dispatch<React.SetStateAction<IOverview>>;
}
export interface IOverview {
    title_en: string;
    title_tc: string;
    title_sc: string;
    subtitle_en: string;
    subtitle_tc: string;
    subtitle_sc: string;
    description_en: string;
    description_tc: string;
    description_sc: string;
}
const OverviewSection: FC<IOverviewData> = ({
    overview,
    activeLang,
    setActiveLang,
    setOverview
}) => {
    const description_en_Ref = useRef();
    const description_tc_Ref = useRef();
    const description_sc_Ref = useRef();
    const [data, setData] = useState<IOverview>({
        title_en:'',
        title_tc:'',
        title_sc:'',
        subtitle_en:'',
        subtitle_tc:'',
        subtitle_sc:'',
        description_en:'',
        description_tc:'',
        description_sc:'',
    });
    const changeLanguage = (code: string) => {
        setActiveLang(code);
    };
    useEffect(() => {
        if (overview) {
            setData(overview);
        }
    }, [overview]);
    const change_data = (value: any, key: string) => {
        if (overview) {
            setOverview({ ...overview, [key]: value })
        }
    }
    return <div>

        <div className="mt-5">
            <div
                className={twJoin(
                    activeLang == "en"
                        ? ""
                        : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
                )}
            >

                <LabelAndTextbox label="Title EN" value={data?.title_en} setValue={(value) => change_data(value, 'title_en')} />
                <div className="mt-5">
                    <LabelAndTextbox label="Subtitle EN" value={data?.subtitle_en} setValue={(value) => change_data(value, 'subtitle_en')} />
                </div>
                <div className="mt-5">
                    <LabelAndEditor refId={description_en_Ref} label="Description EN" value={data?.description_en} setValue={(value) => change_data(value, 'description_en')} />
                </div>
            </div>
            <div
                className={twJoin(
                    activeLang == "tc"
                        ? ""
                        : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
                )}
            >

                <LabelAndTextbox label="Title TC" value={data?.title_tc} setValue={(value) => change_data(value, 'title_tc')} />
                <div className="mt-5">
                    <LabelAndTextbox label="Subtitle TC" value={data?.subtitle_tc} setValue={(value) => change_data(value, 'subtitle_tc')} />
                </div>
                <div className="mt-5">
                    <LabelAndEditor refId={description_tc_Ref} label="Description TC" value={data?.description_tc} setValue={(value) => change_data(value, 'description_tc')} />
                </div>
            </div>
            <div
                className={twJoin(
                    activeLang == "sc"
                        ? ""
                        : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
                )}
            >

                <LabelAndTextbox label="Title SC" value={data?.title_sc} setValue={(value) => change_data(value, 'title_sc')} />
                <div className="mt-5">
                    <LabelAndTextbox label="Subtitle SC" value={data?.subtitle_sc} setValue={(value) => change_data(value, 'subtitle_sc')} />
                </div>
                <div className="mt-5">
                    <LabelAndEditor refId={description_sc_Ref} label="Description SC" value={data?.description_sc} setValue={(value) => change_data(value, 'description_sc')} />
                </div>
            </div>
        </div>
    </div>
}
export default OverviewSection;