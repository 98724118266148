import { redirectToLogin } from "../annoucement-sheet";
import { api } from "../api";
import authHeader from "../auth-header";
export interface HomeData {
  airline: IHomeAirline;
  choose_us: IHomeWhyChooseUs;
  cookie: IHomeCookie;
  footer_career: IFooterCareer;
  home: HomeInfo;
  news_updates:IHomeNews;
}
export interface IHomeNews{
  title_en:string;
  title_tc:string;
  title_sc:string;
  sub_title_en:string;
  sub_title_tc:string;
  sub_title_sc:string;
}
export interface IFooterCareer {
  id: number;
  sub_title_en: string;
  sub_title_tc: string;
  sub_title_sc: string;
  title_en: string;
  title_tc: string;
  title_sc: string;
  description_en: string;
  description_tc: string;
  description_sc: string;
  additional_data_en: {
    button1: {
      link: '',
      text: ''
    },
    button2: {
      link: '',
      text: ''
    }
  };
  additional_data_tc: {
    button1: {
      link: '',
      text: ''
    },
    button2: {
      link: '',
      text: ''
    }
  };
  additional_data_sc: {
    button1: {
      link: '',
      text: ''
    },
    button2: {
      link: '',
      text: ''
    }
  };
}
export interface IHomeAirline {
  id: number;
  sub_title_en: string;
  sub_title_tc: string;
  sub_title_sc: string;
  title_en: string;
  title_tc: string;
  title_sc: string;
  description_en: string;
  description_tc: string;
  description_sc: string;
  image: File | File;
  additional_data_en: string;
  additional_data_tc: string;
  additional_data_sc: string;
}
export interface IHomeWhyChooseUs {
  id: number;
  page_id: number;
  section_order: number;
  sub_title_en: string;
  sub_title_tc: string;
  sub_title_sc: string;
  title_en: string;
  title_tc: string;
  title_sc: string;
  description_en: string;
  description_tc: string;
  description_sc: string;
  image: File | undefined;
  additional_data_en: string;
  additional_data_tc: string;
  additional_data_sc: string;
}
export interface IHomeCookie {
  description_en: string;
  description_tc: string;
  description_sc: string;
  btn_text_en: string;
  btn_text_tc: string;
  btn_text_sc: string;
  additional_data_en: {
    btn_text: string;
  };
  additional_data_tc: {
    btn_text: string;
  };
  additional_data_sc: {
    btn_text: string;
  };
}
export interface IHomeBanner {
  title_en: string;
  title_tc: string;
  title_cn: string;
  slider_desktop_image: any;
}
export interface IAirline {
  airline_sub_title_en: string;
  airline_sub_title_tc: string;
  airline_sub_title_sc: string;
  airline_title_en: string;
  airline_title_tc: string;
  airline_title_sc: string;
  airline_description_en: string;
  airline_description_tc: string;
  airline_description_sc: string;
  airline_btn_text_en: string;
  airline_btn_text_tc: string;
  airline_btn_text_sc: string;
  airline_btn_link_en: string;
  airline_btn_link_tc: string;
  airline_btn_link_sc: string;
}
export interface IWhyChoose {
  choose_title_en: string;
  choose_title_tc: string;
  choose_title_sc: string;
  choose_sub_title_en: string;
  choose_sub_title_tc: string;
  choose_sub_title_sc: string;
  choose_description_en: string;
  choose_description_tc: string;
  choose_description_sc: string;
}
export interface ITitleDescription {
  title_en: string;
  title_tc: string;
  title_sc: string;
  sub_title_en: string;
  sub_title_tc: string;
  sub_title_sc: string;
  description_en: string;
  description_tc: string;
  description_sc: string;
  btn_text_en?: string;
  btn_text_tc?: string;
  btn_text_sc?: string;
  btn_link_en?: string;
  btn_link_tc?: string;
  btn_link_sc?: string;
  additional_data_en?: any;
  additional_data_tc?: any;
  additional_data_sc?: any;
}
export interface ICookie {
  cookie_description_en: string;
  cookie_description_tc: string;
  cookie_description_sc: string;
  cookie_btn_text_en: string;
  cookie_btn_text_tc: string;
  cookie_btn_text_sc: string;
}
export interface IAirlineInfo {
  airline_sub_title_en: string;
  airline_sub_title_tc: string;
  airline_sub_title_sc: string;
  airline_title_en: string;
  airline_title_tc: string;
  airline_title_sc: string;
  airline_description_en: string;
  airline_description_tc: string;
  airline_description_sc: string;
  airline_btn_text_en: string;
  airline_btn_text_tc: string;
  airline_btn_text_sc: string;
  airline_btn_link_en: string;
  airline_btn_link_tc: string;
  airline_btn_link_sc: string;
}
export interface HomeInfo {
  id: number;
  sub_menu_id: number;
  menu_id: number;
  slug: string;
  title_en: string;
  title_tc: string;
  title_sc: string;
  meta_title_en: string;
  meta_title_tc: string;
  meta_title_sc: string;
  meta_description_en: string;
  meta_description_tc: string;
  meta_description_sc: string;
  meta_image: string;
  banner_mobile_image: string;
  banner_desktop_image: string;
  is_slider: number;
}
interface ISlider {
  id: number;
  page_id: number;
  title_en: string;
  title_cn: string;
  title_tc: string;
  slider_mobile_image: string;
  slider_desktop_image: string;
}
export type TData = {
  data: ISlider[];
  total: number;
};
export type PaginationData = {
  page: number;
  limit: number | string | undefined;
  search: string | number;
  sort?: string;
};
export type IWhoAreWeData={
  who_are_we:IWhoAreWe;
}
export type IWhoAreWe = {
  "id": number,
  "page_id": number,
  "section_order": number,
  "unique_name": string,
  "sub_title_en": string,
  "sub_title_tc": string,
  "sub_title_sc": string,
  "title_en":string,
  "title_tc":string,
  "title_sc":string,
  "description_en": string,
  "description_tc": string,
  "description_sc": string,
  "image": string,
  "additional_data_en": {
    "btn_link": string,
    "btn_text": string,
    "list_sign_one": string,
    "list_sign_two": string,
    "list_count_one":string,
    "list_count_two":string,
    "list_title_one":string,
    "list_title_two":string,
    "list_sign_three": string,
    "list_count_three":string,
    "list_title_three":string,
  },
  "additional_data_tc": {
    "btn_link": string,
    "btn_text": string,
    "list_sign_one": string,
    "list_sign_two": string,
    "list_count_one":string,
    "list_count_two":string,
    "list_title_one":string,
    "list_title_two":string,
    "list_sign_three": string,
    "list_count_three":string,
    "list_title_three":string,
  },
  "additional_data_sc": {
    "btn_link": string,
    "btn_text": string,
    "list_sign_one": string,
    "list_sign_two": string,
    "list_count_one":string,
    "list_count_two":string,
    "list_title_one":string,
    "list_title_two":string,
    "list_sign_three": string,
    "list_count_three":string,
    "list_title_three":string,
  }
}
export const footerApi = api.injectEndpoints({
  endpoints: (build) => ({
    getHomeData: build.query<HomeData, void>({
      query: () => ({
        url: `fetch-home`,
        headers: authHeader()
      }),
      keepUnusedDataFor: 0,
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      providesTags: () => [{ type: "Home" as const, id: "LIST" }]
    }),
    getSlider: build.query<TData, PaginationData>({
      query: ({ limit, page, search }) => ({
        url: `sliders/home?per_page=${limit}&page=${page}&search=${search}`,
        headers: authHeader()
      }),
      keepUnusedDataFor: 0,
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      providesTags: () => [{ type: "Slider" as const, id: "LIST" }]
    }),
    getWhoWeAre: build.query<IWhoAreWeData, void>({
      query: () => ({
        url: `fetch-who-are-we`,
        headers: authHeader()
      }),
      keepUnusedDataFor: 0,
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      providesTags: () => [{ type: "Home" as const, id: "LIST" }]
    }),
    deleteSlider: build.mutation<TData, number>({
      query(body) {
        return {
          headers: authHeader(),
          url: `delete-slider`,
          body: {
            id: body
          },
          method: "POST",
        };
      },
    }),
    deleteFooterSocial: build.mutation<TData, number>({
      query(body) {
        return {
          headers: authHeader(),
          url: `delete-footer-social`,
          body: {
            id: body
          },
          method: "POST",
        };        
      },
      invalidatesTags: () => [{ type: 'Footer' }],
    }),
    getWhyChooseData: build.query<TData, PaginationData>({
      query: ({ limit, page, search }) => ({
        url: `home-choose-us?per_page=${limit}&page=${page}&search=${search}`,
        headers: authHeader()
      }),
      keepUnusedDataFor: 0,
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      providesTags: () => [{ type: "WhyChooseUs" as const, id: "LIST" }]
    }),
    deletWhyChooseUs: build.mutation<TData, number>({
      query(body) {
        return {
          headers: authHeader(),
          url: `delete-home-choose-us`,
          body: {
            id: body
          },
          method: "POST",
        };
      },
    }),
    getAirlines: build.query<TData, PaginationData>({
      query: ({ limit, page, search }) => ({
        url: `home-customer-airlines?per_page=${limit}&page=${page}&search=${search}`,
        headers: authHeader()
      }),
      keepUnusedDataFor: 0,
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      providesTags: () => [{ type: "Airline" as const, id: "LIST" }]
    }),
    deleteAirline: build.mutation<TData, number>({
      query(body) {
        return {
          headers: authHeader(),
          url: `delete-home-customer-airlines`,
          body: {
            id: body
          },
          method: "POST",
        };
      },
    }),
  })
});

export const { useGetHomeDataQuery, useGetSliderQuery, useDeleteSliderMutation, useGetWhoWeAreQuery,useDeleteFooterSocialMutation,
  useGetWhyChooseDataQuery,useDeletWhyChooseUsMutation,useGetAirlinesQuery,useDeleteAirlineMutation } = footerApi;

export const {
  endpoints: { getHomeData, getSlider, deleteSlider, getWhoWeAre,deleteFooterSocial,getWhyChooseData,deletWhyChooseUs,getAirlines,deleteAirline }
} = footerApi;
