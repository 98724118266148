import { twJoin } from "tailwind-merge";
import CommonLanguage from "../../components/common/CommonLanguage";
import { useEffect, useState } from "react";
import LabelAndImage from "../../components/SeoData/LabelAndImage";
import LabelAndEditor from "../../components/SeoData/LabelAndEditor";
import FooterInputBoxes, { IFooterInput } from "./FooterInputBoxes";
import FooterContactUs, { IFooterContactUsInput } from "./FooterContactUs";
import FooterSocialMedia, { ISocialMedia } from "./FooterSocialMedia";
import FooterBottom from "./FooterBottom";
import FooterBottomForm, { IFooterBottomInput } from "./FooterBottomForm";
import FooterSocialMediaInput from "./FooterSocialMediaInput";
import { useGetFooterQuery } from "../../app/services/footer";
import { CancelIcon, SaveIcon } from "../../components/common/Icons";
import axios from "../../axios";
import { endpointUrl } from "../../app/services/api";
interface IFooterImage {
    company1Image: string;
    company1ImageFile: File | undefined;
    company2Image: string;
    company2ImageFile: File | undefined;
    company3Image: string;
    company3ImageFile: File | undefined;
}
interface IFooterData {
    footer_description_en: string;
    footer_description_tc: string;
    footer_description_sc: string;
    location_en: string;
    location_tc: string;
    location_sc: string;
    copy_right_text_en: string;
    copy_right_text_tc: string;
    copy_right_text_sc: string;
    logo_image: string;
    phone_en: string;
    phone_tc: string;
    phone_sc: string;
    email_en: string;
    email_tc: string;
    email_sc: string;
    company_image_1: any;
    company_image_2: any;
    company_image_3: any;
}
const FooterComponent = () => {
    const [logoImage, setLogoImage] = useState<string>("");
    const [footerData, setFooterData] = useState<IFooterInput>({
        description_en: '',
        description_tc: '',
        description_sc: '',
    });
    const [logoImageFile, setLogoImageFile] = useState<File | undefined>();
    const [footerImages, setFooterImages] = useState<IFooterImage>({
        company1Image: '',
        company1ImageFile: undefined,
        company2Image: '',
        company2ImageFile: undefined,
        company3Image: '',
        company3ImageFile: undefined
    });
    const [contact_us, setContactUs] = useState<IFooterContactUsInput>({
        location_en: '',
        location_tc: '',
        location_sc: '',
        phone_en: '',
        phone_tc: '',
        phone_sc: '',
        email_en: '',
        email_tc: '',
        email_sc: '',
        copyright_en: '',
        copyright_tc: '',
        copyright_sc: ''
    });
    
    const [showFooterBottomCRUD, setShowBottomCRUD] = useState<boolean>(false);
    const [showMediaCRUD, setShowMediaCRUD] = useState<boolean>(false);
    const [footerBottomData, setFooterBottomData] = useState<IFooterBottomInput[]>([]);
    const [socialDetail, setSocialDetail] = useState<ISocialMedia | undefined>();
    const [errorMessage, setErrorMessage] = useState<string>('');
    const { data: footer } = useGetFooterQuery();
    const [footerBottomDetailData, setFooterBottomDetailData] = useState<IFooterBottomInput | undefined>();
    const change_company_logo = (key: string, value: any) => {
        if (footerImages) {
            if(key=="company1ImageFile"){
                const url=value?URL.createObjectURL(value):'';
                setFooterImages((prev)=>({
                    ...prev,
                    company1ImageFile:value,
                    company1Image:url
                }));
                return;
            }
            if(key=="company2ImageFile"){
                const url=value?URL.createObjectURL(value):'';
                setFooterImages((prev)=>({
                    ...prev,
                    company2ImageFile:value,
                    company2Image:url
                }));
                return;
            }
            if(key=="company3ImageFile"){
                const url=value?URL.createObjectURL(value):'';
                setFooterImages((prev)=>({
                    ...prev,
                    company3ImageFile:value,
                    company3Image:url
                }));
                return;
            }
            if(key=="logoImageFile"){
                const url=value?URL.createObjectURL(value):'';
                setLogoImageFile(value);
                setLogoImage(url);
            }
            setFooterImages({ ...footerImages, [key]: value });
        }
    }
    const handle_submit_social = () => {

    }
    useEffect(() => {
        if (footer) {            
            const footer_info = footer.footer;
            setLogoImage(footer?.footer?.logo_image);            
            setFooterData({
                description_en: footer_info?.footer_description_en,
                description_tc: footer_info?.footer_description_tc,
                description_sc: footer_info?.footer_description_sc,
            });
            setFooterImages({
                company1Image: footer_info?.company_image_one,
                company1ImageFile: undefined,
                company2Image: footer_info?.company_image_two,
                company2ImageFile: undefined,
                company3Image: footer_info?.company_image_three,
                company3ImageFile: undefined
            });
            setContactUs({
                location_en: footer_info.location_en,
                location_tc: footer_info.location_tc,
                location_sc: footer_info.location_sc,
                phone_en: footer_info.phone_en,
                phone_tc: footer_info.phone_tc,
                phone_sc: footer_info.phone_sc,
                email_en: footer_info.email_en,
                email_tc: footer_info.email_tc,
                email_sc: footer_info.email_sc,
                copyright_en: footer_info.copy_right_text_en,
                copyright_tc: footer_info.copy_right_text_tc,
                copyright_sc: footer_info.copy_right_text_sc
            })
        }
    }, [footer]);
    const handle_submit = async () => {
        const footer_all_data: IFooterData = {
            email_en: contact_us?.email_en ?? '',
            email_tc: contact_us?.email_tc ?? '',
            email_sc: contact_us?.email_sc ?? '',
            phone_en: contact_us?.phone_en ?? '',
            phone_tc: contact_us?.phone_tc ?? '',
            phone_sc: contact_us?.phone_sc ?? '',
            location_en: contact_us?.location_en ?? '',
            location_tc: contact_us?.location_tc ?? '',
            location_sc: contact_us?.location_sc ?? '',
            copy_right_text_en: contact_us?.copyright_en ?? '',
            copy_right_text_tc: contact_us?.copyright_tc ?? '',
            copy_right_text_sc: contact_us?.copyright_sc ?? '',
            logo_image: logoImage,
            company_image_1: footerImages?.company1ImageFile ?? undefined,
            company_image_2: footerImages?.company2ImageFile ?? undefined,
            company_image_3: footerImages?.company3ImageFile ?? undefined,
            footer_description_en: footerData?.description_en,
            footer_description_tc: footerData?.description_tc,
            footer_description_sc: footerData?.description_sc,
        }
        var formData = new FormData();
        formData.append('email_en', footer_all_data?.email_en);
        formData.append('email_tc', footer_all_data?.email_tc);
        formData.append('email_sc', footer_all_data?.email_sc);
        formData.append('phone_en', footer_all_data?.phone_en);
        formData.append('phone_tc', footer_all_data?.phone_tc);
        formData.append('phone_sc', footer_all_data?.phone_sc);
        formData.append('location_en', footer_all_data?.location_en);
        formData.append('location_tc', footer_all_data?.location_tc);
        formData.append('location_sc', footer_all_data?.location_sc);
        formData.append('copy_right_text_en', footer_all_data?.copy_right_text_en);
        formData.append('copy_right_text_tc', footer_all_data?.copy_right_text_tc);
        formData.append('copy_right_text_sc', footer_all_data?.copy_right_text_sc);
        const logo_file:any=logoImageFile;
        formData.append('logo_image', logo_file);
        formData.append('company_image_one', footer_all_data?.company_image_1);
        formData.append('company_image_two', footer_all_data?.company_image_2);
        formData.append('company_image_three', footer_all_data?.company_image_3);
        formData.append('footer_description_en', footer_all_data?.footer_description_en);
        formData.append('footer_description_tc', footer_all_data?.footer_description_tc);
        formData.append('footer_description_sc', footer_all_data?.footer_description_sc);
        await axios(endpointUrl + "update-footer-page", {
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: formData,
        })
            .then((res: any) => {
                console.log('res ', res)
            })
            .catch((err) => {
                console.log('error ', err)
            });
    }

    return (
        <div
            className={twJoin(
                "text-left px-2 sm:px-4 py-4 bg-white sm:mt-[25px] mt-0 font-poppins text-13 font-semibold text-[#858795] tracking-[-0.03rem] w-full h-full rounded-xl pt-3 pb-8 sm:pt-3 sm:pb-9 "
            )}
        >
            <LabelAndImage label="Logo Image" setFile={(value)=>change_company_logo('logoImageFile',value)} setValue={()=>1} value={logoImage} />
                <div className="grid grid-cols-3 gap-3">
                    <LabelAndImage label="Company Image 1" setFile={(value: any) => change_company_logo('company1ImageFile', value)} setValue={(value: any) => 1} value={footerImages?.company1Image} />
                    <LabelAndImage label="Company Image 2" setFile={(value: any) => change_company_logo('company2ImageFile', value)} setValue={(value: any) => 1} value={footerImages?.company2Image} />
                    <LabelAndImage label="Company Image 3" setFile={(value: any) => change_company_logo('company3ImageFile', value)} setValue={(value: any) => 1} value={footerImages?.company3Image} />
                </div>
                <div className="mt-5">
                    <FooterInputBoxes footer_data={footerData} setFooterData={setFooterData} />
                </div>
                <div className="mt-5">
                    <h2 className="text-sm text-black font-medium mb-3">Contact us</h2>
                    <FooterContactUs contact_us={contact_us} setContactUs={setContactUs} />
                </div>
                <div className="mt-5 last:mb-0 flex items-center">
                    <button
                        className={twJoin(
                            "flex items-center justify-center bg-vorpblue mr-2.5 text-white font-primary text-14 font-medium rounded-md py-[8.5px] px-3"
                        )}
                        onClick={() => handle_submit()}
                    >
                        <SaveIcon className="mr-2" /> Save
                    </button>
                    <button
                        className="flex items-center justify-center bg-offwhite font-primary text-14 font-medium text-black2 rounded-md py-[8.5px] px-3"
                        onClick={() => {
                            //setShow(false);
                        }}
                    >
                        <CancelIcon className="mr-2" color="#9E3039" />
                        Cancel
                    </button>
                </div>
        </div>
    );
};
export default FooterComponent;
