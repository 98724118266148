import { useEffect, useRef, useState } from "react";
import SeoData from "../../components/SeoData/SeoData";
import { twJoin } from "tailwind-merge";
import CommonLanguage from "../../components/common/CommonLanguage";
import { CancelIcon, SaveIcon } from "../../components/common/Icons";
import classNames from "classnames";
import BannerSingle, { IBannerData } from "../../components/SeoData/BannerSingle";
import CompanyProfileSection, { ICompanyProfile, ICompanyProfileData } from "./CompanyProfileSection";
import CompanyHistorySection, { ICompanyHistory } from "./CompanyHistorySection";
import CompanyMissionInput, { IMission } from "./CompanyMissionInput";
import LeadershipInput from "./LeadershipInput";
import { useGetLeaderShipsQuery } from "../../app/services/company/leadership";
import { toast } from "react-toastify";
import { endpointUrl } from "../../app/services/api";
import axios from "../../axios";
export interface ILeaderShipMember{
    id:number;
    order:number;
    title_en:string;
    title_tc:string;
    title_sc:string;
    subtitle_en:string;
    subtitle_tc:string;
    subtitle_sc:string;
}
const LeadershipPage = () => {
    const [metaTitle_en, setMetaTitleEn] = useState<string>("");
    const [metaTitle_zh, setMetaTitleZh] = useState<string>("");
    const [metaTitle_cn, setMetaTitleCn] = useState<string>("");
    const [metaDescription_en, setMetaDescriptionEn] = useState<string>("");
    const [metaDescription_zh, setMetaDescriptionZh] = useState<string>("");
    const [metaDescription_cn, setMetaDescriptionCn] = useState<string>("");
    const [metaImage, setMetaImage] = useState<string>("");
    const [metaImageFile, setMetaImageFile] = useState<File | undefined>();
    const [banner, setBanner] = useState<IBannerData | undefined>({
        title_en: '',
        title_tc: '',
        title_sc: '',
        image: undefined,
        image_url: ''
    });
    const [show,setShow]=useState<boolean>(true);
    const [errorPopup, setErrorPopup] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [isSave, setIsSave] = useState<boolean>(false);
    const [boardDirector,setBoardDirector]=useState<ILeaderShipMember>({
        id:0,
        order:0,
        title_en:'',
        title_tc:'',
        title_sc:'',
        subtitle_en:'',
        subtitle_tc:'',
        subtitle_sc:'',
    });
    const [executive,setExecutive]=useState<ILeaderShipMember>({
        id:0,
        order:0,
        title_en:'',
        title_tc:'',
        title_sc:'',
        subtitle_en:'',
        subtitle_tc:'',
        subtitle_sc:'',
    });
    const {data:leadership}=useGetLeaderShipsQuery();

    useEffect(() => {
        if (leadership) {
            const page_data = leadership.leadershipPage;
            const board=leadership.board_of_directors;
            const executive=leadership.executive_leadership;
            if (page_data) {
                setMetaTitleEn(page_data.meta_title_en);
                setMetaTitleZh(page_data.meta_title_tc);
                setMetaTitleCn(page_data.meta_title_sc);
                setMetaDescriptionEn(page_data.meta_description_en);
                setMetaDescriptionZh(page_data.meta_description_tc);
                setMetaDescriptionCn(page_data.meta_description_sc);
                setMetaImage(page_data.meta_image);
                setBanner({
                    title_en: page_data.title_en,
                    title_tc: page_data.title_tc,
                    title_sc: page_data.title_sc,
                    image_url: page_data.banner_desktop_image,
                    image: (page_data.banner_desktop_image as any)
                });
            }
            if(board){
                setBoardDirector({
                    id:board.id,
                    order:board.section_order,
                    title_en:board.title_en,
                    title_tc:board.title_tc,
                    title_sc:board.title_sc,
                    subtitle_en:board.sub_title_en,
                    subtitle_tc:board.sub_title_tc,
                    subtitle_sc:board.sub_title_sc,
                })
            }
            if(executive){
                setExecutive({
                    id:executive.id,
                    order:executive.section_order,
                    title_en:executive.title_en,
                    title_tc:executive.title_tc,
                    title_sc:executive.title_sc,
                    subtitle_en:executive.sub_title_en,
                    subtitle_tc:executive.sub_title_tc,
                    subtitle_sc:executive.sub_title_sc,
                })
            }
        }
    }, [leadership]);
    const handle_submit=async()=>{
        const formData = new FormData();
        formData.append('meta_title_en', metaTitle_en ?? '');
        formData.append('meta_title_tc', metaTitle_zh ?? '');
        formData.append('meta_title_sc', metaTitle_cn ?? '');
        formData.append('meta_description_en', metaDescription_en ?? '');
        formData.append('meta_description_tc', metaDescription_zh ?? '');
        formData.append('meta_description_sc', metaDescription_cn ?? '');
        if(metaImageFile)
        formData.append('meta_image', metaImageFile as any);

        formData.append('page_title_en', banner?.title_en ?? '');
        formData.append('page_title_tc', banner?.title_tc ?? '');
        formData.append('page_title_sc', banner?.title_sc ?? '');
        formData.append('banner_desktop_image', banner?.image ?? '');

        formData.append('board_sub_title_en', boardDirector?.subtitle_en ?? '');
        formData.append('board_sub_title_tc', boardDirector?.subtitle_tc ?? '');
        formData.append('board_sub_title_sc', boardDirector?.subtitle_sc ?? '');
        formData.append('board_title_en', boardDirector?.title_en ?? '');
        formData.append('board_title_tc', boardDirector?.title_tc ?? '');
        formData.append('board_title_sc', boardDirector?.title_sc ?? '');
        formData.append('executive_title_en', executive?.title_en ?? '');
        formData.append('executive_title_tc', executive?.title_tc ?? '');
        formData.append('executive_title_sc', executive?.title_sc ?? '');
        formData.append('executive_sub_title_en', executive?.subtitle_en ?? '');
        formData.append('executive_sub_title_tc', executive?.subtitle_tc ?? '');
        formData.append('executive_sub_title_sc', executive?.subtitle_sc ?? '');
        await axios(endpointUrl + "update-leadership-page", {
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: formData,
        })
        .then((res: any) => {
            if (res?.data?.status == true) {
                toast(res?.data?.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    type: "success",
                });
            } else {
                setErrorPopup(true);
                setMessage(res?.data?.message);
            }
            setIsSave(false);
        })
        .catch((err) => {
            setErrorPopup(true);
            setMessage(err?.data?.message);
        });
    }
    return (
        <div
            className={twJoin(
                "px-2 sm:px-4 py-4 bg-white sm:mt-[25px] mt-0 font-poppins text-13 font-semibold text-[#858795] tracking-[-0.03rem] w-full h-full rounded-xl pt-3 pb-8 sm:pt-3 sm:pb-9 "
            )}
        >
            <div className="flex">
                <div className="text-left w-1/2 mr-5">
                    <SeoData
                        showPageTitle={false}
                        setPageTitleEn={() => 1}
                        setPageTitleZh={() => 1}
                        setPageTitleCn={() => 1}
                        metatitle_en={metaTitle_en}
                        metatitle_zh={metaTitle_zh}
                        metatitle_cn={metaTitle_cn}
                        setMetaTitleEn={setMetaTitleEn}
                        setMetaTitleZh={setMetaTitleZh}
                        setMetaTitleCn={setMetaTitleCn}
                        metadescription_en={metaDescription_en}
                        metadescription_zh={metaDescription_zh}
                        metadescription_cn={metaDescription_cn}
                        setMetaDescriptionEn={setMetaDescriptionEn}
                        setMetaDescriptionZh={setMetaDescriptionZh}
                        setMetaDescriptionCn={setMetaDescriptionCn}
                        meta_image={metaImage}
                        setMetaImage={setMetaImage}
                        setMetaImageFile={setMetaImageFile}

                    />

                </div>
                <div className="w-1/2 text-left">
                    <BannerSingle banner={banner} setBanner={setBanner} />
                </div>
            </div>
            <div className="text-left mt-5">
                <h2 className="text-lg text-left font-bold mb-0 mt-5 text-black">Board of Directors</h2>
               <LeadershipInput setShow={setShow} info={boardDirector} setInfo={setBoardDirector}/>
            </div>
            <div className="text-left mt-5">
                <h2 className="text-lg text-left font-bold mb-0 mt-5 text-black">Executive Leadership</h2>
               <LeadershipInput setShow={setShow} info={executive} setInfo={setExecutive}/>
            </div>
            <div className="mt-5 last:mb-0 flex items-center">
            <button
                className={twJoin(
                     isSave ? "opacity-50 pointer-events-none" : "",
                    "flex items-center justify-center bg-vorpblue mr-2.5 text-white font-primary text-14 font-medium rounded-md py-[8.5px] px-3"
                )}
                onClick={() => handle_submit()}
            >
                <SaveIcon className="mr-2" /> Save
            </button>
            <button
                className="flex items-center justify-center bg-offwhite font-primary text-14 font-medium text-black2 rounded-md py-[8.5px] px-3"
                onClick={() => {
                    setShow(false);
                }}
            >
                <CancelIcon className="mr-2" color="#9E3039" />
                Cancel
            </button>
        </div>
        </div>
    );
};
export default LeadershipPage;
