import { twJoin } from "tailwind-merge";
import CommonLanguage from "../../components/common/CommonLanguage";
import { useState } from "react";
import NewsList from "./NewsList";
import NewsSection from "./NewsSection";

const NewsComponent = () => {
  const [activeLang, setActiveLang] = useState<string>("en");
  const [servicesData, setServicesData] = useState<any>();
  const [showList, setShowList] = useState<boolean>(true);
  const changeLanguage = (code: string) => {
    setActiveLang(code);
  };
  return (
    <div
      className={twJoin(
        "text-left px-2 sm:px-4 py-4 bg-white sm:mt-[25px] mt-0 font-poppins text-13 font-semibold text-[#858795] tracking-[-0.03rem] w-full h-full rounded-xl pt-3 pb-8 sm:pt-3 sm:pb-9 "
      )}
    >
      {showList == true
        ? <NewsList setShowList={setShowList}/>
        : <div>
            <CommonLanguage
              changeLanguage={changeLanguage}
              activeLang={activeLang}
            />
            <NewsSection
              activeLang={activeLang}
              initialData={servicesData}
              setValue={setServicesData}
              setShowList={setShowList}
            />
          </div>}
    </div>
  );
};
export default NewsComponent;
