import { FC, useEffect, useState } from "react";
import Button from "../../components/common/Button";
import { ColumnFiltersState, PaginationState } from "@tanstack/react-table";
import { baseObj } from "../../utils/constants";
import { templateList } from "../../active-time-report/views/ActiveTimeReport";
import { twJoin } from "tailwind-merge";
import { useGetMissionInfoMutation, useGetMissionListQuery } from "../../app/services/company/visionmission";
import { ToastContainer, toast } from "react-toastify";
import axios from "../../axios";
import { endpointUrl } from "../../app/services/api";
import ErrorPopup from "../../components/ErrorPopup/ErrorPopup";
import CommonErrorPopup from "../../components/common/CommonErrorPopup";
import ContactUsLocationInput from "./ContactUsLocationInput";
import ContactUsLocationTable from "./ContactUsLocationTable";
export interface IContactLocation {
    id: number,
    map_en: string,
    map_tc: string,
    map_sc: string,
    phone_en: string,
    phone_tc: string,
    phone_sc: string,
    email1_en: string,
    email1_tc: string,
    email1_sc: string,
    email2_en: string,
    email2_tc: string,
    email2_sc: string,
    whatsapp_en: string,
    whatsapp_tc: string,
    whatsapp_sc: string,
    location_en: string
    location_tc: string
    location_sc: string
}
const ContactUsLocationList = ({
}) => {
    const [editRowData, setEditRowData] = useState<any>();
    const [detailData, setDetailData] = useState<IContactLocation>({
        id: 0,
        map_en: '',
        map_tc: '',
        map_sc: '',
        phone_en: '',
        phone_tc: '',
        phone_sc: '',
        email1_en: '',
        email1_tc: '',
        email1_sc: '',
        email2_en: '',
        email2_tc: '',
        email2_sc: '',
        whatsapp_en: '',
        whatsapp_tc: '',
        whatsapp_sc: '',
        location_en: '',
        location_tc: '',
        location_sc: '',

    });
    const [show, setShow] = useState<boolean>(true);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [viewCols, setCols] = useState<any>([]);
    const [isLayoutControlOpen, setIsLayoutControlOpen] = useState<boolean>(
        false
    );
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    let [localModuleId, setLocalModuleId] = useState<number>(0);
    const [data, setData] = useState<baseObj[]>([]);
    const [itemCount, setItemCount] = useState<number>(0);
    const [tempList, setTempList] = useState<any>(templateList);
    const [hiddenFields, setHiddenFields] = useState<string[]>([]);
    const [globalSearch, setGlobalSearch] = useState<string>("");
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 20
    });
    const [errorPopup, setErrorPopup] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [isSave, setIsSave] = useState<boolean>(false);
    const { data: missionList } = useGetMissionListQuery({
        page: currentPage,
        limit: pagination.pageSize,
        search: globalSearch
    });
    const [getMissionInfo] = useGetMissionInfoMutation();
    useEffect(() => {
        if (missionList) {
            setData(missionList.data);
            setItemCount(missionList.total);
        }
    }, [missionList]);
    const handle_submit = async (data: IContactLocation) => {
        const formData = new FormData();
        formData.set('id', data.id.toString() ?? 0);
        formData.set('phone', data.phone_en ?? '');
        formData.set('phone', data.phone_tc ?? '');
        formData.set('phone', data.phone_sc ?? '');
        formData.set('email1', data.email1_en ?? '');
        formData.set('email1', data.email1_tc ?? '');
        formData.set('email1', data.email1_sc ?? '');
        formData.set('email2', data.email2_en ?? '');
        formData.set('email2', data.email2_tc ?? '');
        formData.set('email2', data.email2_sc ?? '');
        formData.set('whatsapp', data.whatsapp_en ?? '');
        formData.set('whatsapp', data.whatsapp_tc ?? '');
        formData.set('whatsapp', data.whatsapp_sc ?? '');
        formData.set('location', data.location_en ?? '');
        formData.set('location', data.location_tc ?? '');
        formData.set('location', data.location_sc ?? '');
        formData.set('map', data.map_en ?? '');
        formData.set('map', data.map_tc ?? '');
        formData.set('map', data.map_sc ?? '');
        const url = data?.id ? 'update-our-commitment-content' : 'our-commitment-content';
        await axios(endpointUrl + url, {
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: formData,
        })
            .then((res: any) => {
                if (res?.data?.status == true) {
                    getMissionInfo({
                        page: 1,
                        limit: 20,
                        search: ''
                    }).then((res: any) => {
                        setData(res?.data?.data)
                    })
                    toast(res?.data?.message, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        type: "success",
                    });
                    setShow(true);
                } else {
                    setErrorPopup(true);
                    setMessage(res?.response?.data?.message);
                }
                setIsSave(false);
            })
            .catch((err) => {
                setErrorPopup(true);
                setMessage(err?.data?.message);
            });
    }
    const clear_data = () => {
        setDetailData({
            id: 0,
            map_en: '',
            map_tc: '',
            map_sc: '',
            phone_en: '',
            phone_tc: '',
            phone_sc: '',
            email1_en: '',
            email1_tc: '',
            email1_sc: '',
            email2_en: '',
            email2_tc: '',
            email2_sc: '',
            whatsapp_en: '',
            whatsapp_tc: '',
            whatsapp_sc: '',
            location_en: '',
            location_tc: '',
            location_sc: '',
        })
    }
    return <div
        className={twJoin(
            "px-2 sm:px-4 py-4 bg-white sm:mt-[25px] mt-0 font-poppins text-13 font-semibold text-[#858795] tracking-[-0.03rem] w-full h-full rounded-xl pt-3 pb-8 sm:pt-3 sm:pb-9 "
        )}
    >
        <ToastContainer className={"text-12 font-poppins font-normal"} />
        <CommonErrorPopup
            show={errorPopup}
            setShow={setErrorPopup}
            message={message}
        />
        <div className="text-left">
            {show == false ? <ContactUsLocationInput isSave={isSave} handle_submit={handle_submit} detailData={detailData} setDetailData={setDetailData} setShow={setShow} /> :
                <>
                    <div className="items-center xs:mt-1 sm:mt-1 flex justify-end mb-3">
                        <Button
                            label="+ Add New"
                            onClick={() => { clear_data(); setShow(false) }}
                            type="button"
                            variant="primary"
                            customClass="py-[3px] my-1 px-2 focus:outline-none hover:bg-vorpmyanmar hover:border-vorpmyanmar hover:text-vorpblue 2xl:text-[14px] text-12 leading-5 font-medium text-white"
                            size="base"
                        />
                    </div>
                    <ContactUsLocationTable
                        // columnsData={columnsData}
                        isLayoutControlOpen={isLayoutControlOpen}
                        setIsLayoutControlOpen={setIsLayoutControlOpen}
                        globalSearch={globalSearch}
                        setGlobalSearch={setGlobalSearch}
                        columnFilters={columnFilters}
                        setColumnFilters={setColumnFilters}
                        pagination={pagination}
                        data={data}
                        setData={setData}
                        setPagination={setPagination}
                        hiddenFields={hiddenFields ? hiddenFields : []}
                        setHiddenFields={setHiddenFields}
                        checkManagement={true}
                        total={itemCount}
                        localModuleId={localModuleId}
                        // visArray={visArray}
                        templateList={tempList}
                        setCurrentView={() => 1}
                        setCols={setCols}
                        setCurrentPage={setCurrentPage}
                        currentPage={currentPage}
                        setEditRowData={setEditRowData}
                        editRowData={editRowData}
                        setShowList={setShow}
                        setDetailData={setDetailData}
                    />
                </>
            }
        </div>
    </div>
}
export default ContactUsLocationList;