import {
  ClassicEditor,
  Context,
  InlineEditor,
  ContextWatchdog,
  Autoformat,
  Bold,
  Italic,
  BlockQuote,
  Base64UploadAdapter,
  CKFinder,
  CKFinderUploadAdapter,
  CloudServices,
  EditorConfig,
  Essentials,
  Heading,
  Image,
  ImageCaption,
  ImageStyle,
  ImageToolbar,
  ImageUpload,
  PictureEditing,
  Indent,
  Link,
  List,
  Mention,
  Paragraph,
  PasteFromOffice,
  Table,
  TableToolbar,
  TextTransformation
} from "ckeditor5";

import { CKEditor, CKEditorContext } from "@ckeditor/ckeditor5-react";

import "ckeditor5/ckeditor5.css";
import { FC } from "react";
interface ILabelAndEditor {
  refId: any;
  label: string;
  value: string|undefined;
  name?:string;
  setValue: React.Dispatch<React.SetStateAction<string>> | undefined;
}
const LabelAndEditor: FC<ILabelAndEditor> = ({
  refId,
  label,
  value,
  setValue,
  name
}) => {
  const handleEditorChange = (event: any, editor: any) => {
    if(setValue)
    setValue(editor.getData());
  };
  return (
    <div className="mb-5 last:mb-0">
      <label className="text-black2 text-15 font-medium font-primary mb-[0.688rem] block">
        {label}
      </label>
      <CKEditorContext context={Context} contextWatchdog={ContextWatchdog}>
        <CKEditor
          editor={ClassicEditor}
          ref={refId}
          config={{
            plugins: [
              Essentials,
              Autoformat,
              Bold,
              Italic,
              BlockQuote,
              CKFinder,
              CKFinderUploadAdapter,
              CloudServices,
              Heading,
              Image,
              ImageCaption,
              ImageStyle,
              ImageToolbar,
              ImageUpload,
              Base64UploadAdapter,
              Indent,
              Link,
              List,
              Mention,
              Paragraph,
              PasteFromOffice,
              PictureEditing,
              Table,
              TableToolbar,
              TextTransformation
            ],
            toolbar: [
              "undo",
              "redo",
              "|",
              "heading",
              "|",
              "bold",
              "italic",
              "|",
              "link",
              "uploadImage",
              "insertTable",
              "blockQuote",
              "|",
              "bulletedList",
              "numberedList",
              "|",
              "outdent",
              "indent"
            ]
          }}
          data={value ? value : "<p></p>"}
          onReady={editor => {}}
          onBlur={handleEditorChange}
        />
      </CKEditorContext>
    </div>
  );
};
export default LabelAndEditor;
