import { redirectToLogin } from "../annoucement-sheet";
import { api } from "../api";
import authHeader from "../auth-header";
export interface IAirlineInfoData {
    "airline_page": IAirlineDataInfo,
    "airline_section": IAirlineSection,
    
}
export interface IAirlineDataInfo {
    "id": number,
    "sub_menu_id": number,
    "menu_id": number,
    "slug": string,
    "title_en": string,
    "title_tc": string,
    "title_sc": string,
    "meta_title_en": string,
    "meta_title_tc": string,
    "meta_title_sc": string,
    "meta_description_en": string,
    "meta_description_tc": string,
    "meta_description_sc": string,
    "meta_image": string,
    "banner_mobile_image": string,
    "banner_desktop_image": string,
}
export interface IAirlineSection{
    "id": number,
    "page_id": number,
    "section_order": number,
    "unique_name": "customerAirlines",
    "sub_title_en": string,
    "sub_title_tc": string,
    "sub_title_sc": string,
    "title_en": string,
    "title_tc": string,
    "title_sc": string,
    "description_en": string,
    "description_tc": string,
    "description_sc": string,
    "image": string,
    "additional_data_en": null,
    "additional_data_tc": null,
    "additional_data_sc": null,
}

export type TData = {
    data: [];
    total: number;
};
export type PaginationData = {
    page: number;
    limit: number | string | undefined;
    search: string | number;
    sort?: string;
    type?: string;
};
export const airlineApi = api.injectEndpoints({
    endpoints: (build) => ({
        getAirlines: build.query<IAirlineInfoData, void>({
            query: () => ({
                url: `fetch-customer-airline-page`,
                headers: authHeader()
            }),
            keepUnusedDataFor: 0,
            transformErrorResponse: (err) => {
                if (err?.status === 401) {
                    redirectToLogin();
                }
            },
            providesTags: () => [{ type: "Airline" as const, id: "LIST" }]
        }),
        getAirlinesData: build.query<IAirlineInfoData, void>({
            query: () => ({
                url: `fetch-customer-airline-page`,
                headers: authHeader()
            }),
            keepUnusedDataFor: 0,
            transformErrorResponse: (err) => {
                if (err?.status === 401) {
                    redirectToLogin();
                }
            },
            providesTags: () => [{ type: "Airline" as const, id: "LIST" }]
        }),
        getAirlinesList: build.query<TData, PaginationData>({
            query: ({ limit, page, search,type }) => ({
                url: `airline-content?per_page=${limit}&page=${page}&search=${search}&type=${type}`,
                headers: authHeader()
            }),
            keepUnusedDataFor: 0,
            transformErrorResponse: (err) => {
                if (err?.status === 401) {
                    redirectToLogin();
                }
            },
            providesTags: () => [{ type: "Airline" as const, id: "LIST" }]
        }),
        getAirlinesInfo: build.mutation<TData, PaginationData>({
            query({ limit, page, search, type }) {
                return {
                    headers: authHeader(),
                    url: `airline-content?type=${type}&per_page=${limit}&page=${page}&search=${search}`,
                    method: "GET",
                };
            },
            invalidatesTags: ['Awards']
        }),
        deleteAirline: build.mutation<TData, number>({
            query(body) {
                return {
                    headers: authHeader(),
                    url: `delete-airline-content`,
                    body: {
                        id: body
                    },
                    method: "POST",
                };
            },
            invalidatesTags: ['Airline']
        }),
    })
});

export const { useGetAirlinesQuery, useGetAirlinesListQuery, useGetAirlinesInfoMutation, useDeleteAirlineMutation,useGetAirlinesDataQuery } = airlineApi;

export const {
    endpoints: { getAirlines, getAirlinesList, getAirlinesInfo, deleteAirline,getAirlinesData }
} = airlineApi;
