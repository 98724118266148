import { FC, useRef, useState } from "react";
import LabelAndEditor from "../../components/SeoData/LabelAndEditor";
import LabelAndImage from "../../components/SeoData/LabelAndImage";
import { twJoin } from "tailwind-merge";
import classNames from "classnames";
import { CancelIcon, SaveIcon } from "../../components/common/Icons";
import LabelAndTextbox from "../../components/SeoData/LabelAndTextbox";
import LabelAndDate from "../../components/SeoData/LabelAndDate";
interface IData {
    activeLang: string;
    initialData: IBannerSlider;
    setValue: React.Dispatch<any>;
    setShowList: React.Dispatch<React.SetStateAction<boolean>>;
}
interface IBannerSlider {
    description_en: string;
    description_zh: string;
    description_cn: string;
    image_en: File | undefined;
    image_en_url: string;
    image_zh: File | undefined;
    image_zh_url: string;
    image_cn: File | undefined;
    image_cn_url: string;
}
const NewsSection: FC<IData> = ({
    activeLang,
    initialData,
    setValue,
    setShowList
}) => {
    const [data, setData] = useState<IBannerSlider | undefined>();
    const [title_en, setTitleEn] = useState<string>("");
    const [date, setDate] = useState<Date | undefined>();
    const [title_zh, setTitleZh] = useState<string>("");
    const [title_cn, setTitleCn] = useState<string>("");
    const [description_en, setDescriptionEn] = useState<string>("");
    const [description_zh, setDescriptionZh] = useState<string>("");
    const [description_cn, setDescriptionCn] = useState<string>("");
    const descriptionRef_en: any = useRef(null);
    const descriptionRef_tc: any = useRef(null);
    const descriptionRef_sc: any = useRef(null);
    const change_data = (value: any, key: string) => {
        if (data) {
            setData({ ...data, [key]: value });
        }
    };
    return <div className="mt-5">
        <div
            className={twJoin(
                activeLang == "en"
                    ? ""
                    : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
            )}
        >
            <LabelAndTextbox
                label="Title (EN)"
                value={title_en}
                setValue={setTitleEn}
            />
            <LabelAndEditor
                name="description"
                refId={descriptionRef_en}
                label=" Description (EN)"
                value={description_en}
                setValue={(value: any) => {
                    change_data(value, 'description_en')
                    setDescriptionEn(value)
                }}
            />
            <LabelAndImage
                margin="mt-0"
                setFile={(value: any) => change_data(value, 'image_en')}
                label="Image (EN)"
                value={data?.image_en_url}
                setValue={(value: any) => change_data(value, 'image_en_url')}
            />
        </div>
        <div
            className={twJoin(
                activeLang == "tc"
                    ? ""
                    : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
            )}
        >
            <LabelAndTextbox
                label="Title (TC)"
                value={title_zh}
                setValue={setTitleZh}
            />
            <LabelAndEditor
                name="description"
                refId={descriptionRef_tc}
                label=" Description (TC)"
                value={description_zh}
                setValue={(value: any) => {
                    change_data(value, 'description_zh')
                    setDescriptionZh(value)
                }}
            />
            <LabelAndImage
                margin="mt-0"
                setFile={(value: any) => change_data(value, 'image_zh')}
                label="Image (TC)"
                value={data?.image_zh_url}
                setValue={(value: any) => change_data(value, 'image_zh_url')}
            />
        </div>
        <div
            className={twJoin(
                activeLang == "sc"
                    ? ""
                    : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
            )}
        >
            <LabelAndTextbox
                label="Title (SC)"
                value={title_cn}
                setValue={setTitleCn}
            />
            <LabelAndEditor
                name="description"
                refId={descriptionRef_sc}
                label="Description (SC)"
                value={description_cn}
                setValue={(value: any) => {
                    change_data(value, 'description_cn')
                    setDescriptionCn(value)
                }}
            />
            <LabelAndImage
                margin="mt-0"
                setFile={(value: any) => change_data(value, 'image_cn')}
                label="Image (SC)"
                value={data?.image_zh_url}
                setValue={(value: any) => change_data(value, 'image_cn_url')}
            />
        </div>
        <div className="mt-5">
        <LabelAndDate label="Date"
            setValue={setDate}
            value={date}
        />
        </div>
        <div className="mt-5 last:mb-0 flex items-center">
            <button
                className={classNames(
                    // saving ? "opacity-50 pointer-events-none" : "",
                    "flex items-center justify-center bg-vorpblue mr-2.5 text-white font-primary text-14 font-medium rounded-md py-[8.5px] px-3"
                )}
                onClick={() => 1}
            >
                <SaveIcon className="mr-2" /> Save
            </button>
            <button
                className="flex items-center justify-center bg-offwhite font-primary text-14 font-medium text-black2 rounded-md py-[8.5px] px-3"
                onClick={() => {
                    setShowList(true);
                }}
            >
                <CancelIcon className="mr-2" color="#9E3039" />
                Cancel
            </button>
        </div>
    </div>
}
export default NewsSection;