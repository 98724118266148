import { FC, useEffect, useRef, useState } from "react";
import { twJoin } from "tailwind-merge";
import CommonLanguage from "../../../components/common/CommonLanguage";
import LabelAndTextbox from "../../../components/SeoData/LabelAndTextbox";
import LabelAndImage from "../../../components/SeoData/LabelAndImage";
import { CancelIcon, SaveIcon } from "../../../components/common/Icons";
import { IAwardAndCertificate } from "./AwardAndCertificateList";
import LabelAndEditor from "../../../components/SeoData/LabelAndEditor";
import LabelAndDate from "../../../components/SeoData/LabelAndDate";

export interface IBoardOfDirectoryInput {
    awards: IAwardAndCertificate;
    setAwards: React.Dispatch<React.SetStateAction<IAwardAndCertificate>>;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    isShowDate?:boolean;
    handle_submit: (data: IAwardAndCertificate) => Promise<void>;
    isSave: boolean;
}
const AwardAndCertificateInput: FC<IBoardOfDirectoryInput> = ({
    awards,
    setAwards,
    setShow,
    isShowDate,
    handle_submit,
    isSave
}) => {
    const description_en = useRef(null);
    const description_tc = useRef(null);
    const description_sc = useRef(null);
    const [data, setData] = useState<IAwardAndCertificate>(awards);
    const [activeLang, setActiveLang] = useState<string>("en");
    const change_data = (value: any, key: string) => {
        if (data) {
            if(key=="image"){
                const url=value?URL.createObjectURL(value):'';
                setData((prev)=>{
                    return {
                        ...prev,
                        image:value,
                        image_url:url
                    }
                });
                return;
            }
            setData({ ...data, [key]: value });

        }
    }
    const changeLanguage = (code: string) => {
        setActiveLang(code);
    };
    useEffect(()=>{
        if(awards){
            setData({
                id:awards.id,
                title_en:awards.title_en,
                title_tc:awards.title_tc,
                title_sc:awards.title_sc,
                description_en:awards.description_en,
                description_tc:awards.description_tc,
                description_sc:awards.description_sc,
                image:undefined,
                image_url:awards.image as any
            })
        }
    },[awards])
    return <div>
        <CommonLanguage
            changeLanguage={changeLanguage}
            activeLang={activeLang}
        />
        <div className="mt-5">
            <div
                className={twJoin(
                    activeLang == "en"
                        ? ""
                        : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
                )}
            >
                <LabelAndTextbox label="Title EN" value={data?.title_en} setValue={(value) => change_data(value, 'title_en')} />
                <div className="mt-5">
                    <LabelAndEditor label="Description EN" refId={description_en} value={data?.description_en} setValue={(value) => change_data(value, 'description_en')} />
                </div>                
            </div>
            <div
                className={twJoin(
                    activeLang == "tc"
                        ? ""
                        : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
                )}
            >
                <LabelAndTextbox label="Name TC" value={data?.title_tc} setValue={(value) => change_data(value, 'title_tc')} />
                <div className="mt-5">
                    <LabelAndEditor label="Description TC" refId={description_tc} value={data?.description_tc} setValue={(value) => change_data(value, 'description_tc')} />
                </div>
            </div>
            <div
                className={twJoin(
                    activeLang == "sc"
                        ? ""
                        : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
                )}
            >
                <LabelAndTextbox label="Name SC" value={data?.title_sc} setValue={(value) => change_data(value, 'title_sc')} />
                <div className="mt-5">
                    <LabelAndEditor label="Description SC" refId={description_sc} value={data?.description_sc} setValue={(value) => change_data(value, 'description_sc')} />
                </div>
            </div>
        </div>
        {isShowDate==true?
        <div className="mt-5">
                    <LabelAndDate label="Date"
                        setValue={(date)=>change_data(date,'date')}
                        value={data?.date}
                    />
                </div>:null}
        <div className="mt-5">
            <LabelAndImage label="Image" value={data?.image_url ?? ''} setValue={(value) => change_data(value, 'image_url')} setFile={(value) => change_data(value, 'image')} />
        </div>
        <div className="mt-5 last:mb-0 flex items-center">
            <button
                className={twJoin(
                    isSave ? "opacity-50 pointer-events-none" : "",
                    "flex items-center justify-center bg-vorpblue mr-2.5 text-white font-primary text-14 font-medium rounded-md py-[8.5px] px-3"
                )}
                onClick={() => handle_submit(data)}
            >
                <SaveIcon className="mr-2" /> Save
            </button>
            <button
                className="flex items-center justify-center bg-offwhite font-primary text-14 font-medium text-black2 rounded-md py-[8.5px] px-3"
                onClick={() => {
                    setShow(false);
                }}
            >
                <CancelIcon className="mr-2" color="#9E3039" />
                Cancel
            </button>
        </div>
    </div>
}
export default AwardAndCertificateInput;