import { twJoin } from "tailwind-merge";
import { useState } from "react";
import JobDepartment, { JobDepartmentType } from "../components/JobDepartment";
import SectionList from "../components/job-category/SectionList";

const JobDepartmentPage = () => {
  const [data, setData] = useState<JobDepartmentType>({
    id: 0,
    title_en: "",
    title_sc: "",
    title_tc: "",
  });
  const [showList, setShowList] = useState<boolean>(true);

  return (
    <div
      className={twJoin(
        "text-left px-2 sm:px-4 py-4 bg-white sm:mt-[25px] mt-0 font-poppins text-13 font-semibold text-[#858795] tracking-[-0.03rem] w-full h-full rounded-xl pt-3 pb-8 sm:pt-3 sm:pb-9 "
      )}
    >
      {showList ? (
        <SectionList setShowList={setShowList} />
      ) : (
        <div>
          <JobDepartment
            initialData={data}
            setValue={setData}
            setShowList={setShowList}
          />
        </div>
      )}
    </div>
  );
};
export default JobDepartmentPage;
