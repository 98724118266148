import React, { useEffect, useMemo, useState } from 'react';
import dashboard from './img/ic_round-space-dashboard.svg';
import dashboard_active from './img/ic_round-space-dashboard-active.svg';
import profile from './img/profile-icon.svg';
import profile_active from './img/profile-icon-active.svg';
import employee from './img/employee-icon.svg';
import employee_active from './img/employee-icon-active.svg';
import announcement from './img/announcement-icon.svg';
import claimForm from './img/claim_form.svg';
import claimFormActive from './img/claim_form_active.svg';
import announcement_active from './img/announcement-icon-active.svg';
import bkuser from './img/bk-user.svg';
import bkuser_active from './img/bk-user-active.svg';
import activityLog from './img/activityLog.svg';
import activityLog_active from './img/activityLog-active.svg';
const SidebarFunc = () => {
  var userDatas = localStorage.getItem('userData');
  var userJson: any = {};
  if (userDatas) {
    userJson = JSON.parse(userDatas);
    userJson.token = userJson.accessToken;
  }

  var getUser = userJson;
  const getAuthPermis: string[] = useMemo(() => [], []);

  if (getUser?.permissions) {
    getUser?.permissions?.forEach((per: any) => {
      getAuthPermis.push(per?.name);
    });
  }
  // const checkPermis = getAuthPermis.includes(permissions[0])

  const [docCheck, setDocCheck] = useState(false);
  const [claimTypeCheck, setClaimTypeCheck] = useState(false);
  const [announcCheck, setAnnouncCheck] = useState(false);
  const [noticeBoardCheck, setNoticeBoardCheck] = useState(false);
  const [profileCheck, setProfilleCheck] = useState(false);
  const [dashboardCheck, setDashboardCheck] = useState(false);
  const [pwdCheck, setPwdCheck] = useState(false);
  const [permisCheck, setPermisCheck] = useState(false);
  const [mediaCheck, seMediaCheck] = useState(false);
  const [employeeeCheck, setEmployeeeCheck] = useState(false);
  const [leaveCheck, setLeaveCheck] = useState(false);
  const [leaveTypeCheck, setLeaveTypeCheck] = useState(false);
  const [ActiveTimeCheck, setActiveTimeCheck] = useState(false);
  const [roleCheck, setRoleCheck] = useState(false);
  const [userCheck, setUserCheck] = useState(false);
  const [projectCheck, setProjectCheck] = useState(false);
  const [activityLogCheck, setActivityLogCheck] = useState(false);
  const [claimsChecked, setClaimsCheck] = useState(false);
  const [perfomance, setPerformance] = useState(false);
  const [recruitment, setRecruitment] = useState(false);
  const [viewCrm, setViewCRM] = useState(false);
  const [otList, setOTList] = useState(false);
  const [offDayWorking, setOffDayWorking] = useState(false);
  const [taskCategory, setTaskCategory] = useState(false);
  const [department, setDepartment] = useState(false);
  const [designation, setDesignation] = useState(false);
  const [category, setCategory] = useState(false);
  const [faq, setFAQ] = useState(false);
  const [manual, setManual] = useState(false);
  const [reminder, setReminder] = useState(false);
  const [usefulLinks, setUsefulLink] = useState(false);
  const [cms, setCMS] = useState(false);
  const [checkIncentive, setCheckIncentive] = useState(false);
  const [checkExchangeRate, setCheckExchangeRage] = useState(false);
  const [payroll, setPayroll] = useState(false);
  const [viewProgressChecker, setViewProgressChecker] =
    useState<boolean>(false);
  const [viewProgressCheckerAcc, setViewProgressCheckerAcc] =
    useState<boolean>(false);

  useEffect(() => {
    const payRollView = getAuthPermis.filter((f) => f == 'Manage_Payroll');

    const filterActiveTime = getAuthPermis.filter(
      (f) => f == 'View_Active_Time_Report'
    );
    const filterDashboard = getAuthPermis.filter(
      (f) => f == 'View_Admin_Dashboard'
    );
    const checkOffDay = getAuthPermis.filter(
      (f) => f == 'Manage_Office_Working_Days'
    );

    const filterAnnounc = getAuthPermis.filter((f) => f == 'View_Announcement');
    const filterNoticeBoard = getAuthPermis.filter((f) => f == 'View_NotiBoard');
    const otList = getAuthPermis.filter((f) => f == 'View_All_OT_List');
    const otOwnList = getAuthPermis.filter((f) => f == 'View_Own_OT_List');

    const claimType = getAuthPermis.filter((f) => f == 'Manage_ClaimForms');
    const claimsCheck = getAuthPermis.filter((f) => f == 'Manage_ClaimForms');
    const approveCheck = getAuthPermis.filter((f) => f == 'Approve_ClaimForms');

    const filterDoc = getAuthPermis.filter((f) => f == 'View_Document');
    const filterOwnDoc = getAuthPermis.filter((f) => f == 'View_Own_Document');
    const filterEmployee = getAuthPermis.filter((f) => f == 'View_Employee');
    const filterDepartment = getAuthPermis.filter((f) => f == 'View_Department');
    // View_Employee_Coded_Value
    // View_Employee_Profile
    const filterLeave = getAuthPermis.filter(
      (f) => f == 'View_Leave_Application'
    );
    const filterMedia = getAuthPermis.filter((f) => f == 'View_Media_Library');
    const filterProfile = getAuthPermis.filter((f) => f == 'View_My_Profile');
    const filterPwd = getAuthPermis.filter((f) => f == 'View_Password');
    const filterOwnPwd = getAuthPermis.filter((f) => f == 'View_Own_Password');
    const filterPermis = getAuthPermis.filter((f) => f == 'View_Permission');
    // const filterMedia = getAuthPermis.filter((f) => f == "View_Media_Library");
    const filteProject = getAuthPermis.filter((f) => f == 'View_Project');
    const filterRole = getAuthPermis.filter((f) => f == 'View_Role');
    // const filterPermiss = getAuthPermis.filter((f) => f == "View_Permission");
    // View_Time_Tracking
    const filterUseer = getAuthPermis.filter((f) => f == 'View_User');
    const filterLog = getAuthPermis.filter((f) => f == 'View_ActivityLog');
    const filterCategory = getAuthPermis.filter((f) => f == 'View_Category');
    const allView = getAuthPermis.filter((f) => f == 'View');
    const faqView = getAuthPermis.filter((f) => f == 'View_Faq');
    const manualView = getAuthPermis.filter((f) => f == 'View_Manuals');
    const remnderView = getAuthPermis.filter((f) => f == 'View_CalendarReminder');
    const viewPerformanceAppraisal = getAuthPermis.filter(
      (f) => f == 'View_Performance_Appraisal'
    );
    const viewRecruitment = getAuthPermis.filter(
      (f) => f == 'View_Recruitment'
    );
    const viewCRMList = getAuthPermis.filter((f) => f == 'View_CRM');
    const manage_incentive = getAuthPermis.filter(
      (f) => f == 'Manage_Employee_Incentives'
    );
    const manage_exchangeRage = getAuthPermis.filter(
      (f) => f == 'Manage_Exchange_Rates'
    );
    const manage_taskCategory = getAuthPermis.filter(
      (f) => f == 'Manage_Task_Categories'
    );
    const view_progresschecker = getAuthPermis.filter(
      (f) => f == 'View_Progress_Checker'
    );
    const view_progresschecker_acc = getAuthPermis.filter(
      (f) => f == 'View_Progress_Checker_Account'
    );
    const manage_department = getAuthPermis.filter(
      (f) => f == 'Manage_Department'
    );

    if (otList.length > 0 || otOwnList.length > 0) {
      setOTList(false);
    }

    if (payRollView.length > 0) {
      setPayroll(false);
    }

    if (checkOffDay.length > 0) {
      setOffDayWorking(false);
    }

    if (claimType.length > 0) {
      setClaimTypeCheck(false);
    }

    if (filteProject.length > 0) {
      setProjectCheck(false);
    }
    if (filterUseer.length > 0) {
      setUserCheck(true);
    }
    if (filterRole.length > 0) {
      setRoleCheck(true);
    }
    if (filterActiveTime.length > 0) {
      setActiveTimeCheck(false);
    }
    if (filterLeave.length > 0) {
      setLeaveCheck(false);
      setLeaveTypeCheck(false);
    }

    if (filterEmployee.length > 0) {
      setEmployeeeCheck(true);
      setDesignation(false);
    }
    if (filterDepartment.length > 0) {
      setDepartment(true);
    }
    if (filterMedia.length > 0) {
      seMediaCheck(false);
    }
    if (filterPermis.length > 0) {
      setPermisCheck(true);
    }
    if (filterPwd.length > 0 || filterOwnPwd.length > 0) {
      setPwdCheck(false);
    }
    if (filterDashboard.length > 0) {
      setDashboardCheck(false);
    }
    if (filterProfile.length > 0) {
      setProfilleCheck(false);
    }
    if (filterAnnounc.length > 0) {
      setAnnouncCheck(true);
    }
    if (filterNoticeBoard.length > 0) {
      setNoticeBoardCheck(true);
    }
    if (claimsCheck.length > 0 || approveCheck.length > 0) {
      setClaimsCheck(false);
    }

    if (filterDoc.length > 0 || filterOwnDoc.length > 0) {
      setDocCheck(false);
    }
    if (filterLog.length > 0) {
      setActivityLogCheck(true);
    }
    if (viewPerformanceAppraisal.length > 0) {
      setPerformance(false);
    }
    if (viewRecruitment.length > 0) {
      setRecruitment(false);
    }
    if (viewCRMList.length > 0) {
      setViewCRM(false);
    }
    if (manage_incentive.length > 0) {
      setCheckIncentive(false);
    }
    if (manage_exchangeRage.length > 0) {
      setCheckExchangeRage(false);
    }
    if (view_progresschecker.length > 0) {
      setViewProgressChecker(false);
    }
    if (view_progresschecker_acc.length > 0) {
      setViewProgressCheckerAcc(false);
    }
    if (manage_taskCategory.length > 0) {
      setTaskCategory(false);
    }
    if (filterCategory.length > 0) {
      setCategory(true);
    }
    if (faqView.length > 0) {
      setFAQ(true);
    }
    if (manualView.length > 0) {
      setManual(true);
    }
    if (remnderView.length > 0) {
      setReminder(true);
    }
  }, [userDatas]);

  const checkAlreadyAddedHR = (title: string) => {
    var isHaved = sidebarsDataHR[0].subcontents.find(
      (x: any) => x.title == title
    );
    return isHaved ? true : false;
  };

  const checkAlreadyAddedGeneral = (url: string) => {
    var isHaved = sidebarsDataGeneral[0].subcontents.find(
      (x: any) => x.title == url
    );
    return isHaved ? true : false;
  };

  var sidebarsDataGeneral: any = [
    {
      title: 'General Setting',
      id: 1,
      subcontents: [],
    },
  ];

  var sidebarsDataHR: any = [
    {
      title: 'HR MANAGEMENT',
      id: 1,
      subcontents: [],
    },
  ];

  var sidebarsAcademy: any = [
    {
      title: 'ACADEMY',
      id: 1,
      subcontents: [],
    },
  ];

  var sidebarsDataDashboard1: any = [
    {
      title: 'Main',
      id: 1,
      subcontents: [],
    },
  ];

  var sidebarsSetting: any = [
    {
      title: 'SETTING',
      id: 1,
      subcontents: [],
    },
  ];
  var sidebarsPages: any = [
    {
      title: 'Pages',
      id: 5,
      subcontents: [],
    },
  ];
  var sidebarsCompanyPages: any = [
    {
      title: 'Company Profile',
      id: 5,
      subcontents: [],
    },
  ];

  var sidebarsServicePages: any = [
    {
      title: 'Services',
      id: 6,
      subcontents: [],
    },
  ];
  var sidebarsContactPages: any = [
    {
      title: 'Contact Us',
      id: 69,
      subcontents: [],
    },
  ];
  var sidebarsLatestNewsPages: any = [
    {
      title: 'Latest News',
      id: 70,
      subcontents: [],
    },
  ];
  var sidebarsMediaReportsPages: any = [
    {
      title: 'Media Reports',
      id: 71,
      subcontents: [],
    },
  ];
  var sidebarsMediaResourcesPages: any = [
    {
      title: 'Media Resources',
      id: 72,
      subcontents: [],
    },
  ];
  var sidebarsJobOpportunitiesPages: any = [
    {
      title: 'Job Opportunities',
      id: 73,
      subcontents: [],
    },
  ];
  var sidebarsCareerPathPages: any = [
    {
      title: 'Career Path Development',
      id: 74,
      subcontents: [],
    },
  ];
  var sidebarFooter: any = [
    {
      title: 'Footer',
      id: 75,
      subcontents: [],
    },
  ];
  //Dashboar check
  //if (dashboardCheck) {
  sidebarsDataDashboard1[0].subcontents.push({
    id: 11,
    link: '/dashboard',
    title: 'Admin Dashboard',
    icon: dashboard,
    activeIcon: dashboard_active,
    active: '',
  });
  //}

  //for profile
  if (profileCheck && userJson?.user?.employee_id != 0) {
    sidebarsDataDashboard1[0].subcontents.push({
      id: 12,
      link: '/profile',
      title: 'My Profile',
      icon: profile,
      activeIcon: profile_active,
      active: '',
    });
  }

  //HR check
  sidebarsDataHR[0].subcontents.push({
    order: 1,
    id: 36,
    link: '/header',
    title: 'Header',
    icon: announcement,
    activeIcon: announcement_active,
    active: '',
  });
  sidebarFooter[0].subcontents.push({
    order: 2,
    id: 37,
    link: '#',
    title: 'Footer',
    icon: announcement,
    activeIcon: announcement_active,
    active: '',
    subMenuContents: []
  });
  sidebarsDataHR[0].subcontents.push({
    order: 3,
    id: 38,
    link: '/company-history',
    title: 'Company History',
    icon: announcement,
    activeIcon: announcement_active,
    active: '',
  });
  sidebarsDataHR[0].subcontents.push({
    order: 4,
    id: 39,
    link: '/vision-mission',
    title: 'Vision Mission',
    icon: announcement,
    activeIcon: announcement_active,
    active: '',
  });
  if (announcCheck) {
    sidebarsDataHR[0].subcontents.push({
      order: 5,
      id: 38,
      link: '/announcement',
      title: 'Announcement',
      icon: announcement,
      activeIcon: announcement_active,
      active: '',
    });
  }
  if (noticeBoardCheck) {
    sidebarsDataHR[0].subcontents.push({
      order: 6,
      id: 39,
      link: '/notice_board',
      title: 'Notice Board',
      icon: announcement,
      activeIcon: announcement_active,
      active: '',
    });
  }
  sidebarsDataHR[0].subcontents.push({
    order: 7,
    id: 40,
    link: '/news',
    title: 'News & Announcement',
    icon: announcement,
    activeIcon: announcement_active,
    active: '',
  });
  //Employee check
  if (employeeeCheck) {
    if (!checkAlreadyAddedHR('Employees')) {
      sidebarsDataHR[0].subcontents.push({
        order: 1,
        id: 31,
        link: '#',
        title: 'Employees',
        icon: employee,
        activeIcon: employee_active,
        active: '',
        subMenuContents: [],
      });
    }

    var isHaveSubContent = sidebarsDataHR[0].subcontents.find(
      (x: any) => x.title == 'Employees'
    );
    if (isHaveSubContent) {
      isHaveSubContent.subMenuContents.push({
        id: 311,
        link: '/employeeList',
        title: 'Employee List',
        label: 'Employee List',
        active: '',
      });
    }
  }
  var isFooterSubContent = sidebarFooter[0].subcontents.find(
    (x: any) => x.title == 'Footer'
  );
  if (isFooterSubContent) {
    isFooterSubContent.subMenuContents.push({
      id: 311,
      link: '/footer',
      title: 'Overview',
      label: 'Overview',
      active: '',
    });
    isFooterSubContent.subMenuContents.push({
      id: 311,
      link: '/footer/footerlist',
      title: 'Footer List',
      label: 'Footer List',
      active: '',
    });
    isFooterSubContent.subMenuContents.push({
      id: 311,
      link: '/footer/social',
      title: 'Social',
      label: 'Social',
      active: '',
    });
    isFooterSubContent.subMenuContents.push({
      id: 311,
      link: '/footer/privacy',
      title: 'Privacy',
      label: 'Privacy',
      active: '',
    });
    isFooterSubContent.subMenuContents.push({
      id: 311,
      link: '/footer/terms',
      title: 'Terms',
      label: 'Terms',
      active: '',
    });
  }
  //General setting check
  //for user
  if (userCheck) {
    if (!checkAlreadyAddedGeneral('Backend Users')) {
      sidebarsDataGeneral[0].subcontents.push({
        id: 41,
        link: '#',
        title: 'Backend Users',
        icon: bkuser,
        activeIcon: bkuser_active,
        active: '',
        subMenuContents: [],
      });
    }

    var isHaveSubContent = sidebarsDataGeneral[0].subcontents.find(
      (x: any) => x.title == 'Backend Users'
    );
    if (isHaveSubContent) {
      isHaveSubContent.subMenuContents.push({
        id: 411,
        link: '/users',
        title: 'Users',
        label: 'Users',
        active: '',
      });
    }
  }
  //for role
  if (roleCheck) {
    if (!checkAlreadyAddedGeneral('Backend Users')) {
      sidebarsDataGeneral[0].subcontents.push({
        id: 41,
        link: '#',
        title: 'Backend Users',
        icon: bkuser,
        activeIcon: bkuser_active,
        active: '',
        subMenuContents: [],
      });
    }

    var isHaveSubContent = sidebarsDataGeneral[0].subcontents.find(
      (x: any) => x.title == 'Backend Users'
    );

    if (isHaveSubContent) {
      isHaveSubContent.subMenuContents.push({
        id: 412,
        link: '/users/roles',
        title: 'Roles',
        label: 'Roles',
        active: '',
      });
    }
  }
  //for permissions
  if (permisCheck) {
    if (!checkAlreadyAddedGeneral('Backend Users')) {
      sidebarsDataGeneral[0].subcontents.push({
        id: 41,
        link: '#',
        title: 'Backend Users',
        icon: bkuser,
        activeIcon: bkuser_active,
        active: '',
        subMenuContents: [],
      });
    }

    var isHaveSubContent = sidebarsDataGeneral[0].subcontents.find(
      (x: any) => x.title == 'Backend Users'
    );
    if (isHaveSubContent) {
      isHaveSubContent.subMenuContents.push({
        id: 413,
        link: '/users/permissions',
        title: 'Permissions',
        label: 'Permissions',
        active: '',
      });
    }
  }
  if (activityLogCheck) {
    sidebarsDataGeneral[0].subcontents.push({
      id: 52,
      link: '/activity_log',
      title: 'Activity Log',
      icon: activityLog,
      activeIcon: activityLog_active,
      active: '',
    });
  }
  if (department) {
    sidebarsSetting[0].subcontents.push({
      order: 16,
      id: 46,
      link: '/departments',
      title: 'Department',
      icon: claimForm,
      activeIcon: claimFormActive,
      active: '',
    });
  }
  if (category) {
    sidebarsSetting[0].subcontents.push({
      order: 17,
      id: 47,
      link: '/category',
      title: 'Category',
      icon: claimForm,
      activeIcon: claimFormActive,
      active: '',
    });
  }
  if (faq) {
    sidebarsSetting[0].subcontents.push({
      order: 18,
      id: 48,
      link: '/faq',
      title: 'FAQ',
      icon: claimForm,
      activeIcon: claimFormActive,
      active: '',
    });
    sidebarsSetting[0].subcontents.push({
      order: 19,
      id: 49,
      link: '/faq_types',
      title: 'FAQ Type',
      icon: claimForm,
      activeIcon: claimFormActive,
      active: '',
    });
  }
  if (manual) {
    sidebarsSetting[0].subcontents.push({
      order: 20,
      id: 50,
      link: '/manuals',
      title: 'Manuals',
      icon: claimForm,
      activeIcon: claimFormActive,
      active: '',
    });
  }
  if (manual) {
    sidebarsSetting[0].subcontents.push({
      order: 21,
      id: 50,
      link: '/usefullinks',
      title: 'Useful Link',
      icon: claimForm,
      activeIcon: claimFormActive,
      active: '',
    });
  }
  if (manual) {
    sidebarsSetting[0].subcontents.push({
      order: 22,
      id: 51,
      link: '/cms',
      title: 'CMS',
      icon: claimForm,
      activeIcon: claimFormActive,
      active: '',
    });
  }
  sidebarsSetting[0].subcontents.push({
    order: 23,
    id: 52,
    link: '/eventtype',
    title: 'Event Type',
    icon: claimForm,
    activeIcon: claimFormActive,
    active: '',
  });
  if (reminder) {
    sidebarsSetting[0].subcontents.push({
      order: 24,
      id: 53,
      link: '/event-reminder',
      title: 'Event Reminder',
      icon: claimForm,
      activeIcon: claimFormActive,
      active: '',
    });
  }
  if (reminder) {
    sidebarsSetting[0].subcontents.push({
      order: 24,
      id: 53,
      link: '/workgroups',
      title: 'Workgroup',
      icon: claimForm,
      activeIcon: claimFormActive,
      active: '',
    });
  }

  sidebarsSetting[0].subcontents.push({
    order: 25,
    id: 54,
    link: '/custom_logo',
    title: 'Mobile Logo',
    icon: claimForm,
    activeIcon: claimFormActive,
    active: '',
  });
  sidebarsSetting[0].subcontents.push({
    order: 26,
    id: 55,
    link: '/announcement-type',
    title: 'Announcement Type',
    icon: claimForm,
    activeIcon: claimFormActive,
    active: '',
  });
  sidebarsPages[0].subcontents.push({
    order: 1,
    id: 1,
    link: '#',
    title: 'Home',
    icon: claimForm,
    activeIcon: claimFormActive,
    active: '',
    subMenuContents: [],
  });
  var isHaveSubContentPage = sidebarsPages[0].subcontents.find(
    (x: any) => x.title == 'Home'
  );
  if (isHaveSubContentPage) {
    isHaveSubContentPage.subMenuContents.push({
      id: 41,
      link: '/pages/home',
      title: 'Home',
      label: 'Home',
      active: '',
    });
    isHaveSubContentPage.subMenuContents.push({
      id: 46,
      link: '/pages/banner',
      title: 'Banner',
      label: 'Banner',
      active: '',
    });
    isHaveSubContentPage.subMenuContents.push({
      id: 42,
      link: '/pages/who-we-are',
      title: 'Who We Are',
      label: 'Who We Are',
      active: '',
    });
    isHaveSubContentPage.subMenuContents.push({
      id: 43,
      link: '/pages/services',
      title: 'Services',
      label: 'Services',
      active: '',
    });
    isHaveSubContentPage.subMenuContents.push({
      id: 44,
      link: '/pages/why-choose-us',
      title: 'Why Choose Us',
      label: 'Why Choose Us',
      active: '',
    });

    isHaveSubContentPage.subMenuContents.push({
      id: 45,
      link: '/pages/airlines',
      title: 'Customer Airline',
      label: 'Customer Airline',
      active: '',
    });
  }
  // sidebarsSetting[0].subcontents.push({
  //   order: 18,
  //   id: 48,
  //   link: '/language',
  //   title: 'Language',
  //   icon: language,
  //   activeIcon: languageActive,
  //   active: '',
  // });
  sidebarsCompanyPages[0].subcontents.push({
    order: 1,
    id: 1,
    link: '#',
    title: 'Company',
    icon: claimForm,
    activeIcon: claimFormActive,
    active: '',
    subMenuContents: [

    ],
  });
  var isHaveSubContentCompanyProfile = sidebarsCompanyPages[0].subcontents.find(
    (x: any) => x.title == 'Company'
  );
  if (isHaveSubContentCompanyProfile) {
    isHaveSubContentCompanyProfile.subMenuContents.push({
      id: 1,
      link: '/company-profile/our-story',
      title: 'Our Story',
      label: 'Our Story',
      active: '',
    });
    isHaveSubContentCompanyProfile.subMenuContents.push({
      id: 2,
      link: '/company-profile/vision-mission',
      title: 'Vision Mission',
      label: 'Vision Mission',
      active: '',
    });
    isHaveSubContentCompanyProfile.subMenuContents.push({
      id: 3,
      link: '/company-profile/leadership',
      title: 'LeaderShip',
      label: 'LeaderShip',
      active: '',
    });
    isHaveSubContentCompanyProfile.subMenuContents.push({
      id: 4,
      link: '/company-profile/board_of_directors',
      title: 'Board of Directors',
      label: 'Board of Directors',
      active: '',
    })
    isHaveSubContentCompanyProfile.subMenuContents.push({
      id: 5,
      link: '/company-profile/executive_leadership',
      title: 'Executive Leadership',
      label: 'Executive Leadership',
      active: '',
    });
    isHaveSubContentCompanyProfile.subMenuContents.push({
      id: 6,
      link: '/company-profile/awards',
      title: 'Award Page',
      label: 'Award Page',
      active: '',
    });
    isHaveSubContentCompanyProfile.subMenuContents.push({
      id: 7,
      link: '/company-profile/awards_list',
      title: 'Award List',
      label: 'Award List',
      active: '',
    });
    isHaveSubContentCompanyProfile.subMenuContents.push({
      id: 7,
      link: '/company-profile/certificate_list',
      title: 'Certification',
      label: 'Certification',
      active: '',
    });
    isHaveSubContentCompanyProfile.subMenuContents.push({
      id: 8,
      link: '/company-profile/sustainable',
      title: 'Sustainable',
      label: 'Sustainable',
      active: '',
    });
    isHaveSubContentCompanyProfile.subMenuContents.push({
      id: 9,
      link: '/company-profile/sustainable_list',
      title: 'Sustainable List',
      label: 'Sustainable List',
      active: '',
    });
    isHaveSubContentCompanyProfile.subMenuContents.push({
      id: 9,
      link: '/company-profile/airlines-page',
      title: 'Airlines Page',
      label: 'Airlines Page',
      active: '',
    })
    isHaveSubContentCompanyProfile.subMenuContents.push({
      id: 9,
      link: '/company-profile/airlines',
      title: 'Airlines',
      label: 'Airlines',
      active: '',
    })
  }

  // start services
  sidebarsServicePages[0].subcontents.push({
    order: 1,
    id: 1,
    link: '#',
    title: 'Services',
    icon: claimForm,
    activeIcon: claimFormActive,
    active: '',
    subMenuContents: [],
  });
  var isHaveSubContentServicesPage = sidebarsServicePages[0].subcontents.find(
    (x: any) => x.title == 'Services'
  );
  if (isHaveSubContentServicesPage) {
    isHaveSubContentServicesPage.subMenuContents.push({
      id: 80,
      link: '/services/passenger-overview',
      title: 'Passenger Overview',
      label: 'Overview',
      active: '',
    });
    isHaveSubContentServicesPage.subMenuContents.push({
      id: 98,
      link: '/services/ramp-overview',
      title: 'Ramp Overview',
      label: 'Ramp Overview',
      active: '',
    });
    isHaveSubContentServicesPage.subMenuContents.push({
      id: 99,
      link: '/services/load-control-overview',
      title: 'Load Control Overview',
      label: 'Load Control Overview',
      active: '',
    });
    isHaveSubContentServicesPage.subMenuContents.push({
      id: 81,
      link: '/services/service-list',
      title: 'Service List',
      label: 'Service List',
      active: '',
    });
  }
  // end services
  //start contact us
  sidebarsContactPages[0].subcontents.push({
    order: 1,
    id: 1,
    link: '#',
    title: 'Contact Us',
    icon: claimForm,
    activeIcon: claimFormActive,
    active: '',
    subMenuContents: [],
  });
  var isHaveSubContact = sidebarsContactPages[0].subcontents.find(
    (x: any) => x.title == 'Contact Us'
  );
  if (isHaveSubContact) {
    isHaveSubContact.subMenuContents.push({
      id: 80,
      link: '/contact-us/overview',
      title: 'Overview',
      label: 'Overview',
      active: '',
    });
    isHaveSubContact.subMenuContents.push({
      id: 98,
      link: '/contact-us/online-enquiry',
      title: 'Online Enquiry Overview',
      label: 'Online Enquiry Overview',
      active: '',
    });
    isHaveSubContact.subMenuContents.push({
      id: 99,
      link: '/contact-us/locations',
      title: 'Location Overview',
      label: 'Location Overview',
      active: '',
    });
    isHaveSubContact.subMenuContents.push({
      id: 99,
      link: '/contact-us/locations-list',
      title: 'Location List',
      label: 'Location List',
      active: '',
    });
  }
  //end contact us
  // start latest news
  sidebarsLatestNewsPages[0].subcontents.push({
    order: 1,
    id: 1,
    link: '#',
    title: 'Latest News',
    icon: claimForm,
    activeIcon: claimFormActive,
    active: '',
    subMenuContents: [],
  });
  var isHaveSubContentLatestNewsPage = sidebarsLatestNewsPages[0].subcontents.find(
    (x: any) => x.title == 'Latest News'
  );
  if (isHaveSubContentLatestNewsPage) {
    isHaveSubContentLatestNewsPage.subMenuContents.push({
      id: 82,
      link: '/latest-news/overview',
      title: 'Overview',
      label: 'Overview',
      active: '',
    });
    isHaveSubContentLatestNewsPage.subMenuContents.push({
      id: 83,
      link: '/latest-news/latest-news-list',
      title: 'Latest News List',
      label: 'Latest News List',
      active: '',
    });
    isHaveSubContentLatestNewsPage.subMenuContents.push({
      id: 84,
      link: '/latest-news/category',
      title: 'Categories',
      label: 'Categories',
      active: '',
    });
  }
  // end latest news

  // start media reports
  sidebarsMediaReportsPages[0].subcontents.push({
    order: 1,
    id: 1,
    link: '#',
    title: 'Media Reports',
    icon: claimForm,
    activeIcon: claimFormActive,
    active: '',
    subMenuContents: [],
  });
  var isHaveSubContentMediaReportsPage = sidebarsMediaReportsPages[0].subcontents.find(
    (x: any) => x.title == 'Media Reports'
  );
  if (isHaveSubContentMediaReportsPage) {
    isHaveSubContentMediaReportsPage.subMenuContents.push({
      id: 95,
      link: '/media-reports/overview',
      title: 'Overview',
      label: 'Overview',
      active: '',
    });
    isHaveSubContentMediaReportsPage.subMenuContents.push({
      id: 96,
      link: '/media-reports/media-reports-list',
      title: 'Media Reports List',
      label: 'Media Reports List',
      active: '',
    });
    isHaveSubContentMediaReportsPage.subMenuContents.push({
      id: 97,
      link: '/media-reports/category',
      title: 'Categories',
      label: 'Categories',
      active: '',
    });
  }
  // end media reports

  // start media resources
  sidebarsMediaResourcesPages[0].subcontents.push({
    order: 1,
    id: 1,
    link: '#',
    title: 'Media Resources',
    icon: claimForm,
    activeIcon: claimFormActive,
    active: '',
    subMenuContents: [],
  });
  var isHaveSubContentMediaResourcesPage = sidebarsMediaResourcesPages[0].subcontents.find(
    (x: any) => x.title == 'Media Resources'
  );
  if (isHaveSubContentMediaResourcesPage) {
    isHaveSubContentMediaResourcesPage.subMenuContents.push({
      id: 88,
      link: '/media-resources/overview',
      title: 'Overview',
      label: 'Overview',
      active: '',
    });
    isHaveSubContentMediaResourcesPage.subMenuContents.push({
      id: 89,
      link: '/media-resources/resources',
      title: 'Resources',
      label: 'Resources',
      active: '',
    });
    isHaveSubContentMediaResourcesPage.subMenuContents.push({
      id: 90,
      link: '/media-resources/download-items',
      title: 'Download Items',
      label: 'Download Items',
      active: '',
    });

  }
  // end media resources

  // start Job Opportunities
  sidebarsJobOpportunitiesPages[0].subcontents.push({
    order: 1,
    id: 1,
    link: '#',
    title: 'Job Opportunities',
    icon: claimForm,
    activeIcon: claimFormActive,
    active: '',
    subMenuContents: [],
  });
  var isHaveSubContentJobOpportunitiesPage = sidebarsJobOpportunitiesPages[0].subcontents.find(
    (x: any) => x.title == 'Job Opportunities'
  );
  if (isHaveSubContentJobOpportunitiesPage) {
    isHaveSubContentJobOpportunitiesPage.subMenuContents.push({
      id: 91,
      link: '/job-opportunities/overview',
      title: 'Overview',
      label: 'Overview',
      active: '',
    });
    isHaveSubContentJobOpportunitiesPage.subMenuContents.push({
      id: 92,
      link: '/job-opportunities/job-openings',
      title: 'Job Openings',
      label: 'Job Openings',
      active: '',
    });
    isHaveSubContentJobOpportunitiesPage.subMenuContents.push({
      id: 93,
      link: '/job-opportunities/job-applications',
      title: 'Job Applications',
      label: 'Job Applications',
      active: '',
    });
    isHaveSubContentJobOpportunitiesPage.subMenuContents.push({
      id: 94,
      link: '/job-opportunities/job-category',
      title: 'Job Categories',
      label: 'Job Categories',
      active: '',
    });
    isHaveSubContentJobOpportunitiesPage.subMenuContents.push({
      id: 95,
      link: '/job-opportunities/job-department',
      title: 'Job Departments',
      label: 'Job Departments',
      active: '',
    });

  }
  // end Job Opportunities

  // start career path
  sidebarsCareerPathPages[0].subcontents.push({
    order: 1,
    id: 1,
    link: '#',
    title: 'Career Path Developments',
    icon: claimForm,
    activeIcon: claimFormActive,
    active: '',
    subMenuContents: [],
  });
  var isHaveSubContentCareerPathPage = sidebarsCareerPathPages[0].subcontents.find(
    (x: any) => x.title == 'Career Path Developments'
  );
  if (isHaveSubContentCareerPathPage) {
    isHaveSubContentCareerPathPage.subMenuContents.push({
      id: 96,
      link: '/career-path-development/overview',
      title: 'Overview',
      label: 'Overview',
      active: '',
    });
  }
  // end career path

  const sidebarsData = [
    ...sidebarsDataDashboard1,
    ...sidebarsPages,
    ...sidebarsCompanyPages,
    ...sidebarsServicePages,
    ...sidebarsContactPages,
    ...sidebarsLatestNewsPages,
    ...sidebarsMediaReportsPages,
    ...sidebarsMediaResourcesPages,
    ...sidebarsJobOpportunitiesPages,
    ...sidebarsCareerPathPages,
    ...sidebarsAcademy,
    ...sidebarFooter,
    //...sidebarsDataProject,
    ...sidebarsDataHR,
    ...sidebarsDataGeneral,
    ...sidebarsSetting,
  ];

  // console.log("main", sidebarsData);

  return sidebarsData;
};

export default SidebarFunc;
