
import { FC } from "react";
interface ILabelAndNumber {
  refId?: any;
  label: string;
  value: number;
  setValue: (value: React.SetStateAction<string>) => void;
}
const LabelAndNumber: FC<ILabelAndNumber> = ({
  label,
  value,
  setValue
}) => {
  return (
    <div className="mb-5 last:mb-0">
      <label className="text-black2 text-15 font-medium font-primary mb-[0.688rem] block">
        {label}
      </label>
      <input
        type="number"
        name="number"
        value={value}
        onChange={event => setValue(event.target.value)}
        placeholder=""
        className="focus:outline-none focus-visible:outline-none w-full rounded py-3.5 px-3 border border-goalinputborder text-black2 text-14 font-medium font-primary placeholder:text-graydark h-[48px]"
      />
    </div>
  );
};
export default LabelAndNumber;
