import { FC, useEffect, useState } from "react";
import { baseObj } from "../../../utils/constants";
import { ColumnFiltersState, PaginationState } from "@tanstack/react-table";
import { templateList } from "../../../active-time-report/views/ActiveTimeReport";
import { twJoin } from "tailwind-merge";
import Button from "../../../components/common/Button";
import AwardAndCertificateInput from "./AwardAndCertificateInput";
import AwardsAndCertificateTable from "./AwardsAndCertificateTable";
import { useGetAwardInfoMutation, useGetAwardsListQuery } from "../../../app/services/company/award";
import axios from "../../../axios";
import { endpointUrl } from "../../../app/services/api";
import { ToastContainer, toast } from "react-toastify";
import CommonErrorPopup from "../../../components/common/CommonErrorPopup";
import { renderDateFormatDMY } from "../../../components/DashboardCalendarView/DashboardAddEventPopup";
export interface IAwardAndCertificate {
    id:number;
    date?: Date|undefined;
    title_en: string;
    title_tc: string;
    title_sc: string;
    description_en: string;
    description_tc: string;
    description_sc: string;
    image: File | undefined;
    image_url: string;
}
export interface IAwardAndCertificateList {
    data_list: baseObj[];
    showData?:boolean;
}
const AwardAndCertificateList:FC<IAwardAndCertificateList> = ({
    data_list,
    showData
}) => {
    const [editRowData, setEditRowData] = useState<any>();
    const [detailData, setDetailData] = useState<IAwardAndCertificate>({
        id:0,
        title_en:'',
        title_tc:'',
        title_sc:'',
        description_en:'',
        description_tc:'',
        description_sc:'',
        image:undefined,
        image_url:''
    });
    const [show, setShow] = useState<boolean>(true);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [viewCols, setCols] = useState<any>([]);
    const [isLayoutControlOpen, setIsLayoutControlOpen] = useState<boolean>(
        false
    );
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    let [localModuleId, setLocalModuleId] = useState<number>(0);
    const [data, setData] = useState<baseObj[]>([]);
    const [itemCount, setItemCount] = useState<number>(0);
    const [tempList, setTempList] = useState<any>(templateList);
    const [hiddenFields, setHiddenFields] = useState<string[]>([]);
    const [globalSearch, setGlobalSearch] = useState<string>("");
    const [getAwardsInfo]=useGetAwardInfoMutation();
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 20
    });
    const [errorPopup, setErrorPopup] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [isSave, setIsSave] = useState<boolean>(false);
    const {data:awards}=useGetAwardsListQuery({
        page:currentPage,
        search:globalSearch,
        limit:pagination.pageSize
    });
    useEffect(()=>{
        if(awards){
            setData(awards?.data);
            setItemCount(awards?.total);
        }
    },[awards]);
    const handle_submit=async(data:IAwardAndCertificate)=>{
        const formData=new FormData();
        if(data){
            formData.set('id',data.id?.toString()??0)
            formData.set('title_en',data.title_en);
            formData.set('title_tc',data.title_tc);
            formData.set('title_sc',data.title_sc);
            formData.set('description_en',data.description_en);
            formData.set('description_tc',data.description_tc);
            formData.set('description_sc',data.description_sc);
            if(data.date){
                formData.set('date',renderDateFormatDMY(data.date,'-'));
            }
            
            if(data.image){
                formData.set('image',data.image);
            }
            const url = detailData?.id ? 'update-certification-content' : 'certification-content';
        await axios(endpointUrl + url, {
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: formData,
        })
            .then((res: any) => {
                if (res?.data?.status == true) {
                    getAwardsInfo({
                        page: currentPage,
                        limit: pagination.pageSize,
                        search: ''
                    }).then((res: any) => {
                        setData(res?.data?.data)
                    })
                    toast(res?.data?.message, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        type: "success",
                    });
                    setShow(true);
                } else {
                    setErrorPopup(true);
                    setMessage(res?.response?.data?.message);
                }
                setIsSave(false);
            })
            .catch((err) => {
                setErrorPopup(true);
                setMessage(err?.data?.message);
            });
        }
        
    }
    const clear_data=()=>{
        setDetailData({ 
            id:0,
            title_en:'',
            title_tc:'',
            title_sc:'',
            description_en:'',
            description_tc:'',
            description_sc:'',
            image:undefined,
            image_url:''
        })
    }
    return <div
        className={twJoin(
            "px-2 sm:px-4 py-4 bg-white sm:mt-[25px] mt-0 font-poppins text-13 font-semibold text-[#858795] tracking-[-0.03rem] w-full h-full rounded-xl pt-3 pb-8 sm:pt-3 sm:pb-9 "
        )}
    >
        <ToastContainer className={"text-12 font-poppins font-normal"} />
        <CommonErrorPopup
         show={errorPopup}
         setShow={setErrorPopup}
         message={message}
        />
        <div className="text-left">
            {show == false ? <AwardAndCertificateInput handle_submit={handle_submit} isSave={isSave} isShowDate={showData} awards={detailData} setAwards={setDetailData} setShow={setShow}/> :
                <>
                    <div className="items-center xs:mt-1 sm:mt-1 flex justify-end mb-3">
                        <Button
                            label="+ Add New"
                            onClick={() => {clear_data();setShow(false)}}
                            type="button"
                            variant="primary"
                            customClass="py-[3px] my-1 px-2 focus:outline-none hover:bg-vorpmyanmar hover:border-vorpmyanmar hover:text-vorpblue 2xl:text-[14px] text-12 leading-5 font-medium text-white"
                            size="base"
                        />
                    </div>
                    <AwardsAndCertificateTable
                        // columnsData={columnsData}
                        isLayoutControlOpen={isLayoutControlOpen}
                        setIsLayoutControlOpen={setIsLayoutControlOpen}
                        globalSearch={globalSearch}
                        setGlobalSearch={setGlobalSearch}
                        columnFilters={columnFilters}
                        setColumnFilters={setColumnFilters}
                        pagination={pagination}
                        data={data}
                        setData={setData}
                        setPagination={setPagination}
                        hiddenFields={hiddenFields ? hiddenFields : []}
                        setHiddenFields={setHiddenFields}
                        checkManagement={true}
                        total={itemCount}
                        localModuleId={localModuleId}
                        // visArray={visArray}
                        templateList={tempList}
                        setCurrentView={() => 1}
                        setCols={setCols}
                        setCurrentPage={setCurrentPage}
                        currentPage={currentPage}
                        setEditRowData={setEditRowData}
                        editRowData={editRowData}
                        setShowList={setShow}
                        setDetailData={setDetailData}
                    />
                </>
            }
        </div>
    </div>
}
export default AwardAndCertificateList;