import { FC, useEffect, useState } from "react";
import Button from "../../components/common/Button";
import CompanyHistoryTable from "./CompanyHistoryTable";
import { ColumnFiltersState, PaginationState } from "@tanstack/react-table";
import { baseObj } from "../../utils/constants";
import { templateList } from "../../active-time-report/views/ActiveTimeReport";
import { twJoin } from "tailwind-merge";
import CompanyHistoryInput, { ICompanyHistoryInputFields } from "./CompanyHistoryInput";
import BoardOfDirectoryTable from "./BoardOfDirectoryTable";
import BoardOfDirectoryInput, { IBoardOfDirectoryInputFields } from "./BoardOfDirectoryInput";
import { useGetLeaderShipDataQuery, useGetLeadershipInfoMutation } from "../../app/services/company/leadership";
import { endpointUrl } from "../../app/services/api";
import axios from "../../axios";
import { toast } from "react-toastify";
export interface ILeadership {
    start_year: number;
    end_year: number;
    description: string;
    image: File | undefined;
    image_url: string;
    
}
export interface ILeadershipList {
    leadership_list: baseObj[];
    type:string;
}
const LeadershipList: FC<ILeadershipList> = ({
    leadership_list,
    type
}) => {
    const [editRowData, setEditRowData] = useState<any>();
    const [detailData, setDetailData] = useState<IBoardOfDirectoryInputFields>({
        id:0,
        name_en:'',
        name_tc:'',
        name_sc:'',
        position_en:'',
        position_tc:'',
        position_sc:'',
        image:undefined,
        image_url:'',
        order:0
    });
    const [show, setShow] = useState<boolean>(true);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [viewCols, setCols] = useState<any>([]);
    const [isLayoutControlOpen, setIsLayoutControlOpen] = useState<boolean>(
        false
    );
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    let [localModuleId, setLocalModuleId] = useState<number>(0);
    const [data, setData] = useState<baseObj[]>([]);
    const [itemCount, setItemCount] = useState<number>(0);
    const [tempList, setTempList] = useState<any>(templateList);
    const [hiddenFields, setHiddenFields] = useState<string[]>([]);
    const [globalSearch, setGlobalSearch] = useState<string>("");
    const [leaderShipType, setLeaderShipType] = useState<string>(type);
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 20
    });
    const [errorPopup, setErrorPopup] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [isSave, setIsSave] = useState<boolean>(false);
    const [getLeaderShipData]=useGetLeadershipInfoMutation();
    const { data: leadership } = useGetLeaderShipDataQuery({
        limit: pagination.pageSize,
        page: currentPage,
        search: globalSearch,
        type: leaderShipType
    });
    useEffect(() => {
        if (leadership) {
            setData(leadership.data);
            setItemCount(leadership.total);
        }
    }, [leadership]);
    const clear_data = () => {
        setDetailData({
            id: 0,
            image: undefined,
            image_url: '',
            name_en: '',
            name_tc: '',
            name_sc: '',
            position_en: '',
            position_tc: '',
            position_sc: '',
            order:0
        })
    }
    const handle_submit=async(data:IBoardOfDirectoryInputFields)=>{
        const formData=new FormData();
        formData.set('id',data.id?.toString()??'0');
        formData.set('name_en',data.name_en);
        formData.set('name_tc',data.name_tc);
        formData.set('name_sc',data.name_sc);
        formData.set('position_en',data.name_en);
        formData.set('position_tc',data.name_tc);
        formData.set('position_sc',data.name_sc);
        formData.set('position_sc',data.name_sc);
        formData.set('order',data.order?.toString()??'0');
        formData.set('type',type);
        if(data.image){
            formData.set('image',data.image);
        }
        const url = data?.id ? 'update-leadership-people-content' : 'leadership-people-content';
        await axios(endpointUrl + url, {
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: formData,
        })
            .then((res: any) => {
                if (res?.data?.status == true) {
                    getLeaderShipData({
                        page:currentPage,
                        limit:pagination.pageSize,
                        search:'',
                        type:type
                    }).then((res:any)=>{
                        console.log('res',res)
                        setData(res?.data?.data)
                    })
                    toast(res?.data?.message, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        type: "success",
                    });
                    setShow(true);
                } else {
                    setErrorPopup(true);
                    setMessage(res?.data?.message);
                }
                setIsSave(false);
            })
            .catch((err) => {
                setErrorPopup(true);
                setMessage(err?.response?.data?.message);
            });
    }
    return <div
        className={twJoin(
            "px-2 sm:px-4 py-4 bg-white sm:mt-[25px] mt-0 font-poppins text-13 font-semibold text-[#858795] tracking-[-0.03rem] w-full h-full rounded-xl pt-3 pb-8 sm:pt-3 sm:pb-9 "
        )}
    >
        <div className="text-left">
            {show == false ? <BoardOfDirectoryInput handle_submit={handle_submit} director={detailData} setDirector={setDetailData} setShow={setShow} isSave={isSave}/> :
                <>
                    <div className="items-center xs:mt-1 sm:mt-1 flex justify-end mb-3">
                        <Button
                            label="+ Add New"
                            onClick={() => {clear_data();setShow(false)}}
                            type="button"
                            variant="primary"
                            customClass="py-[3px] my-1 px-2 focus:outline-none hover:bg-vorpmyanmar hover:border-vorpmyanmar hover:text-vorpblue 2xl:text-[14px] text-12 leading-5 font-medium text-white"
                            size="base"
                        />
                    </div>
                    <BoardOfDirectoryTable
                        // columnsData={columnsData}
                        isLayoutControlOpen={isLayoutControlOpen}
                        setIsLayoutControlOpen={setIsLayoutControlOpen}
                        globalSearch={globalSearch}
                        setGlobalSearch={setGlobalSearch}
                        columnFilters={columnFilters}
                        setColumnFilters={setColumnFilters}
                        pagination={pagination}
                        data={data}
                        setData={setData}
                        setPagination={setPagination}
                        hiddenFields={hiddenFields ? hiddenFields : []}
                        setHiddenFields={setHiddenFields}
                        checkManagement={true}
                        total={itemCount}
                        localModuleId={localModuleId}
                        // visArray={visArray}
                        templateList={tempList}
                        setCurrentView={() => 1}
                        setCols={setCols}
                        setCurrentPage={setCurrentPage}
                        currentPage={currentPage}
                        setEditRowData={setEditRowData}
                        editRowData={editRowData}
                        setShowList={setShow}
                        setDetailData={setDetailData}
                    />
                </>
            }
        </div>
    </div>
}
export default LeadershipList;