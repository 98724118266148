import { FC, useEffect, useState } from "react";
import DebounceSearchInput from "../../components/Tables/DebounceSearchInput";
import { SearchIcon } from "../../components/common/Icons";
import Button from "../../components/common/Button";
import { NumberDropdownList } from "../../components/common/DropdownList";
import { ColumnFiltersState, PaginationState } from "@tanstack/react-table";
import HeaderTable from "./HeaderTable";
import { baseObj } from "../../utils/constants";
import { templateList } from "../../active-time-report/views/ActiveTimeReport";
import SubMenuHeaderTable from "./SubMenuHeaderTable";
interface IHeaderSubmenu {
  submenu: ISubmenu[];
  setShowList:React.Dispatch<React.SetStateAction<boolean>>;
  setDetailData: React.Dispatch<any>;
}
interface ISubmenu {
  id: number;
  menu_id: number;
  title_en: string;
  title_tc: string;
  title_sc: string;
  slug: string;
  status: 1;
  order_list: 0;
}
interface IFilterStates {
  tag: string;
  type: string;
}
const HeaderSubmenuList: FC<IHeaderSubmenu> = ({ submenu,setShowList,setDetailData }) => {
  const [editRowData, setEditRowData] = useState<any>(submenu);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [viewCols, setCols] = useState<any>([]);
  const [isLayoutControlOpen, setIsLayoutControlOpen] = useState<boolean>(
    false
  );
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  let [localModuleId, setLocalModuleId] = useState<number>(0);
  const [data, setData] = useState<baseObj[]>(submenu);
  const [itemCount, setItemCount] = useState<number>(0);
  const [tempList, setTempList] = useState<any>(templateList);
  const [hiddenFields, setHiddenFields] = useState<string[]>([]);
  const [globalSearch, setGlobalSearch] = useState<string>("");
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20
  });
  useEffect(()=>{
    if(submenu){
      setData(submenu);
    }
  },[submenu])
  return (
    <div className="bg-white rounded-xl pt-4 pb-4 sm:pt-4 sm:pb-9 w-full h-full font-primary mt-5">
      <h2 className="text-sm font-medium text-black mb-5">SubMenus</h2>
      <div>
        <div className="text-left">
          <SubMenuHeaderTable
            // columnsData={columnsData}
            isLayoutControlOpen={isLayoutControlOpen}
            setIsLayoutControlOpen={setIsLayoutControlOpen}
            globalSearch={globalSearch}
            setGlobalSearch={setGlobalSearch}
            columnFilters={columnFilters}
            setColumnFilters={setColumnFilters}
            pagination={pagination}
            data={data}
            setData={setData}
            setPagination={setPagination}
            hiddenFields={hiddenFields ? hiddenFields : []}
            setHiddenFields={setHiddenFields}
            checkManagement={true}
            total={itemCount}
            localModuleId={localModuleId}
            // visArray={visArray}
            templateList={tempList}
            setCurrentView={() => 1}
            setCols={setCols}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            setEditRowData={setDetailData}
            editRowData={editRowData}
            setShowList={setShowList}
          />
        </div>
      </div>
    </div>
  );
};
//

export default HeaderSubmenuList;
