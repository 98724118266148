import { FC, useState } from "react";
import { CancelIcon, SaveIcon } from "../../components/common/Icons";
import styles from "./JobApplication.module.scss";
interface IData {
  initialData: JobApplicationType;
  setValue: React.Dispatch<any>;
  setShowList: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface JobApplicationType {
  id: number;
  job_id: number;

  source_type: number;
  expected_salary: string;
  date_of_avaibility: string;
  reference_in_Pre_offer_notice: string;

  category: string;
  first_name: string;
  last_name: string;
  first_name_in_chinese: string;
  last_name_in_chinese: string;
  gender: number;
  dob: string;
  phone: string;
  email: string;
  address: string;
  are_you_pr_of_hk: boolean;
  hkid_or_passport: string;
  chinese_commercial_code: string;
  have_right_abode_in_hk: boolean;
  require_hk_work_visa: boolean;
  emergency_contact_name: string;
  emergency_contact_relationship: string;
  emergency_contact_call_number: string;

  can_work_overtime: boolean;
  can_work_overtime_with_short_notice: boolean;
  can_work_rotating_shift: boolean;
  can_work_at_night: boolean;
  can_work_night_shift:boolean;
  can_work_on_sat_sun_holiday: boolean;
  convicted_or_accused_of_criminal: boolean;
  declared_bankrupt_or_invlolved: boolean;
  previously_issued_ara_permit: boolean;
  have_rejected_for_applying_airpot_restricted_area_permit: boolean;

  working_experiences: string;
  professional_qualification: string;
  academic_background: string;

  educations: string;
  english: string;
  cantonese: string;
  mandarin: string;
  other_language: string;
  other_language_name: string;

  are_family_or_friends_employed_by_our_company: string;
  are_family_member_related_with_satshk: string;
  declare: boolean;
  authorize: boolean;
  have_read: boolean;
  application_signature: undefined;
}

const options = [
  {
    label: "No Experience",
    value: "1",
  },
  {
    label: "DSE or above",
    value: "2",
  },
  {
    label: "Diploma or above",
    value: "3",
  },
  {
    label: "Degree or above",
    value: "4",
  },
];

const JobApplication: FC<IData> = ({ setShowList, initialData }) => {

  return (
    <div>
      <h2 className="text-lg text-black font-bold text-left my-5">
        Job Application
      </h2>
      <div className="overflow-x-scroll lg:overflow-x-hidden">
        <table className={styles["job-table"]}>
          <tbody>
            <tr>
              <td>Category</td>
              <td>{initialData?.category || "-"}</td>
            </tr>
            <tr>
              <td>Expected Salary</td>
              <td>{initialData?.expected_salary || "-"}</td>
            </tr>
            <tr>
              <td>Date of Availablity</td>
              <td>{initialData?.date_of_avaibility || "-"}</td>
            </tr>
            <tr>
              <td>Reference in Pre-offer notice</td>
              <td>{initialData?.reference_in_Pre_offer_notice || "-"}</td>
            </tr>
            <tr>
              <td>First Name</td>
              <td>{initialData?.first_name || "-"}</td>
            </tr>
            <tr>
              <td>Last Name</td>
              <td>{initialData?.last_name || "-"}</td>
            </tr>
            <tr>
              <td>{`First Name (in Chinese) (Same as HKID)`}</td>
              <td>{initialData?.first_name_in_chinese || "-"}</td>
            </tr>
            <tr>
              <td>{`Last Name (in Chinese) (Same as HKID)`}</td>
              <td>{initialData?.last_name_in_chinese || "-"}</td>
            </tr>
            <tr>
              <td>Sex</td>
              <td>{initialData?.gender && (initialData.gender > 1 ? 'Female':'Male')}</td>
            </tr>
            <tr>
              <td>Date of Birth *</td>
              <td>{initialData?.dob || "-"}</td>
            </tr>
            <tr>
              <td>Phone Number</td>
              <td>{initialData?.phone || "-"}</td>
            </tr>
            <tr>
              <td>Email Address</td>
              <td>{initialData?.email || "-"}</td>
            </tr>
            <tr>
              <td>Address</td>
              <td>{initialData?.address || "-"}</td>
            </tr>
            <tr>
              <td>Are you a permanent resident of Hong Kong?</td>
              <td>{initialData?.are_you_pr_of_hk ? "Yes":"No"}</td>
            </tr>
            
            <tr>
              <td>HKID No / Passport No</td>
              <td>{initialData?.hkid_or_passport || "-"}</td>
            </tr>

            <tr>
              <td>Chinese Commercial Code</td>
              <td>{initialData?.chinese_commercial_code || "-"}</td>
            </tr>
            <tr>
              <td>Do you have right of abode in Hong Kong?</td>
              <td>{initialData?.have_right_abode_in_hk ? "Yes":"No"}</td>
            </tr>
            <tr>
              <td>Do you require Hong Kong work visa?</td>
              <td>{initialData?.require_hk_work_visa ? "Yes":"No"}</td>
            </tr>
            <tr>
              <td>Emergency Contact Name</td>
              <td>{initialData?.emergency_contact_name || "-"}</td>
            </tr>
            <tr>
              <td>Emergency Contact Relationship</td>
              <td>{initialData?.emergency_contact_relationship || "-"}</td>
            </tr>
            <tr>
              <td>Emergency Call</td>
              <td>{initialData?.emergency_contact_call_number || "-"}</td>
            </tr>

            <tr>
              <td>Can you work overtime?</td>
              <td>{initialData?.can_work_overtime ? "Yes":"No"}</td>
            </tr>
            <tr>
              <td>Can you work overtime with short notice?</td>
              <td>{initialData?.can_work_overtime_with_short_notice ? "Yes":"No"}</td>
            </tr>
            <tr>
              <td>Can you work rotating shifts?</td>
              <td>{initialData?.can_work_rotating_shift ? "Yes":"No"}</td>
            </tr>
            <tr>
              <td>Can you work night shift?</td>
              <td>{initialData?.can_work_night_shift ? "Yes":"No"}</td>
            </tr>
            <tr>
              <td>Can you work on Sunday or Statutory Holiday?</td>
              <td>{initialData?.can_work_on_sat_sun_holiday ? "Yes":"No"}</td>
            </tr>
            <tr>
              <td>
                Have you ever been convicted or accused of any criminal offence?
              </td>
              <td>{initialData?.convicted_or_accused_of_criminal ? "Yes":"No"}</td>
            </tr>
            <tr>
              <td>
                Have you ever been declared bankrupt or involved in any
                bankruptcy proceedings?
              </td>
              <td>{initialData?.declared_bankrupt_or_invlolved ? "Yes":"No"}</td>
            </tr>
            <tr>
              <td>Previously issued ARA Permit?</td>
              <td>{initialData?.previously_issued_ara_permit ? "Yes":"No"}</td>
            </tr>
            <tr>
              <td>
                Have you ever got rejected for applying Airport Restricted Area
                Permit?
              </td>
              <td>
                {initialData?.have_rejected_for_applying_airpot_restricted_area_permit ? "Yes":"No"}
              </td>
            </tr>

            <tr>
              <td>{`Working Experience (in descending chronological order)`}</td>
              <td>{JSON.stringify(initialData?.working_experiences) || "-"}</td>
            </tr>
            <tr>
              <td>{`Professional Qualification (in descending chronological order)`}</td>
              <td>
                {JSON.stringify(initialData?.professional_qualification) || "-"}
              </td>
            </tr>
            <tr>
              <td>Academic Background</td>
              <td>{initialData?.academic_background || "-"}</td>
            </tr>
            <tr>
              <td>Education</td>
              <td>{JSON.stringify(initialData?.educations) || "-"}</td>
            </tr>
            <tr>
              <td>English</td>
              <td>{JSON.stringify(initialData?.english) || "-"}</td>
            </tr>
            <tr>
              <td>Cantonese</td>
              <td>{JSON.stringify(initialData?.cantonese) || "-"}</td>
            </tr>
            <tr>
              <td>Mandarin</td>
              <td>{JSON.stringify(initialData?.mandarin) || "-"}</td>
            </tr>
            <tr>
              <td>Other Language Name</td>
              <td>{initialData?.other_language_name || "-"}</td>
            </tr>
            <tr>
              <td>Other Languages</td>
              <td>{JSON.stringify(initialData?.other_language) || "-"}</td>
            </tr>
            <tr>
              <td>Other Language Name</td>
              <td>{initialData?.other_language_name || "-"}</td>
            </tr>
            <tr>
              <td>
                Are any of your family members or friend employed by our
                company?
              </td>
              <td>
                {initialData?.are_family_or_friends_employed_by_our_company ? "Yes":"No"}
              </td>
            </tr>
            <tr>
              <td>
                To your best knowledge, any of your family members are related
                to thecustomers, suppliers, contractors, or staffs of SATS HK?
              </td>
              <td>
                {initialData?.are_family_member_related_with_satshk ? "Yes":"No"}
              </td>
            </tr>
            <tr>
              <td>
                I have read the attached Personal InformationCollection
                Statement.
              </td>
              <td>{initialData?.have_read ? "Yes":"No"}</td>
            </tr>
            <tr>
              <td>I authorize SATS HK Limited for Reference Check.</td>
              <td>{initialData?.authorize ? "Yes":"No"}</td>
            </tr>
            <tr>
              <td>
                I declare that the particulars here entered are to the best of
                my knowledge true and accurate.
              </td>
              <td>{initialData?.declare? "Yes":"No"}</td>
            </tr>
            <tr>
              <td>Application's Signature</td>
              <td>
                {initialData?.application_signature ? (
                  <img
                    src={initialData.application_signature}
                    alt="application signature"
                    className="aspect-[100/100] w-full h-full max-w-[100px] object-cover bg-center "
                  />
                ) : (
                  "-"
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="mt-5 last:mb-0 flex items-center">
        <button
          className="flex items-center justify-center bg-offwhite font-primary text-14 font-medium text-black2 rounded-md py-[8.5px] px-3"
          onClick={() => {
            setShowList(true);
          }}
        >
          <CancelIcon className="mr-2" color="#9E3039" />
          Back
        </button>
      </div>
    </div>
  );
};
export default JobApplication;
