import { twJoin } from "tailwind-merge";
import CommonLanguage from "../../components/common/CommonLanguage";
import { useState } from "react";
import ResourceSectionList from "../components/resources/ResourceSectionList";
import Resources, { ResurceType } from "../components/Resources";

const ServicePage = () => {
  const [activeLang, setActiveLang] = useState<string>("en");
  const [servicesData, setServicesData] = useState<ResurceType>({
    id: 0,
    category:'',
    title_en: "",
    title_sc: "",
    title_tc: "",
    text_en: "",
    text_sc: "",
    text_tc: "",
  });
  
  const changeLanguage = (code: string) => {
    setActiveLang(code);
  };
  return (
    <div
      className={twJoin(
        "text-left px-2 sm:px-4 py-4 bg-white sm:mt-[25px] mt-0 font-poppins text-13 font-semibold text-[#858795] tracking-[-0.03rem] w-full h-full rounded-xl pt-3 pb-8 sm:pt-3 sm:pb-9 "
      )}
    >
       <ResourceSectionList/>
    </div>
  );
};
export default ServicePage;
