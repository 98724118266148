import { FC, useEffect, useState } from "react";
import DebounceSearchInput from "../../components/Tables/DebounceSearchInput";
import { SearchIcon } from "../../components/common/Icons";
import Button from "../../components/common/Button";
import { NumberDropdownList } from "../../components/common/DropdownList";
import { ColumnFiltersState, PaginationState } from "@tanstack/react-table";
import HeaderTable from "./HeaderTable";
import { baseObj } from "../../utils/constants";
import { templateList } from "../../active-time-report/views/ActiveTimeReport";
import { useGetMenusQuery } from "../../app/services/header";
interface INews {
  setShowList: React.Dispatch<React.SetStateAction<boolean>>;
  setShowSubmenuList: React.Dispatch<React.SetStateAction<boolean>>;
  showSubmenuList: boolean;
  setDetailData: React.Dispatch<any>;
}
interface IFilterStates {
  tag: string;
  type: string;
}
const dummyHeader = [
  {
    id: 1,
    title_en: "Company Profile",
    title_tc: "公司簡介",
    title_sc: "公司簡介",
    slug: "/company-profile",
    status: 1,
    order_list: 1,
    updated_by: 0,
    created_at: "2024-11-19T10:34:44.000000Z",
    updated_at: "2024-11-19T10:34:44.000000Z",
    deleted_at: null,
    sub_menus: [
      {
        id: 1,
        menu_id: 1,
        title_en: "Our Story",
        title_tc: "我們的故事",
        title_sc: "我們的故事\n",
        slug: "our-story",
        status: 1,
        order_list: 0,
        updated_by: 1,
        created_at: "2024-11-19T10:34:44.000000Z",
        updated_at: "2024-11-19T10:34:44.000000Z",
        deleted_at: null
      },
      {
        id: 2,
        menu_id: 1,
        title_en: "Vision & Mission",
        title_tc: "Vision & Mission",
        title_sc: "Vision & Mission",
        slug: "vision-mission",
        status: 1,
        order_list: 1,
        updated_by: 0,
        created_at: "2024-11-19T10:34:44.000000Z",
        updated_at: "2024-11-19T10:34:44.000000Z",
        deleted_at: null
      },
      {
        id: 3,
        menu_id: 1,
        title_en: "Leader Ship Team",
        title_tc: "Leader Ship Team",
        title_sc: "Leader Ship Team",
        slug: "leader-ship-team",
        status: 1,
        order_list: 2,
        updated_by: 0,
        created_at: "2024-11-19T10:34:44.000000Z",
        updated_at: "2024-11-19T10:34:44.000000Z",
        deleted_at: null
      },
      {
        id: 4,
        menu_id: 1,
        title_en: "Awards Certification",
        title_tc: "Awards Certification",
        title_sc: "Awards Certification",
        slug: "awards-certification",
        status: 1,
        order_list: 3,
        updated_by: 0,
        created_at: "2024-11-19T10:34:44.000000Z",
        updated_at: "2024-11-19T10:34:44.000000Z",
        deleted_at: null
      },
      {
        id: 5,
        menu_id: 1,
        title_en: "Sustainable Development",
        title_tc: "Sustainable Development",
        title_sc: "Sustainable Development",
        slug: "sustainable-development",
        status: 1,
        order_list: 4,
        updated_by: 0,
        created_at: "2024-11-19T10:34:44.000000Z",
        updated_at: "2024-11-19T10:34:44.000000Z",
        deleted_at: null
      },
      {
        id: 6,
        menu_id: 1,
        title_en: "Customer Airlines",
        title_tc: "Customer Airlines",
        title_sc: "Customer Airlines",
        slug: "customer-airlines",
        status: 1,
        order_list: 5,
        updated_by: 0,
        created_at: "2024-11-19T10:34:44.000000Z",
        updated_at: "2024-11-19T10:34:44.000000Z",
        deleted_at: null
      }
    ]
  },
  {
    id: 2,
    title_en: "Services",
    title_tc: "Services",
    title_sc: "Services",
    slug: "/menu",
    status: 1,
    order_list: 2,
    updated_by: 0,
    created_at: "2024-11-19T10:34:44.000000Z",
    updated_at: "2024-11-19T10:34:44.000000Z",
    deleted_at: null,
    sub_menus: []
  },
  {
    id: 3,
    title_en: "Media",
    title_tc: "Media",
    title_sc: "Media",
    slug: "/menu",
    status: 1,
    order_list: 3,
    updated_by: 0,
    created_at: "2024-11-19T10:34:44.000000Z",
    updated_at: "2024-11-19T10:34:44.000000Z",
    deleted_at: null,
    sub_menus: []
  },
  {
    id: 4,
    title_en: "SATS Carers",
    title_tc: "SATS Carers",
    title_sc: "SATS Carers",
    slug: "/menu",
    status: 1,
    order_list: 4,
    updated_by: 0,
    created_at: "2024-11-19T10:34:44.000000Z",
    updated_at: "2024-11-19T10:34:44.000000Z",
    deleted_at: null,
    sub_menus: []
  },
  {
    id: 5,
    title_en: "Join SATS",
    title_tc: "Join SATS",
    title_sc: "Join SATS",
    slug: "/menu",
    status: 1,
    order_list: 5,
    updated_by: 0,
    created_at: "2024-11-19T10:34:44.000000Z",
    updated_at: "2024-11-19T10:34:44.000000Z",
    deleted_at: null,
    sub_menus: []
  },
  {
    id: 6,
    title_en: "Contact Us",
    title_tc: "Contact Us",
    title_sc: "Contact Us",
    slug: "/menu",
    status: 1,
    order_list: 6,
    updated_by: 0,
    created_at: "2024-11-19T10:34:44.000000Z",
    updated_at: "2024-11-19T10:34:44.000000Z",
    deleted_at: null,
    sub_menus: []
  }
];
const HeaderList: FC<INews> = ({ setShowList,showSubmenuList,setShowSubmenuList,setDetailData }) => {
  const [editRowData, setEditRowData] = useState<any>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [viewCols, setCols] = useState<any>([]);
  const [isLayoutControlOpen, setIsLayoutControlOpen] = useState<boolean>(
    false
  );
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  let [localModuleId, setLocalModuleId] = useState<number>(0);
  const [data, setData] = useState<baseObj[]>([]);
  const [itemCount, setItemCount] = useState<number>(0);
  const [tempList, setTempList] = useState<any>(templateList);
  const [hiddenFields, setHiddenFields] = useState<string[]>([]);
  const [globalSearch, setGlobalSearch] = useState<string>("");
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20
  });
  const {data:menuList}=useGetMenusQuery({
    page:currentPage,
    limit:pagination.pageSize,
    search:globalSearch
  })
  
  useEffect(()=>{
    if(menuList){
      setData(menuList?.data);
      setItemCount(menuList?.total);
    }
  },[menuList])
  return (
    <div className="bg-white rounded-xl pt-4 pb-4 sm:pt-4 sm:pb-9 px-4 w-full h-full font-primary">
      <div className="flex flex-wrap justify-between items-center mb-1">
        <div className="flex items-center mr-2 xs:mr-4 sm:w-[260px] w-[225px] my-1">
          <DebounceSearchInput setState={setGlobalSearch} debounce={800}>
            {setState =>
              <div className="flex border-1 border-[#C4C4C4] bg-white hover:bg-[#EFEFEF] rounded-[28px]">
                <input
                  type="text"
                  placeholder="Search"
                  className="focus:outline-none py-1 px-4 text-12 xl:text-14 leading-5 bg-transparent rounded-[28px] w-full text-graydark"
                  onChange={e => setState(e.target.value)}
                />
                <SearchIcon className="mr-2" />
              </div>}
          </DebounceSearchInput>
        </div>

        <div className="items-center xs:mt-1 sm:mt-1 hidden">
          <Button
            label="+ Add New"
            onClick={() => setShowList(false)}
            type="button"
            variant="primary"
            customClass="py-[3px] my-1 px-2 focus:outline-none hover:bg-vorpmyanmar hover:border-vorpmyanmar hover:text-vorpblue 2xl:text-[14px] text-12 leading-5 font-medium text-white"
            size="base"
          />
        </div>
      </div>

      <div className="flex sm:flex-row flex-col justify-end mb-1">
        <div className="flex items-center sm:justify-start justify-end">
          <div className="flex items-center 3xl:ml-3 mr-[10px]">
            <p className="xl:text-sm text-12 mr-2 text-black2 font-primary">
              DISPLAY
            </p>
            <NumberDropdownList
              state={pagination.pageSize}
              setState={value =>
                setPagination(prev => ({
                  ...prev,
                  pageSize: value as number
                }))}
              dropdownItems={new Array(5).fill(1).map((_, index: number) => {
                const count = (index + 1) * 20;
                return {
                  value: count,
                  label: String(count)
                };
              })}
              className="max-w-[55px] 4xs:max-w-[60px] lg:max-w-[72px]"
              textAlign="text-left pl-3"
              paddingClass="py-[2px]"
              placeholder="All"
            />
          </div>
        </div>
      </div>

      <div>
        <div className="text-left">
          <HeaderTable
            // columnsData={columnsData}
            isLayoutControlOpen={isLayoutControlOpen}
            setIsLayoutControlOpen={setIsLayoutControlOpen}
            globalSearch={globalSearch}
            setGlobalSearch={setGlobalSearch}
            columnFilters={columnFilters}
            setColumnFilters={setColumnFilters}
            pagination={pagination}
            data={data}
            setData={setData}
            setPagination={setPagination}
            hiddenFields={hiddenFields ? hiddenFields : []}
            setHiddenFields={setHiddenFields}
            checkManagement={true}
            total={itemCount}
            localModuleId={localModuleId}
            // visArray={visArray}
            templateList={tempList}
            setCurrentView={() => 1}
            setCols={setCols}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            setEditRowData={setEditRowData}
            editRowData={editRowData}
            setShowList={setShowList}
            setShowSubmenuList={setShowSubmenuList}
            setDetailData={setDetailData}
          />
        </div>
      </div>
    </div>
  );
};
//

export default HeaderList;
