
import { ReactElement } from 'react';
import { Outlet } from 'react-router-dom';
import BasicLayout from '../../layouts/BasicLayout';
import CompanyHistoryList from './CompanyHistoryList';
import CompanyVisionMissionList from './CompanyVisionMissionList';

const CompanyVisionMissionRoute = (props:any): ReactElement => {
  const data=props.loginData;

  const permissions = props.permissions;

 return (
    <BasicLayout userData={data} permissions={permissions}>
      <CompanyVisionMissionList/>
      <Outlet />
    </BasicLayout>
  )
};

export default CompanyVisionMissionRoute;
