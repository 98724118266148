import { twJoin } from "tailwind-merge";
import LabelAndEditor from "../../components/SeoData/LabelAndEditor";
import CommonLanguage from "../../components/common/CommonLanguage";
import { FC, useEffect, useState } from "react";
import LabelAndTextbox from "../../components/SeoData/LabelAndTextbox";
import FooterBottomTable from "./FooterBottomTable";
import { ColumnFiltersState, PaginationState } from "@tanstack/react-table";
import { baseObj } from "../../utils/constants";
import { templateList } from "../../active-time-report/views/ActiveTimeReport";
import Button from "../../components/common/Button";
import { IFooterBottomInput } from "./FooterBottomForm";
import { FooterData, IFooterLink, useGetFooterQuery } from "../../app/services/footer";
interface IFooterBottom {
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  setDetailData: React.Dispatch<
    React.SetStateAction<IFooterBottomInput | undefined>
  >;
  footer: IFooterLink[] | undefined;
}
const FooterBottom: FC<IFooterBottom> = ({ setShow, setDetailData,footer }) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [editRowData, setEditRowData] = useState<any>();
  const [viewCols, setCols] = useState<any>([]);

  const [isLayoutControlOpen, setIsLayoutControlOpen] = useState<boolean>(
    false
  );
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  let [localModuleId, setLocalModuleId] = useState<number>(0);
  const [data, setData] = useState<baseObj[]>([]);
  const [itemCount, setItemCount] = useState<number>(0);
  const [tempList, setTempList] = useState<any>(templateList);
  const [hiddenFields, setHiddenFields] = useState<string[]>([]);
  const [globalSearch, setGlobalSearch] = useState<string>("");
  
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20
  });
  useEffect(()=>{
    if(footer){
      setData(footer)
    }
  },[footer])
  return (
    <div>
      <div className="px-2">
        <div>
          <div className="items-center xs:mt-1 sm:mt-1 flex justify-end mb-3">
            <Button
              label="+ Add New"
              onClick={() => setShow(true)}
              type="button"
              variant="primary"
              customClass="py-[3px] my-1 px-2 focus:outline-none hover:bg-vorpmyanmar hover:border-vorpmyanmar hover:text-vorpblue 2xl:text-[14px] text-12 leading-5 font-medium text-white"
              size="base"
            />
          </div>
          <FooterBottomTable
            isLayoutControlOpen={isLayoutControlOpen}
            setIsLayoutControlOpen={setIsLayoutControlOpen}
            globalSearch={globalSearch}
            setGlobalSearch={setGlobalSearch}
            columnFilters={columnFilters}
            setColumnFilters={setColumnFilters}
            pagination={pagination}
            data={data}
            setData={setData}
            setPagination={setPagination}
            hiddenFields={hiddenFields ? hiddenFields : []}
            setHiddenFields={setHiddenFields}
            checkManagement={true}
            total={itemCount}
            localModuleId={localModuleId}
            // visArray={visArray}
            templateList={tempList}
            setCurrentView={() => 1}
            setCols={setCols}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            setEditRowData={setEditRowData}
            editRowData={editRowData}
            setShowList={setShow}
            setDetailData={setDetailData}
          />
        </div>
      </div>
    </div>
  );
};
export default FooterBottom;
