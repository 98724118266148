import { FC, useRef, useState } from "react";
import LabelAndTextbox from "../../components/SeoData/LabelAndTextbox";
import { CancelIcon, SaveIcon } from "../../components/common/Icons";
import { twJoin } from "tailwind-merge";
import classNames from "classnames";
import LabelAndEditor from "../../components/SeoData/LabelAndEditor";
import LabelAndSelect from "../../components/SeoData/LabelAndSelect";
import { options } from "./DownLoadItem";

interface IData {
  activeLang: string;
  initialData: ResurceType;
  setValue: React.Dispatch<any>;
  setShowList: React.Dispatch<React.SetStateAction<boolean>>;
  handle_submit: (data: ResurceType) => Promise<void>;
  isSave:boolean;
}
export interface ResurceType {
  id: number;
  category: string;
  title_en: string;
  title_sc: string;
  title_tc: string;
  text_en: string;
  text_sc: string;
  text_tc: string;
}

const Resources: FC<IData> = ({ activeLang, setShowList, initialData,handle_submit,isSave }) => {
  const [data, setData] = useState<ResurceType>(initialData);
  const description_en_Ref = useRef();
  const description_tc_Ref = useRef();
  const description_sc_Ref = useRef();
  const change_data = (id: number, value: any, key: string) => {
    if (data) {
      setData({ ...data, [key]: value });
    }
  };
  return (
    <div>
      <h2 className="text-lg text-black font-bold text-left my-5">Resource</h2>
      <div className="mb-5">
          <LabelAndSelect
            label="Category"
            value={data?.category}
            options={options}
            setValue={(value) => change_data(data?.id, value, "category")}
          />
        </div>
      <div
        className={twJoin(
          activeLang == "en"
            ? ""
            : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
        )}
      >        
        <LabelAndTextbox
          label="Title (EN)"
          value={initialData?.title_en}
          setValue={(value: any) => change_data(data?.id, value, "title_en")}
        />
        <div className="mt-5">
          <LabelAndEditor
            refId={description_tc_Ref}
            label="Description (EN)"
            value={data?.text_en}
            setValue={(value) => change_data(data?.id, value, "text_en")}
          />
        </div>
      </div>
      <div
        className={twJoin(
          activeLang == "tc"
            ? ""
            : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
        )}
      >
        <LabelAndTextbox
          label="Title (TC)"
          value={initialData?.title_sc}
          setValue={(value: any) => change_data(data?.id, value, "title_tc")}
        />
        <div className="mt-5">
          <LabelAndEditor
            refId={description_tc_Ref}
            label="Description TC)"
            value={data?.text_sc}
            setValue={(value) => change_data(data?.id, value, "text_tc")}
          />
        </div>
      </div>
      <div
        className={twJoin(
          activeLang == "sc"
            ? ""
            : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
        )}
      >
        <LabelAndTextbox
          label="Title (SC)"
          value={initialData?.title_tc}
          setValue={(value: any) => change_data(data?.id, value, "title_sc")}
        />
        <div className="mt-5">
          <LabelAndEditor
            refId={description_tc_Ref}
            label="Description (SC)"
            value={data?.text_sc}
            setValue={(value) => change_data(data?.id, value, "text_sc")}
          />
        </div>
      </div>

      <div className="mt-5 last:mb-0 flex items-center">
        <button
          className={classNames(
            isSave ? "opacity-50 pointer-events-none" : "",
            "flex items-center justify-center bg-vorpblue mr-2.5 text-white font-primary text-14 font-medium rounded-md py-[8.5px] px-3"
          )}
          onClick={() => handle_submit(data)}
        >
          <SaveIcon className="mr-2" /> Save
        </button>
        <button
          className="flex items-center justify-center bg-offwhite font-primary text-14 font-medium text-black2 rounded-md py-[8.5px] px-3"
          onClick={() => {
            setShowList(true);
          }}
        >
          <CancelIcon className="mr-2" color="#9E3039" />
          Cancel
        </button>
      </div>
    </div>
  );
};
export default Resources;
