import { twJoin } from "tailwind-merge";
import CommonLanguage from "../../components/common/CommonLanguage";
import { useState } from "react";
import HeaderList from "./HeaderList";
import HeaderSection from "./HeaderSection";
import HeaderSectionDetail from "./HeaderSectionDetail";

const HeaderComponent = () => {
  const [activeLang, setActiveLang] = useState<string>("en");
  const [servicesData, setServicesData] = useState<any>();
  const [showList, setShowList] = useState<boolean>(true);
  const [showSubmenuList, setShowSubmenuList] = useState<boolean>(true);
  const [detailData,setDetailData]=useState<any>();
  const changeLanguage = (code: string) => {
    setActiveLang(code);
  };
  return (
    <div
      className={twJoin(
        "text-left px-2 sm:px-4 py-4 bg-white sm:mt-[25px] mt-0 font-poppins text-13 font-semibold text-[#858795] tracking-[-0.03rem] w-full h-full rounded-xl pt-3 pb-8 sm:pt-3 sm:pb-9 "
      )}
    >
      {showList == true
        ? <HeaderList setShowList={setShowList} setShowSubmenuList={setShowSubmenuList} showSubmenuList={showSubmenuList} setDetailData={setDetailData}/>
        :(showSubmenuList==true)?<HeaderSectionDetail initialData={detailData} setShow={setShowSubmenuList} setShowList={setShowList}/>: 
        <div>
            <CommonLanguage
              changeLanguage={changeLanguage}
              activeLang={activeLang}
            />
            <HeaderSection
              activeLang={activeLang}
              initialData={detailData}
              setValue={setServicesData}
              setShowList={setShowList}
            />
          </div>}
    </div>
  );
};
export default HeaderComponent;
