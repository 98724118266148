import {
  Cell,
  ColumnDef,
  ColumnFiltersState,
  createColumnHelper,
  PaginationState,
  RowSelectionState,
  SortingState,
} from "@tanstack/react-table";
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import LayoutAdjustableTable from "../Tables/LayoutAdjustableTable";
import {
  baseObj,
  // dummyProjectData
} from "../../utils/constants";
import {
  AddIcon,
  // CalendarIcon,
  CancelIcon,
  // ChevronDown,
  // CloseSmallIcon,
  EditIcon,
  ProfileUploadIcon,
  ReloadIcon,
  // ShareIcon,
  TableSaveIcon,
  TrashIcon,
  // ViewIcon,
} from "../common/Icons";
import TablePaginator from "../Tables/TablePaginator";
import EnableSwitch from "./ProjectCompleteSwitch";
import {
  // convertDateToIsoString,
  // formatDate,
  // formatDateData,
  formatMoney,
} from "../../utils/helpers";
import classNames from "classnames";
// import styles from "../EmployeeTable/EmployeeTable.module.scss";
import styles from "./Projects.module.scss";
import styles1 from "./Projects.module.scss";
import PaymentProgressBar from "./PaymentProgressBar";
import { Modals } from "./Modals";
import EditableCell from "../Tables/EditableCell";
import CustomDropdownWithAddButton from "../EmployeeTable/CustomDropdownWithAddButton";
import { EmployeeData } from "../EmployeeTable/EmployeeTable";
// import CustomDatePicker from "../common/BaseDatePicker";
import {
  useCreateProjectMutation,
  // useGetCountryListQuery,
  useCreateRegionMutation,
  useCreatePJTypeMutation,
  useCreatePJStatusMutation,
  useCreatePaymentStatusMutation,
  useCreateCurrencyMutation,
  useCreateCheckListMutation,
  useCreateQuotationNoMutation,
  useGetKeyDateQuery,
  // KeyDateData,
  useCreateKeyDateMutation,
  useUpdateProjectMutation,
  useDeleteRegionMutation,
  useDeletePJTypeMutation,
  useDeletePJStatusMutation,
  useDeleteCurrencyMutation,
  useDeletePaymentStatusMutation,
  useMultiDeleteProjectMutation,
  QuotationData,
  useDeleteQuotationNoMutation,
  useRemoveKeyDateMutation,
  TEmployeeListForProject,
  TEmployee,
  TRowData,
} from "../../app/services/project";
import DeletePopup from "../DeletePopup/DeletePopup";
import {
  renderDateFormatDMY,
  renderDateFormatYMD,
} from "../DashboardCalendarView/DashboardAddEventPopup";
import DatePopup from "../EmployeeTable/DatePopup";
import {
  TagsData,
  useCreateTagMutation,
  // useGetTagsDataQuery,
} from "../../app/services/annoucement-sheet";
import { makeStyles } from "@mui/styles";
import { Modal, Tooltip } from "@mui/material";
import EmployeeAssignmentPopup from "./EmployeeAssignmentPopup";

// import SuccessPopup from "../SuccessPopup/SuccessPopup";
import ErrorPopup from "../ErrorPopup/ErrorPopup";
// import { useAddTemplateMutation } from "../../app/services/template";
import DOMPurify from "dompurify";
import AssignPopup from "../Passwords/AssignPopup";
import { tagsColor } from "../Announcements/AnouncementsTable";
import { toast, ToastContainer } from "react-toastify";
import ReportToPopup from "../ReportToPopup/ReportToPopup";
import TeamLeadToPopup from "../TeamLeadPopup/TeamLeadToPopup";
import ProjectManagerPopup from "../PorjectManagerPopup/ProjectManagerPopup";
import { assignedColor } from "../Documents/EmployeeDocumentsTable";
import { assignUsers } from "../../app/services/password";
import CommonErrorPopup from "../common/CommonErrorPopup";
import axios from "../../axios";
import { endpointUrl } from "../../app/services/api";
import { getAuthUser } from "../../app/services/dashboard";

interface IProjectsTable {
  setEditData: any;
  isLayoutControlOpen: boolean;
  data: any[];
  // setData: Dispatch<SetStateAction<baseObj[]>>;
  setData: Dispatch<SetStateAction<any[]>>;
  setIsLayoutControlOpen: Dispatch<SetStateAction<boolean>>;
  pagination: PaginationState;
  setPagination: Dispatch<SetStateAction<PaginationState>>;
  globalSearch: string;
  setGlobalSearch: Dispatch<SetStateAction<string>>;
  columnFilters: ColumnFiltersState;
  setColumnFilters: Dispatch<SetStateAction<ColumnFiltersState>>;
  regionList: EmployeeData[];
  setRegionList: Dispatch<SetStateAction<EmployeeData[]>>;
  setRegionFilterItems?: Dispatch<SetStateAction<string[]>>;
  projectTypesList: EmployeeData[];
  setProjectTypesList: Dispatch<SetStateAction<EmployeeData[]>>;
  paymentStatusList: EmployeeData[] | undefined;
  setPaymentStatusList: Dispatch<SetStateAction<EmployeeData[]>>;
  projectStatusList: EmployeeData[];
  setProjectStatusList: Dispatch<SetStateAction<EmployeeData[]>>;
  paymentTermList: EmployeeData[];
  setPaymentTermList: Dispatch<SetStateAction<EmployeeData[]>>;
  deleteProject: any;
  projectTagList: TagsData[];

  setFilterStates: any;
  filterStates: any;
  updateFilterState: any;
  localModuleId: number;

  setHiddenFields: any;
  hiddenFields: string[];
  setCurrentPage: any;
  total: number;
  changePage: any;
  currentPage: number;
  checkManagement: boolean;
  setCols?: any;
  setGetPlaceHolder?: any;
  isEditing?: boolean;
  setIsEdit: Dispatch<SetStateAction<boolean>>;
  setIsTemplate?: Dispatch<SetStateAction<boolean>>;
  // addNew: boolean;
  viewPermis: boolean;
  sorting: SortingState;
  setSorting: React.Dispatch<React.SetStateAction<SortingState>>;
  setMemberData: React.Dispatch<
    React.SetStateAction<TEmployeeListForProject[]>
  >;
  memberData: TEmployeeListForProject[];
  assignUserListData: TEmployee[];
  departmentList: EmployeeData[];
  setAssignDepartmentId: React.Dispatch<React.SetStateAction<string>>;
  assignDepartmentId: string;
  setAssignKeword: React.Dispatch<React.SetStateAction<string>>;
  assignEmployee: any;
  rowList: TRowData[];
  restoreProjectData: any;
  currencyLists: EmployeeData[];
  setCurrencyLists: any;
  keyDateDataList: any;
  setIsOpenPopup: React.Dispatch<React.SetStateAction<boolean>>;

  // setIsUploading: React.Dispatch<React.SetStateAction<boolean>>;
  // isUploading: boolean;
  pjDataClone?: any;
  setPjDataClone?: any;
}

export const MODALS = {
  QUOTATION_NUM: "quotationNumber",
  PROJECT_SCOPE: "projectScope",
  REMARKS: "remarks",
  CHECKLIST: "checklist",
  KEYDATES: "keyDates",
};

const useStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: "transparent",
    margin: 0,
    marginRight: 8,
    padding: 0,
  },
}));
export type TModalState = {
  [key: (typeof MODALS)[keyof typeof MODALS]]: boolean;
};

const initialModalState = {
  [MODALS.QUOTATION_NUM]: false,
  [MODALS.PROJECT_SCOPE]: false,
  [MODALS.REMARKS]: false,
  [MODALS.CHECKLIST]: false,
  [MODALS.KEYDATES]: false,
};

const ProjectsTable: FC<IProjectsTable> = ({
  setEditData,
  assignUserListData,
  departmentList,
  setAssignDepartmentId,
  assignDepartmentId,
  setAssignKeword,
  isLayoutControlOpen,
  setIsLayoutControlOpen,
  pagination,
  setPagination,
  globalSearch,
  setGlobalSearch,
  columnFilters,
  setColumnFilters,
  regionList,
  setRegionList,
  projectTypesList,
  setProjectTypesList,
  paymentStatusList,
  setPaymentStatusList,
  projectStatusList,
  setProjectStatusList,
  paymentTermList,
  setPaymentTermList,
  data,
  setData,
  deleteProject,
  projectTagList,
  setFilterStates,
  filterStates,
  updateFilterState,
  localModuleId,
  setHiddenFields,
  hiddenFields,
  setCurrentPage,
  currentPage,
  total,
  changePage,
  checkManagement,
  setCols,
  setIsTemplate,
  setGetPlaceHolder,
  setIsEdit,
  isEditing,
  // addNew,
  viewPermis,
  sorting,
  setSorting,
  setMemberData,
  memberData,
  assignEmployee,
  restoreProjectData,
  // setIsUploading,
  // isUploading,
  rowList,
  pjDataClone,
  setPjDataClone,
  currencyLists,
  setCurrencyLists,
  keyDateDataList,
  setIsOpenPopup,
}) => {
  const columnHelper = useMemo(() => createColumnHelper<any>(), []); // TODO add type definitions when api binding

  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isPaymentChange, setIsPaymentChange] = useState<boolean>(false);

  const [createProject] = useCreateProjectMutation();
  const [updateProject] = useUpdateProjectMutation();
  const [isShow, setIsShow] = useState<boolean>(false);
  const [createRegion] = useCreateRegionMutation();
  const [createPjType] = useCreatePJTypeMutation();
  const [createPStatus] = useCreatePJStatusMutation();
  const [createPayStatus] = useCreatePaymentStatusMutation();
  const [createCurrency] = useCreateCurrencyMutation();
  const [createCheckList] = useCreateCheckListMutation();
  const [createQuotation] = useCreateQuotationNoMutation();
  // const { data: currencyList } = useGetCurrencyListQuery();
  const [createKeyDate] = useCreateKeyDateMutation();
  // const { data: keyDateDataList } = useGetKeyDateQuery();
  const [deleteRegion] = useDeleteRegionMutation();
  const [deletePJType] = useDeletePJTypeMutation();
  const [deletePJStatus] = useDeletePJStatusMutation();
  const [deleteCurrency] = useDeleteCurrencyMutation();
  const [deletePYStatus] = useDeletePaymentStatusMutation();
  const [multDeletePj] = useMultiDeleteProjectMutation();
  const [deleteQuotation] = useDeleteQuotationNoMutation();
  const [removeKeyDates] = useRemoveKeyDateMutation();
  const [createTag] = useCreateTagMutation();
  const [deleteID, setDeleteID] = useState<number>();
  const [openDeletePopup, setOpenDeleteopup] = useState<boolean>(false);
  const [openRestorePopup, setOpenRestorePopup] = useState<boolean>(false);
  const [restoreId, setRestoreId] = useState<number>(0);
  const [successPopup, setSuccessPopup] = useState<boolean>(false);
  const [errorPopup, setErrorPopup] = useState<boolean>(false);

  const [regionData, setRegionData] = useState("Select");
  const [pjType, setPjType] = useState("Select");
  const [pjStatus, setPjStatus] = useState("Select");
  const [currencyD, setCurrencyD] = useState("All");
  const [paymentStatusD, setPaymentStatusD] = useState("All");
  const [paymentTermD, setPaymentTermD] = useState("All");
  const [itemCount, setItemCount] = useState<number>(total);
  const [commonError, setCommonError] = useState<string>("");
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  // const [itemCount, setItemCount] = useState<number>(dummyProjectData.length);
  const selectRowsCount = Object.keys(rowSelection).length;
  const [modalState, setModalState] = useState<TModalState>(initialModalState);
  const [modalRow, setModalRow] = useState<number>(0);
  const [getPJScope, setGetPJScope] = useState<string>("");
  const [message, setMessage] = useState<any>([]);
  // const [currencyLists, setCurrencyLists] = useState<EmployeeData[]>([]);
  const [checkListData, setCheckListData] = useState<EmployeeData[]>([]);
  const [clientId, setClientId] = useState<number>();
  const [tagData, setTagDate] = useState<any[]>([]);
  const [deleteRowID, setDeleteRowID] = useState<number | 0>();
  const [editedData, setEditedData] = useState<any>();
  // let getFinalPaymentValue = editedData?.paidAmount?.paymentValue.reduce(
  //   function (prev: any, current: any) {
  //     return prev + +current.amount;
  //   },
  //   0
  // );
  // console.log("getFinalPaymentValue", getFinalPaymentValue);
  // console.log("edited", editedData?.paidAmount?.paymentValue);

  const [editProjectAmount, setEditProjectAmountValue] = useState<number>(0);

  const [isProjectAmount, setIsProjectAmount] = useState<boolean>(false);
  const [editedTagData, setEditedTagData] = useState<any>();
  //for region delete
  const [isRegion, setIsRegion] = useState<boolean>(false);
  const [isPJType, setIsPJType] = useState<boolean>(false);
  const [isPJStatus, setIsPJStatus] = useState<boolean>(false);
  const [isCurr, setIsCurrency] = useState<boolean>(false);
  const [isPYStatus, setIsPYStatus] = useState<boolean>(false);
  const [isProj, setIsProj] = useState<boolean>(false);
  const [selectedMember, setSelectedMember] = useState<string>("");
  const [show, setShow] = useState<boolean>(false);
  const [modalType, setModalType] = useState<"assign" | "remove">("assign");
  const [isAssignModalOpen, setIsAssignModalOpen] = useState<boolean>(false);
  const [selectedPwIDSingle, setSelectedSinglePwID] = useState<number>(0);
  const [projectLogo, setProjectLogo] = useState<string>("");
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [selectedUser, setSelectedUser] = useState<number>(0);
  const [existingMember, setExistingMember] = useState<any[]>([]);
  const [selectedPwID, setSelectedPwID] = useState<number[]>([]);
  const [currenProjectID, setCurrentProjectID] = useState<string>("");
  var getUser = getAuthUser();
  //for multi select
  const [chosenTags, setChosenTags] = useState<string[]>([]);
  // const { data: tagsData } = useGetTagsDataQuery();
  const [showTagPopup, setShowTagPopup] = useState<boolean>(false);
  // const [editRowData, setEditRowData] = useState<any>();
  const [cellData, setCellData] = useState<any>();
  // console.log("in table", projectTagList);
  const profileImageRef = useRef<HTMLInputElement>(null);
  const tags = projectTagList
    ?.map((tag, idx) => ({
      label: tag.name,
      value: tag.name,
      name: tag.name,
      id: tag.id,
    }))
    .filter((tag) => tag?.label?.toLowerCase() !== "all");

  // useEffect(() => {
  //   if (currencyList) {
  //     var transformCurrencyData: EmployeeData[] = currencyList.map((x) => {
  //       return {
  //         id: x.id,
  //         name: x.currency_name,
  //         label: x.currency_code,
  //       };
  //     });
  //     setCurrencyLists(transformCurrencyData ? transformCurrencyData : []);
  //   }
  // }, [currencyList]);

  // console.log("editedData", editedData);
  // console.log("dd", data);

  useEffect(() => {
    let getFinalPaymentValue = editedData?.paidAmount?.paymentValue.reduce(
      function (prev: any, current: any) {
        return prev + +current.amount;
      },
      0
    );
    // console.log("getFinalPaymentValue", getFinalPaymentValue);

    if (editedData?.projectAmount > Number(editProjectAmount)) {
      if (getFinalPaymentValue) {
        setIsProjectAmount(true);
      } else {
        setIsProjectAmount(false);
      }
    } else {
      setIsProjectAmount(false);
    }
  }, [editProjectAmount, isPaymentChange]);

  const modalStateHandler = (target: string) => {
    setModalState((prev) => ({ ...prev, [target]: true }));
  };

  const getPJScopeHandler = (name: string) => {
    setGetPJScope(name);
  };

  const deleteProjects = useCallback(
    (id?: number, uniqueID?: number) => {
      setDeleteID(id);
      setDeleteRowID(uniqueID);
      setOpenDeleteopup(true);
    },
    [setData, rowSelection]
  );

  const createTagData = (label: string) => {
    const name = DOMPurify.sanitize(label);
    var data = {
      // name: label,
      name,
      flag: "pj",
    };
    return createTag(data);
    // .unwrap()
    // .then((payload: any) => {
    //   // console.log("re", payload)
    //   if (payload.status) {
    //     setSuccessPopup(true)
    //     setMessage(payload ? [payload?.message] : ["Create Successfully!"]);
    //     setOpenDeleteopup(false);
    //     toast('Successfully Created.', {
    //       position: "top-right",
    //       autoClose: 2000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //       type:"success",
    //       theme: "light",
    //     });
    //   }
    // })
    // .catch((error: any) => {
    //   // console.log("e", error)
    //   setErrorPopup(true)
    //   setMessage(error ? [error?.data?.message] : ["Something went wrong!Trying again."])
    // })

    // return createTag(data);
  };

  const confirmDeleteData = () => {
    // var id = deleteID;
    // var dataID = deleteRowID;
    var id = deleteRowID;
    var dataID = deleteID;
    const selectedRows = Object.keys(rowSelection);
    var selectionIds = data
      .filter((x, index: number) => {
        return selectedRows.find((s) => s == index.toString()) ? x.uniqeID : 0;
      })
      .map((x) => x.uniqeID);
    if (selectionIds.length > 0) {
      deleteProjectMultiple(selectionIds);
    } else {
      deleteItem(id, dataID);
      setDeleteID(-1);
    }
  };

  // const deleteItem = useCallback(
  //   (id?: number) => {
  //     console.log("rree", isRegion);
  //     const selectedRows = Object.keys(rowSelection);
  //     console.log("idssd", id)
  //     setData((prev: baseObj[]) =>
  //       prev.filter((_, index) => {
  //         return id !== undefined
  //           ? id !== index
  //           : !selectedRows.includes(String(index));
  //       })
  //     );
  //     id == undefined && setRowSelection({});
  //     if(isRegion){
  //       console.log("yes")
  //       if(id !== undefined){
  //         console.log("not undefined")
  //         deleteRegion(id).then((res: any) => {
  //           console.log("rettt", res)
  //         })
  //       }
  //     }else{
  //       console.log("pjj")
  //       // deleteProject(id)
  //     }
  //   },
  //   [setData, rowSelection]
  // );

  // console.log("dd", data)

  const deleteItem = useCallback(
    (id?: number, dataId?: number) => {
      // const selectedRows = Object.keys(rowSelection);
      // setData((prev: baseObj[]) =>
      //   prev.filter((_, index) => {
      //     return dataId !== undefined
      //       ? dataId !== index
      //       : !selectedRows.includes(String(index));
      //   })
      // );
      // dataId == undefined && setRowSelection({});

      if (isRegion == true) {
        if (dataId !== undefined) {
          deleteRegion(dataId)
            .unwrap()
            .then((payload: any) => {
              // console.log("pa", payload)
              if (payload.status) {
                setIsRegion(false);
                setIsPJType(false);
                setIsPJStatus(false);
                setIsCurrency(false);
                setIsPYStatus(false);
                setIsProj(false);

                setSuccessPopup(true);
                setMessage(
                  payload ? [payload?.message] : ["Delete Successfullly!"]
                );
                toast("Successfully Deleted.", {
                  position: "top-right",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  type: "success",
                  theme: "light",
                });
                setOpenDeleteopup(false);
              } else {
                setIsRegion(false);
                setIsPJType(false);
                setIsPJStatus(false);
                setIsCurrency(false);
                setIsPYStatus(false);
                setIsProj(false);

                setErrorPopup(true);
                setOpenDeleteopup(false);
                setMessage(payload ? [payload?.message] : ["Cannot Delete!"]);
              }
            })
            .catch((error: any) => {
              // console.log("e", error)
              setIsRegion(false);
              setIsPJType(false);
              setIsPJStatus(false);
              setIsCurrency(false);
              setIsPYStatus(false);
              setIsProj(false);

              setErrorPopup(true);
              setMessage(
                error
                  ? [error?.data?.message]
                  : ["Something went wrong!Trying again."]
              );
              setOpenDeleteopup(false);
            });
        }
      } else if (isPJType == true) {
        if (dataId !== undefined) {
          deletePJType(dataId)
            .unwrap()
            .then((payload: any) => {
              // console.log("p", payload)
              if (payload.status) {
                setIsRegion(false);
                setIsPJType(false);
                setIsPJStatus(false);
                setIsCurrency(false);
                setIsPYStatus(false);
                setIsProj(false);

                setSuccessPopup(true);
                setMessage(
                  payload ? [payload?.message] : ["Delete Successfully!"]
                );
                toast("Successfully Deleted.", {
                  position: "top-right",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  type: "success",
                  theme: "light",
                });
                setOpenDeleteopup(false);
              } else {
                setIsRegion(false);
                setIsPJType(false);
                setIsPJStatus(false);
                setIsCurrency(false);
                setIsPYStatus(false);
                setIsProj(false);

                setErrorPopup(true);
                setMessage(payload ? [payload?.message] : ["Cannot Delete"]);
                setOpenDeleteopup(false);
              }
            })
            .catch((error: any) => {
              // console.log("e", error)

              setIsRegion(false);
              setIsPJType(false);
              setIsPJStatus(false);
              setIsCurrency(false);
              setIsPYStatus(false);
              setIsProj(false);

              setErrorPopup(true);
              setMessage(
                error
                  ? [error?.data?.message]
                  : ["Something went wrong!Trying again."]
              );
              setOpenDeleteopup(false);
            });
        }
      } else if (isPJStatus == true) {
        if (dataId !== undefined) {
          deletePJStatus(dataId)
            .unwrap()
            .then((payload: any) => {
              // console.log("p", payload)
              if (payload.status) {
                setIsRegion(false);
                setIsPJType(false);
                setIsPJStatus(false);
                setIsCurrency(false);
                setIsPYStatus(false);
                setIsProj(false);

                setSuccessPopup(true);
                setMessage(
                  payload ? [payload?.message] : ["Delete Successfully!"]
                );
                toast("Successfully Deleted.", {
                  position: "top-right",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  type: "success",
                  theme: "light",
                });
                setOpenDeleteopup(false);
              } else {
                setIsRegion(false);
                setIsPJType(false);
                setIsPJStatus(false);
                setIsCurrency(false);
                setIsPYStatus(false);
                setIsProj(false);

                setErrorPopup(true);
                setMessage(payload ? [payload?.message] : ["Cannot Delete"]);
                setOpenDeleteopup(false);
              }
            })
            .catch((error: any) => {
              // console.log("e", error)

              setIsRegion(false);
              setIsPJType(false);
              setIsPJStatus(false);
              setIsCurrency(false);
              setIsPYStatus(false);
              setIsProj(false);

              setErrorPopup(true);
              setMessage(
                error
                  ? [error?.data?.message]
                  : ["Something went wrong!Trying again."]
              );
              setOpenDeleteopup(false);
            });
        }
      } else if (isCurr == true) {
        if (dataId !== undefined) {
          deleteCurrency(dataId)
            .unwrap()
            .then((payload: any) => {
              // console.log("c", payload)
              if (payload.status) {
                setIsRegion(false);
                setIsPJType(false);
                setIsPJStatus(false);
                setIsCurrency(false);
                setIsPYStatus(false);
                setIsProj(false);

                setSuccessPopup(true);
                setMessage(
                  payload ? [payload?.message] : ["Successfully Created!."]
                );
                toast("Successfully Created.", {
                  position: "top-right",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  type: "success",
                  theme: "light",
                });
                setOpenDeleteopup(false);
              } else {
                setIsRegion(false);
                setIsPJType(false);
                setIsPJStatus(false);
                setIsCurrency(false);
                setIsPYStatus(false);
                setIsProj(false);

                setErrorPopup(true);
                setOpenDeleteopup(false);
                setMessage(payload ? [payload?.message] : ["Cannot Delete!"]);
              }
            })
            .catch((error: any) => {
              // console.log("error", error)
              setIsRegion(false);
              setIsPJType(false);
              setIsPJStatus(false);
              setIsCurrency(false);
              setIsPYStatus(false);
              setIsProj(false);

              setErrorPopup(true);
              setMessage(
                error
                  ? [error?.data?.message]
                  : ["Something went wrong!Trying again."]
              );
              setOpenDeleteopup(false);
            });
        }
      } else if (isPYStatus == true) {
        if (dataId !== undefined) {
          deletePYStatus(dataId)
            .unwrap()
            .then((payload: any) => {
              // console.log("p", payload)
              if (payload.status) {
                setIsRegion(false);
                setIsPJType(false);
                setIsPJStatus(false);
                setIsCurrency(false);
                setIsPYStatus(false);
                setIsProj(false);

                setSuccessPopup(true);
                setMessage(
                  payload ? [payload?.message] : ["Delete Successfully"]
                );
                toast("Successfully Deleted.", {
                  position: "top-right",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  type: "success",
                  theme: "light",
                });
                setOpenDeleteopup(false);
              } else {
                setIsRegion(false);
                setIsPJType(false);
                setIsPJStatus(false);
                setIsCurrency(false);
                setIsPYStatus(false);
                setIsProj(false);

                setErrorPopup(true);
                setMessage(payload ? [payload?.message] : ["Cannot Delete"]);
                setOpenDeleteopup(false);
              }
            })
            .catch((error: any) => {
              // console.log("e", error)

              setIsRegion(false);
              setIsPJType(false);
              setIsPJStatus(false);
              setIsCurrency(false);
              setIsPYStatus(false);
              setIsProj(false);

              setErrorPopup(true);
              setMessage(
                error
                  ? [error?.data?.message]
                  : ["Something went wrong!Trying again."]
              );
              setOpenDeleteopup(false);
            });
        }
      } else if (isProj == true) {
        if (id !== undefined) {
          deleteProject(id)
            .unwrap()
            .then((payload: any) => {
              if (payload) {
                setIsRegion(false);
                setIsPJType(false);
                setIsPJStatus(false);
                setIsCurrency(false);
                setIsPYStatus(false);
                setIsProj(false);

                setSuccessPopup(true);
                setMessage(
                  payload ? [payload?.message] : ["Successfully Delete!"]
                );
                toast("Successfully Deleted.", {
                  position: "top-right",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  type: "success",
                  theme: "light",
                });
                setOpenDeleteopup(false);
              }
            })
            .catch((error: any) => {
              // console.log("e", error)
              setIsRegion(false);
              setIsPJType(false);
              setIsPJStatus(false);
              setIsCurrency(false);
              setIsPYStatus(false);
              setIsProj(false);

              setErrorPopup(true);
              setMessage(
                error
                  ? [error?.data?.message]
                  : ["Something went wrong!Trying again."]
              );
              setOpenDeleteopup(false);
            });
          setIsProj(false);
        }
      }
    },
    [
      setData,
      rowSelection,
      isRegion,
      isPJType,
      isPJStatus,
      isCurr,
      isPYStatus,
      isProj,
      deleteRegion,
      deletePJType,
      deletePJStatus,
      deleteCurrency,
      deletePYStatus,
      deleteProject,
    ]
  ); //

  const deleteProjectMultiple = (ids: number[]) => {
    multDeletePj(ids)
      .unwrap()
      .then((payload: any) => {
        // console.log("pp", payload)
        setOpenDeleteopup(false);
        setSuccessPopup(true);
        setMessage(payload ? [payload?.message] : ["Delete Successfully!"]);
        toast("Successfully Deleted.", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          type: "success",
          theme: "light",
        });
      })
      .catch((error: any) => {
        // console.log("e", error)
        setErrorPopup(true);
        setMessage(error ? [error?.data?.message] : ["Something went wrong!"]);
        setOpenDeleteopup(false);
      });
    setRowSelection({});
  };

  const editProject = useCallback(
    (id: number) =>
      setData((prev: baseObj[]) => {
        return prev.map((data, index: number) => {
          if (index == id) {
            return { ...data, isEdit: true, isNew: true };
            // return { ...data, isNew: true };
          }
          return data;
        });
      }),
    [setData]
  );

  const addRegionData = (data: string) => {
    const name = DOMPurify.sanitize(data);
    createRegion({ name })
      .unwrap()
      .then((payload: any) => {
        // console.log("re", payload)
        if (payload.status) {
          setSuccessPopup(true);
          setMessage([payload?.message]);
          toast("Successfully Created.", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            type: "success",
            theme: "light",
          });
          setOpenDeleteopup(false);
        }
      })
      .catch((error: any) => {
        // console.log("e", error)
        setErrorPopup(true);
        setMessage(
          error
            ? [error?.data?.message]
            : ["Something went wrong!Trying again."]
        );
      });
  };

  const addPJTypeData = (data: string) => {
    const name = DOMPurify.sanitize(data);
    createPjType({ name })
      .unwrap()
      .then((payload: any) => {
        // console.log("p", payload)
        if (payload.status) {
          setSuccessPopup(true);
          setMessage([payload?.message]);
          setOpenDeleteopup(false);
          toast("Successfully Created.", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            type: "success",
            theme: "light",
          });
        }
      })
      .catch((error: any) => {
        // console.log("ee", error)
        setErrorPopup(true);
        setMessage(
          error
            ? [error?.data?.message]
            : ["Something went wrong!Trying again."]
        );
      });
  };

  const addPJStatusData = (data: string) => {
    const name = DOMPurify.sanitize(data);
    createPStatus({ name })
      .unwrap()
      .then((payload: any) => {
        // console.log("p", payload)
        if (payload.status) {
          setSuccessPopup(true);
          setMessage(payload ? [payload?.message] : ["Create Successfully"]);
          setOpenDeleteopup(false);
          toast("Successfully Created.", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            type: "success",
            theme: "light",
          });
        }
      })
      .catch((error: any) => {
        // console.log("e", error)
        setErrorPopup(true);
        setMessage(
          error
            ? [error?.data?.message]
            : ["Something went wrong!Trying again."]
        );
      });
  };

  const addPayStatusData = (data: string) => {
    const name = DOMPurify.sanitize(data);

    createPayStatus({ name })
      .unwrap()
      .then((payload: any) => {
        // console.log("p", payload)
        if (payload.status) {
          setSuccessPopup(true);
          setMessage(payload ? [payload?.message] : ["Create Successfully"]);
          setOpenDeleteopup(false);
          toast("Successfully Created.", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            type: "success",
            theme: "light",
          });
        }
      })
      .catch((error: any) => {
        // console.log("ee", error)
        setErrorPopup(true);
        setMessage(
          error
            ? [error?.data?.message]
            : ["Something went wrong!Trying again."]
        );
      });
  };

  const addQuotationData = (id: number, name: string) => {
    var exQ = data.find((x) => x.uniqeID == modalRow)?.quotationNumber;

    var updated = data.map((x) => {
      if (x.uniqeID == modalRow) {
        x.quotationNumber = x.quotationNumber
          ? x.quotationNumber + "," + name
          : name;
      }
      return { ...x };
    });
    setData(updated);
    createQuotation({ project_id: id, quotationNo: name }).then((res: any) => {
      var resData = res?.data;
    });
  };

  const removeQuotation = (quoData: QuotationData) => {
    deleteQuotation(quoData);
    var updatedData = data.map((x) => {
      if (x.uniqeID == quoData.project_id) {
        var orgQuo = x.quotationNumber.split(",");
        var filter = orgQuo
          ?.filter((x: any) => x != quoData.quotationNo)
          .join(",");
        x.quotationNumber = filter;
      }
      return { ...x };
    });
    setData(updatedData);
  };

  const addCurreencyData = (data: string, code: string) => {
    const name = DOMPurify.sanitize(data);
    const codes = DOMPurify.sanitize(code);

    createCurrency({ currency_name: name, currency_code: codes })
      .unwrap()
      .then((payload: any) => {
        // console.log("d", payload)
        if (payload.status) {
          setSuccessPopup(true);
          setMessage(payload ? [payload?.message] : ["Successfully Created!."]);
          setIsCurrency(false);
          toast("Successfully Created.", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            type: "success",
            theme: "light",
          });
        }
      })
      .catch((error: any) => {
        setErrorPopup(true);
        setMessage(
          error
            ? [error?.data?.message]
            : ["Something went wrong!Trying again."]
        );
        setIsCurrency(false);
      });
  };
  const classes = useStyles();
  const addCheckListData = (state: any) => {
    // createCheckList({ id: 0, label: state?.label, isChecked: state?.isCheck, project_id: state?.id })
    //   .unwrap()
    //   .then((payload: any) => {
    //     setSuccessPopup(true)
    //     setMessage(payload?.message);
    //     // isNew = false;
    //   })
    //   .catch((error: any) => {
    //     setErrorPopup(true)
    //     setMessage(error ? error?.data?.message : "Something went wrong!")
    //     // isNew = true;
    //   })
    var checklistData = state?.map((s: any) => {
      return {
        label: s.label,
        isChecked: s.isChecked,
        project_id: s.project_id,
      };
    });
    createCheckList(checklistData)
      .unwrap()
      .then((payload: any) => {
        setSuccessPopup(true);
        setMessage([payload?.message]);
        toast("Successfully Created.", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          type: "success",
          theme: "light",
        });
      })
      .catch((error: any) => {
        setErrorPopup(true);
        setMessage(error ? [error?.data?.message] : ["Something went wrong!"]);
      });
  };

  const addKeyDateData = (state: any) => {
    let keyType: any = [];
    //need to call api
    var keyDateData = state.map((s: any) => {
      return {
        id: s.id,
        date: s.date,
        project_id: s.project_id,
        //name:s.label
      };
    });
    // keyDateDataList?.map(function (item: any) {
    //   if (item.label == state?.label) {
    //     keyType.push(item.id);
    //   }
    // });

    createKeyDate(keyDateData)
      .unwrap()
      .then((payload: any) => {
        var resData = payload?.data;
        setData((prev: any) => {
          if (prev.uniqeID == modalRow) {
            prev.checkList.push(resData);
          }
          return prev;
        });
        setSuccessPopup(true);
        setMessage([payload?.message]);
        toast("Successfully Created.", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          type: "success",
          theme: "light",
        });
      })
      .catch((error: any) => {
        setErrorPopup(true);
        setMessage(error ? [error?.data?.message] : ["Something went wrong!"]);
      });

    // .then((res: any) => {
    //   var resData = res?.data;
    //   setData((prev: any) => {
    //     if (prev.uniqeID == modalRow) {
    //       prev.checkList.push(resData)
    //     }
    //     return prev;
    //   })
    //   var updateData = data.find(x => x.uniqeID == modalRow);
    // });
  };

  const [placement, setPlacement] = useState<any>("top-end");
  const [selectedRoleFinal, setSelectedRoleFinal] = useState<any[]>([]);
  const [checkDataLast, setCheckDataLast] = useState<any[]>([]);
  const [selectedRole, setSelectedRole] = useState<any[]>([]);
  const [lastData, setLastData] = useState<any[]>([]);
  const [exitRolesEmp, setExitRolesEmp] = useState<any>();

  useEffect(() => {
    lastData?.map((d: any) => {
      if (d.employee_id === checkDataLast[0]?.employee_id) {
        const idsEmp = selectedRole?.map((data: any) => data?.id);
        const filterData = selectedRoleFinal?.filter(
          (d: any) => d.employee_id !== checkDataLast[0]?.employee_id
        );
        // console.log("filterData vvvv", filterData);
        if (idsEmp?.length) {
          setSelectedRoleFinal([...filterData, { ...d, roles: idsEmp }]);
        } else {
          setSelectedRoleFinal([...filterData]);
        }
      } else {
        setSelectedRoleFinal([...selectedRoleFinal, ...checkDataLast]);
      }
    });
  }, [lastData, checkDataLast]);

  // const [isEditing, setIsEdit] = useState<boolean>(false);

  // console.log("lastData", lastData);
  // console.log("checkDataLast", checkDataLast);

  useEffect(() => {
    // let newExit: any = [];
    if (exitRolesEmp) {
      var rolesID = exitRolesEmp?.project_roles?.map((role: any) => role?.id);
      let uniqueRolesID = rolesID.filter((element: any, index: any) => {
        return rolesID.indexOf(element) === index;
      });
      setSelectedRoleFinal([
        {
          employee_id: exitRolesEmp?.id,
          roles: uniqueRolesID,
        },
      ]);
    }
  }, [exitRolesEmp]);

  // console.log("exitRolesEmp", exitRolesEmp);
  // console.log("selectedRoleFinal", selectedRoleFinal);

  // TODO add type definitions when api binding

  const restoreProject = useCallback(
    (id: number) =>
      setData((prev: baseObj[]) => {
        setRestoreId(id);
        setOpenRestorePopup(true);
        return prev.map((data, index: number) => {
          if (index == id) {
            return { ...data, restoreOption: false };
          }
          return data;
        });
      }),
    [setData]
  );
  const restoreProjectConfirm = () => {
    restoreProjectData(restoreId);
    setOpenRestorePopup(false);
  };
  const changeProfileImage = (id: number) => {
    document.getElementById("logo_image_" + id)?.click();
  };
  const changeProfile = (e: any) => {
    const id = e.target.getAttribute("data-id");
    const code = e.target.getAttribute("data-code");
    var filesize = Math.round(e.target.files[0]?.size / 1000) / 1024;
    if (filesize > 2) {
      setErrorMsg("The uploaded image size is greater than 2mb. ");
      return;
    }
    var url = URL.createObjectURL(e.target.files[0]);
    setProjectLogo(url);
    setData((prev: any) =>
      prev.map((x: any) => {
        if (x.uniqeID == id) {
          return { ...x, logo: url };
        } else {
          return { ...x };
        }
      })
    );
    uploadFileFunc(e.target.files[0], code);
  };
  const uploadFileFunc = async (file: any, code: string) => {
    if (file) {
      let formSaveData = new FormData();
      formSaveData.append("logo", file);
      formSaveData.append("_method", "PUT");

      await axios(endpointUrl + "projects/" + code + "/upload-logo", {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getUser.token}`,
          Accept: "application/json",
        },
        data: formSaveData,
      })
        .then((res: any) => {
          // axios.get(`${endpointUrl}file-manager`, {}).then((result) => {
          // });
        })
        .catch((err) => {
          setErrorMsg(err?.response?.data.message);
        });
    }
  };
  const columns = useMemo(
    () => [
      // columnHelper.display({
      //   header: "",
      //   id: `${checkManagement ? "selection" : "none-selection"}`,
      // }),
      columnHelper.accessor("logo", {
        header: "Logo",
        id: "logo",
        cell: ({ getValue, cell }) => {
          const logo = getValue();
          const isNew: boolean = cell.row.original?.isNew;
          const projectName = cell.row.original.projectName;
          const id = cell.row.original.uniqeID;
          const code = cell.row.original.projectCode;
          return (
            <div className="w-full h-full relative">
              <div
                onClick={() => {
                  setCurrentProjectID(code);
                  changeProfileImage(id);
                }}
                className={classNames(
                  styles.profileImageDiv,
                  logo
                    ? "justify-center rounded-[6px] w-full h-[30px] mx-auto"
                    : "h-full w-full"
                )}
              >
                {logo ? (
                  <img
                    src={logo}
                    alt="profile"
                    className="w-full h-full object-contain max-w-[30px] max-h-[30px] rounded-lg"
                  />
                ) : (
                  <span className="h-full flex items-center w-full mx-auto justify-center">
                    ---
                  </span>
                )}
                <div
                  className={classNames(
                    projectName === ""
                      ? `hidden`
                      : ` ${styles.customUploadBtn}`,
                    "hidden rounded-lg w-full h-[90%] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 cursor-pointer"
                  )}
                >
                  <ProfileUploadIcon
                    color="#fff"
                    width={126}
                    height={126}
                    className="mx-auto"
                  />
                  <input
                    onChange={(e) => {
                      changeProfile(e);
                    }}
                    className="hidden"
                    type="file"
                    data-id={id}
                    data-code={code}
                    id={"logo_image_" + id}
                    accept="image/*"
                  />
                </div>
              </div>
            </div>
          );
        },
      }),
      columnHelper.accessor("projectCode", {
        header: "Project Code",
        id: "projectCode",
        cell: ({ getValue, cell }) => {
          const projectCode = getValue();
          const isNew: boolean = cell.row.original?.isNew;
          return (
            <div
              className={classNames(
                isNew
                  ? "bg-white text-black2 px-2"
                  : "" + "bg-white text-black2 px-2"
              )}
            >
              <p>{projectCode}</p>
              {/* <EditableCell
                setData={setData}
                value={projectCode}
                type="text"
                cell={cell}
              /> */}
            </div>
          );
        },
      }),
      columnHelper.accessor("projectName", {
        header: "Project Name",
        id: "projectName",
        cell: ({ getValue, cell }) => {
          const projectName = getValue();
          return (
            <div className="">
              <EditableCell
                setData={setData}
                value={projectName}
                type="text"
                cell={cell}
              />
            </div>
          );
        },
      }),
      columnHelper.accessor("companyName", {
        header: "Company Name",
        id: "companyName",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const companyName = getValue();
          return (
            <div className="">
              <EditableCell
                setData={setData}
                value={companyName}
                type="text"
                cell={cell}
              />
            </div>
          );
        },
      }),
      columnHelper.accessor("email", {
        header: "Email",
        id: "email",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const email = getValue();
          return (
            <div className="min-w-[160px]">
              <EditableCell
                setData={setData}
                value={email}
                type="text"
                cell={cell}
              />
            </div>
          );
        },
      }),
      columnHelper.accessor("contactPerson", {
        header: "Contact Person",
        id: "contactPerson",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const contactPerson = getValue();
          return (
            <div className="">
              <EditableCell
                setData={setData}
                value={contactPerson}
                type="text"
                cell={cell}
              />
            </div>
          );
        },
      }),
      // columnHelper.accessor("financialContactPerson", {
      //   header: "Financial Contact Person",
      //   id: "financialContactPerson",
      //   enableSorting: false,
      //   cell: ({ getValue, cell }) => {
      //     const financialContactPerson = getValue();
      //     return (
      //       <div className="">
      //         <EditableCell
      //           setData={setData}
      //           value={financialContactPerson}
      //           type="text"
      //           cell={cell}
      //         />
      //       </div>
      //     );
      //   },
      // }),

      columnHelper.accessor("teamLeader", {
        header: "Team Lead",
        id: "teamLeader",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const reportTo = getValue();

          return (
            <div
              className={classNames(
                " min-w-[200px] text-left h-full flex",
                styles.customEmployeeDropdown
              )}
            >
              {reportTo ? (
                <EditableCell
                  className="py-0"
                  customDesign="circle"
                  hasPadding={true}
                  setData={setData}
                  value={reportTo?.preferred_name ?? "Select"}
                  type="custom"
                  cell={cell}
                  CustomComponent={
                    <TeamLeadToPopup
                      title="Select Team Lead"
                      label="Team Lead"
                      memberList={memberData}
                      setMemberData={setSelectedMember}
                      show={show}
                      setShow={setShow}
                      data={data}
                      cell={cell}
                      setData={setData}
                      selectedMember={reportTo?.id}
                      setSelectedMember={setSelectedMember}
                    />
                  }
                />
              ) : (
                <span className="px-[10px] h-full flex items-center justify-center">
                  ---
                </span>
              )}
            </div>
          );
        },
      }),
      columnHelper.accessor("projectManager", {
        header: "Project Manager",
        id: "projectManager",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const reportTo = getValue();

          return (
            <div
              className={classNames(
                " min-w-[200px] text-left h-full flex",
                styles.customEmployeeDropdown
              )}
            >
              {reportTo ? (
                <EditableCell
                  className="py-0"
                  customDesign="circle"
                  hasPadding={true}
                  setData={setData}
                  value={reportTo?.preferred_name ?? "Select"}
                  type="custom"
                  cell={cell}
                  CustomComponent={
                    <ProjectManagerPopup
                      title="Select PM"
                      label="Project Manager"
                      memberList={memberData}
                      setMemberData={setSelectedMember}
                      show={show}
                      setShow={setShow}
                      data={data}
                      cell={cell}
                      setData={setData}
                      selectedMember={reportTo?.id}
                      setSelectedMember={setSelectedMember}
                    />
                  }
                />
              ) : (
                <span className="px-[10px] h-full flex items-center justify-center">
                  ---
                </span>
              )}
            </div>
          );
        },
      }),

      columnHelper.accessor("employees", {
        header: "Assign to",
        id: "employees",
        enableSorting: false,
        cell: ({ cell }) => {
          const id = cell.row.original.id;
          const assigned = cell.row.original.employees;
          const isNew = cell.row.original.isNew;

          return (
            <button
              onClick={() => {
                // setRowOriginal()
                // console.log("d", cell.row.original);
                setCellData(cell);
                setModalType("assign");
                if (checkManagement) {
                  setIsAssignModalOpen(!isAssignModalOpen);
                }
                setSelectedSinglePwID(
                  cell.row.original.uniqeID
                    ? parseInt(cell.row.original.uniqeID)
                    : 0
                );
                setSelectedUser(id);
                setExistingMember(assigned);
                // console.log("assigned", assigned);
              }}
              type="button"
              className={classNames(
                checkManagement ? "" : "pointer-events-none",
                Number(cell.row.original.id) === 0 ||
                  isEditing === true ||
                  isNew
                  ? "pointer-events-none"
                  : "",
                "font-normal flex justify-center items-center h-full min-w-[150px] ml-[10px]"
              )}
            >
              {assigned?.length == 0 && (
                <div className="flex justify-center items-center">---</div>
              )}
              {assigned?.map((x: any, index: number) => {
                if (index < 3) {
                  var idx = index;
                  if (idx > 4) {
                    idx = 0;
                  }
                  return x.profileImage ? (
                    <img
                      key={index}
                      src={x.profileImage}
                      alt="profileImage"
                      className="w-[35px] h-[35px] rounded-full object-cover ml-[-10px]"
                    />
                  ) : (
                    <div
                      key={index}
                      className={classNames(
                        "bg-[" + assignedColor[idx] + "]",
                        "ml-[-10px] w-[35px] h-[35px] rounded-full text-14 flex justify-center items-center text-white"
                      )}
                    >
                      {x.name?.substring(0, 1)}
                    </div>
                  );
                }
              })}
              {assigned?.length > 3 ? (
                <div className="ml-[-10px] w-[35px] h-[35px] rounded-full text-10 flex justify-center items-center bg-black2 text-white">
                  {assigned?.length - 3}+
                </div>
              ) : (
                ""
              )}
            </button>
          );
        },
      }),

      columnHelper.accessor("kickOffDate", {
        header: "KickOff Date",
        id: "kickOffDate",
        cell: ({ getValue, cell }) => {
          const date = getValue();
          var joinDate: any = undefined;
          if (date) {
            joinDate = date !== undefined ? new Date(date) : undefined;
          }
          let formatDate =
            joinDate !== undefined ? renderDateFormatDMY(joinDate, "-") : "---";
          const id = Number(cell.row.id);
          return (
            <div className=" px-3 pr-4">
              <EditableCell
                setData={setData}
                value={
                  cell.row.original?.isNew
                    ? formatDate.toString()
                    : formatDate.toString()
                }
                type="custom"
                cell={cell}
                CustomComponent={
                  <DatePopup
                    dobDate={joinDate}
                    setDate={() => 1}
                    cell={cell}
                    data={data}
                    setData={setData}
                    hasMaxDate={false}
                  />
                }
              />
            </div>
          );
        },
      }),
      columnHelper.accessor("targetLaunchDate", {
        header: "Target Launch Date",
        id: "targetLaunchDate",
        cell: ({ getValue, cell }) => {
          const date = getValue();
          var joinDate: any = undefined;
          if (date) {
            joinDate = date !== undefined ? new Date(date) : undefined;
          }
          let formatDate =
            joinDate !== undefined ? renderDateFormatDMY(joinDate, "-") : "---";
          const id = Number(cell.row.id);
          return (
            <div className=" px-3 pr-4">
              <EditableCell
                setData={setData}
                value={
                  cell.row.original?.isNew
                    ? formatDate.toString()
                    : formatDate.toString()
                }
                type="custom"
                cell={cell}
                CustomComponent={
                  <DatePopup
                    dobDate={joinDate}
                    setDate={() => 1}
                    cell={cell}
                    data={data}
                    setData={setData}
                    hasMaxDate={false}
                  />
                }
              />
            </div>
          );
        },
      }),
      // columnHelper.accessor("startDate", {
      //   header: "Start Date",
      //   id: "startDate",
      //   cell: ({ getValue, cell }) => {
      //     const date = getValue();
      //     var joinDate: any = undefined;
      //     if (date) {
      //       joinDate = date !== undefined ? new Date(date) : undefined;
      //     }
      //     let formatDate =
      //       joinDate !== undefined ? renderDateFormatDMY(joinDate, "-") : "---";
      //     const id = Number(cell.row.id);
      //     return (
      //       <div className=" px-3 pr-4">
      //         <EditableCell
      //           setData={setData}
      //           value={
      //             cell.row.original?.isNew
      //               ? formatDate.toString()
      //               : formatDate.toString()
      //           }
      //           type="custom"
      //           cell={cell}
      //           CustomComponent={
      //             <DatePopup
      //               dobDate={joinDate}
      //               setDate={() => 1}
      //               cell={cell}
      //               data={data}
      //               setData={setData}
      //               hasMaxDate={false}
      //             />
      //           }
      //         />
      //       </div>
      //     );
      //   },
      // }),
      columnHelper.accessor("region", {
        header: "Region",
        id: "region",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const data = getValue();
          var getView: any;
          if (data) {
            getView = regionList?.find((x) => x.id == data);
          } else {
            getView = regionList?.find((x) => x.name == data);
          }
          const isEditable = cell?.row?.original?.isNew;
          const isNew: boolean = cell.row.original?.isNew;
          return (
            <>
              {!isEditable ? (
                <div className=" px-3 pr-4 flex justify-between items-center">
                  {/* min-w-[200px] w-full */}
                  <p className={`text-black2`}>
                    {data ? data?.name : "Select"}
                  </p>
                </div>
              ) : (
                <div className="min-w-[200px] flex justify-between items-center">
                  {/* min-w-[200px] */}
                  <EditableCell
                    hasPadding={true}
                    setData={setData}
                    value={getView ? getView.name : "Select"}
                    type="custom"
                    cell={cell}
                    CustomComponent={
                      <CustomDropdownWithAddButton
                        isShowAdd={true}
                        isShowDelete={true}
                        isCode={true}
                        isRegion={true}
                        addNewItemData={addRegionData}
                        setIsRegion={setIsRegion}
                        setDeleteID={setDeleteID}
                        setOpenDeleteopup={setOpenDeleteopup}
                        initialValues={0} // TODO bypassing
                        dataList={regionList ? regionList : []}
                        setDataList={setRegionList}
                        title="Region"
                        cell={cell}
                        data={data}
                        setData={setData}
                        setSelectedData={setRegionData} // TODO bypassing
                        // setSelectedData={() => 1} // TODO bypassing
                        selectedData={
                          getView ? getView.name : data ? data?.name : "Select"
                        }
                        isEditable={isNew}
                      />
                    }
                  />
                </div>
              )}
            </>
          );
        },
      }),
      // columnHelper.accessor("department", {
      //   header: "Department",
      //   id: "department",
      //   enableSorting: false,
      //   cell: ({ getValue, cell }) => {
      //     const data = getValue();
      //     var getView;
      //     if (data) {
      //       getView = projectTypesList?.find((x) => x.id == data);
      //     } else {
      //       getView = projectTypesList?.find((x) => x.name == data);
      //     }
      //     const isEditable = cell?.row?.original?.isNew;

      //     return (
      //       <>
      //         {!isEditable ? (
      //           <div className=" px-3 pr-4 flex justify-between items-center">
      //             {/* min-w-[200px] w-full */}
      //             <p className={`text-black2`}>
      //               {data ? data?.name : "Select"}
      //             </p>
      //           </div>
      //         ) : (
      //           <div className="min-w-[200px] flex justify-between items-center">
      //             {/* min-w-[200px] w-full */}
      //             <EditableCell
      //               hasPadding={true}
      //               setData={setData}
      //               value={getView ? getView.name : "Select"}
      //               type="custom"
      //               cell={cell}
      //               CustomComponent={
      //                 <CustomDropdownWithAddButton
      //                   isShowAdd={true}
      //                   addNewItemData={addPJTypeData}
      //                   isProjectType={true}
      //                   setIsPJType={setIsPJType}
      //                   setDeleteID={setDeleteID}
      //                   setOpenDeleteopup={setOpenDeleteopup}
      //                   initialValues={0} // TODO bypassing
      //                   dataList={projectTypesList ? projectTypesList : []}
      //                   setDataList={setProjectTypesList}
      //                   title="Project Type"
      //                   cell={cell}
      //                   data={data}
      //                   setData={setData}
      //                   setSelectedData={setPjType} // TODO bypassing
      //                   selectedData={
      //                     getView ? getView.name : data ? data?.name : "Select"
      //                   }
      //                   isEditable={isEditable}
      //                 />
      //               }
      //             />
      //           </div>
      //         )}
      //       </>
      //     );
      //   },
      // }),
      columnHelper.accessor("duration", {
        header: "Duration",
        id: "duration",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const duration = getValue();
          return (
            <div className="">
              <EditableCell
                durationType={cell?.row?.original?.duration_type}
                isDuration={true}
                setData={setData}
                value={duration}
                type="number"
                cell={cell}
              />
            </div>
          );
        },
      }),
      // columnHelper.accessor("current_working_status", {
      //   header: "Project Status",
      //   id: "current_working_status",
      //   cell: ({ getValue, cell }) => {
      //     const status = getValue();
      //     return (
      //       <div className={"bg-white text-black2 px-2"}>
      //         <p>{status}</p>
      //       </div>
      //     );
      //   },
      // }),
      // columnHelper.accessor("projectStatus", {
      //   header: "Project Status",
      //   id: "projectStatus",
      //   enableSorting: false,
      //   cell: ({ getValue, cell }) => {
      //     const data = getValue();
      //     var getView;
      //     if (data) {
      //       getView = projectStatusList?.find((x) => x.id == data);
      //     } else {
      //       getView = projectStatusList?.find((x) => x.name == data);
      //     }
      //     const isEditable = cell.row.original?.isNew;
      //     return (
      //       <>
      //         {!isEditable ? (
      //           <div className=" px-3 pr-4 flex justify-between items-center  w-full">
      //             <p className={`text-black2`}>{data ? data?.name : "---"}</p>
      //           </div>
      //         ) : (
      //           <div className=" flex justify-between items-center w-full">
      //             <EditableCell
      //               hasPadding={true}
      //               setData={setData}
      //               value={getView ? getView?.name : "---"}
      //               type="custom"
      //               cell={cell}
      //               CustomComponent={
      //                 <CustomDropdownWithAddButton
      //                   isShowAdd={true}
      //                   addNewItemData={addPJStatusData}
      //                   isProjectStatus={true}
      //                   setIsPJStatus={setIsPJStatus}
      //                   setDeleteID={setDeleteID}
      //                   setOpenDeleteopup={setOpenDeleteopup}
      //                   initialValues={0} // TODO bypassing
      //                   dataList={projectStatusList ? projectStatusList : []}
      //                   setDataList={setProjectStatusList}
      //                   title="Project Status"
      //                   cell={cell}
      //                   data={data}
      //                   setData={setData}
      //                   setSelectedData={setPjStatus} // TODO bypassing
      //                   selectedData={
      //                     getView ? getView.name : data ? data?.name : "Select"
      //                   }
      //                   isEditable={isEditable}
      //                 />
      //               }
      //             />
      //           </div>
      //         )}
      //       </>
      //     );
      //   },
      // }),
      columnHelper.accessor("projectScope", {
        header: "Project Scope",
        id: "projectScope",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const rowId = cell?.row?.original?.uniqeID;
          const isNew = cell.row.original?.isNew;
          var pjScope = getValue();

          return (
            <div className=" flex items-center justify-center ">
              <button
                type="button"
                onClick={() => {
                  getPJScopeHandler(pjScope);
                  setModalRow(rowId);
                  modalStateHandler(MODALS.PROJECT_SCOPE);
                }}
                className={classNames(
                  Number(rowId) === 0
                    ? "opacity-40 pointer-events-none"
                    : "text-vorpblue text-14 leading-5 mt-4"

                  // isNew //|| viewPermis
                  //   ? "opacity-100"
                  //   : "pointer-events-none opacity-50",
                )}
              >
                Click to view
              </button>
            </div>
          );
        },
      }),
      columnHelper.accessor("quotationNumber", {
        header: "Quotation No.",
        id: "quotationNumber",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const quotationNumber = getValue();
          var quot = quotationNumber?.split(",");
          var subquot = quotationNumber?.split(",")?.splice(0, 2);
          const rowId = cell?.row?.original?.uniqeID;
          const isNew = cell.row.original?.isNew;
          return (
            <div className=" flex justify-between items-center ">
              {subquot?.map((x: any, index: any) => {
                return x ? (
                  <p
                    key={`${x}-${Math.random() * 100}-${index}`}
                    className={classNames(
                      "bg-vorpblue text-white px-[10px] py-1 rounded-[28px] "
                    )}
                  >
                    {x}
                  </p>
                ) : (
                  ""
                );
              })}
              <button
                className={classNames(
                  !cell?.row?.original?.isNew
                    ? "pointer-events-none hidden"
                    : ""
                )}
                type="button"
                onClick={() => {
                  setModalRow(rowId);
                  modalStateHandler(MODALS.QUOTATION_NUM);
                }}
              >
                <AddIcon color={"#464E5F"} />
              </button>
              {quot?.length > 2 && (
                <>
                  <Tooltip
                    arrow
                    classes={{ tooltip: classes.tooltip }}
                    enterTouchDelay={0}
                    leaveTouchDelay={5000}
                    placement="left"
                    title={
                      <>
                        <div className="text-white text-[10px] hover:cursor-pointer leading-[15px] z-[2] rounded p-4 w-full bg-tooltipbg">
                          <span className="block">{quot?.join(", ")}</span>
                        </div>
                      </>
                    }
                  >
                    <span className="font-semibold cursor-pointer">...</span>
                  </Tooltip>
                  <div className="relative hover:cursor-pointer pl-1"></div>
                </>
              )}
            </div>
          );
        },
      }),
      columnHelper.accessor("confirmOn", {
        header: "Project Confirm Date",
        id: "confirmOn",
        cell: ({ getValue, cell }) => {
          const date = getValue();
          var joinDate: any = undefined;
          if (date) {
            joinDate = date !== undefined ? new Date(date) : undefined;
          }
          let formatDate =
            joinDate !== undefined ? renderDateFormatDMY(joinDate, "-") : "---";

          return (
            <div className="">
              <EditableCell
                setData={setData}
                value={
                  cell.row.original?.isNew
                    ? formatDate.toString()
                    : formatDate.toString()
                }
                type="custom"
                cell={cell}
                CustomComponent={
                  <DatePopup
                    dobDate={joinDate}
                    setDate={() => 1}
                    cell={cell}
                    data={data}
                    setData={setData}
                    hasMaxDate={true}
                  />
                }
              />
            </div>
          );
        },
      }),
      // columnHelper.accessor((props) => `${props.currency.label}(${props.currency.sing})`,
      columnHelper.accessor("currency", {
        header: "Currency",
        id: "currency",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const currency: any = getValue();
          var currencyView: any = currencyD;
          var currencyD = currencyLists?.find((x) => x.id == currency);

          if (currencyD == undefined) {
            currencyView = currencyLists?.find((x) => x.name == currency);
          } else {
            currencyView = currencyD;
          }
          const isEditable = cell?.row?.original?.isNew;
          return (
            <>
              {!isEditable ? (
                <div className=" px-3 pr-4 flex justify-between items-center">
                  {/* min-w-[200px] w-full */}
                  <p className={`text-black2`}>
                    {currency ? currency?.label : "Select"}
                  </p>
                </div>
              ) : (
                <div className="min-w-[200px] flex justify-between items-center">
                  <EditableCell
                    hasPadding={true}
                    setData={setData}
                    value={currencyView ? currencyView.name : "Select"}
                    type="custom"
                    cell={cell}
                    CustomComponent={
                      <CustomDropdownWithAddButton
                        isShowAdd={true}
                        isCurrency={true}
                        addNewItemData={addCurreencyData}
                        setIsCurrency={setIsCurrency}
                        setDeleteID={setDeleteID}
                        setOpenDeleteopup={setOpenDeleteopup}
                        isCode={true}
                        initialValues={0} // TODO bypassing
                        dataList={currencyLists ? currencyLists : []}
                        setDataList={setCurrencyLists}
                        title="Currency"
                        cell={cell}
                        data={data}
                        setData={setData}
                        setSelectedData={setCurrencyD} // TODO bypassing
                        selectedData={
                          currencyView ? currencyView.name : "Select"
                        }
                        // selectedData={currencyView ? currencyView.name : currency}
                        selectedDatas={currencyView ? currencyView : currency}
                        isEditable={isEditable}
                      />
                    }
                  />
                </div>
              )}
            </>
          );
        },
      }),
      columnHelper.accessor("projectAmount", {
        header: "Project Amount",
        id: "projectAmount",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const value = getValue();
          const currency = cell.row.original?.currency?.sing;
          const isEditable = cell.row.original?.isNew;
          return (
            <div className=" flex items-center ">
              {isEditable ? (
                <EditableCell
                  setData={setData}
                  value={value}
                  type="text"
                  cell={cell}
                  setEditProjectAmountValue={setEditProjectAmountValue}
                />
              ) : (
                // <p className="">{formatMoney(Number(value),` ${currency}`)}</p>
                <p className="">
                  {currency
                    ? formatMoney(Number(value), currency)
                    : Number(value)}
                </p>
              )}
            </div>
          );
        },
      }),
      columnHelper.accessor("paymentTerm", {
        header: "Payment Terms",
        id: "paymentTerm",
        cell: ({ getValue, cell }) => {
          const paymentTerm = getValue();
          const isEditable = cell?.row?.original?.isNew;

          return (
            <>
              {!isEditable ? (
                <div className=" px-3 pr-4 flex justify-between items-center">
                  {/* min-w-[180px] w-full */}
                  <p className="text-black2">
                    {`${paymentTerm} ${paymentTerm > 1 ? "times" : "time"}`}
                  </p>
                </div>
              ) : (
                <div className=" w-full flex justify-between items-center">
                  <EditableCell
                    hasPadding={true}
                    setData={setData}
                    // value={""}
                    value={paymentTerm}
                    type="custom"
                    cell={cell}
                    CustomComponent={
                      <CustomDropdownWithAddButton
                        isPaymentTerm={true}
                        isCode={true}
                        initialValues={0} // TODO bypassing
                        dataList={paymentTermList}
                        setDataList={setPaymentTermList}
                        title="Payment Terms"
                        cell={cell}
                        data={data}
                        setData={setData}
                        setSelectedData={setPaymentTermD} // TODO bypassing
                        selectedData={paymentTerm ? paymentTerm : "Select"}
                        // selectedData={payment}
                        isPayment={true}
                        isEditable={isEditable}
                      />
                    }
                  />
                </div>
              )}
            </>
          );
        },
      }),
      columnHelper.accessor(
        (props) =>
          Number(
            props?.paidAmount?.paymentValues?.filter(
              (val: baseObj) => val?.isDone
            ).length
          ),
        {
          header: "Payment Progress",
          filterFn: "equals",
          id: "paymentStage", // paidAmount
          enableSorting: false,
          cell: ({ cell }) => {
            const rowValues = cell?.row?.original;
            const value = rowValues?.projectAmount;
            const paidAmount = rowValues?.paidAmount;
            const currency = rowValues?.currency?.sing;
            const paymentTerm = rowValues?.paymentTerm;
            const id = rowValues.uniqeID;
            const rowId = cell?.row?.original?.uniqeID;
            const isNew = cell?.row?.original?.isNew;
            const getId = cell?.row?.id;

            return (
              <div className=" px-2 w-[100%] ">
                {/* //min-w-[420px] */}
                <PaymentProgressBar
                  {...{
                    paidAmount,
                    projectAmount: Number(
                      isProjectAmount
                        ? Number(editedData?.projectAmount)
                        : Number(value)
                    ),
                    getId: Number(getId),
                    setErrorPopup: setErrorPopup,
                    setSuccessPopup: setSuccessPopup,
                    setMessage: setMessage,
                    rowId: rowId,
                    cell: cell,
                    currency,
                    setData,
                    id,
                    paymentTerm,
                    isNew,
                    setIsPaymentChange,
                    setEditedData,
                    editedData,
                  }}
                />
              </div>
            );
          },
        }
      ),
      // columnHelper.accessor("paymentStatus", {
      //   header: "Payment Status",
      //   id: "paymentStatus",
      //   enableSorting: false,
      //   cell: ({ getValue, cell }) => {
      //     const paymentStatus = getValue();
      //     var paStatusView = paymentD;
      //     var paymentD = paymentStatusList?.find((x) => x.id == paymentStatus);
      //     if (paymentD == undefined) {
      //       paStatusView = paymentStatusList?.find(
      //         (x) => x.name == paymentStatus
      //       );
      //     } else {
      //       paStatusView = paymentD;
      //     }
      //     const isEditable = cell?.row?.original?.isNew;
      //     return (
      //       <>
      //         {!isEditable ? (
      //           <div className="w-full px-3 pr-4 flex justify-between items-center">
      //             {/* min-w-[200px]  */}
      //             <p
      //               className={`${
      //                 paymentStatus?.name?.toLowerCase() == "payment completed"
      //                   ? "text-vorpgreen"
      //                   : "text-black2"
      //               }`}
      //             >
      //               {paymentStatus?.name}
      //             </p>
      //           </div>
      //         ) : (
      //           <div className="w-full flex justify-between items-center">
      //             <EditableCell
      //               hasPadding={true}
      //               setData={setData}
      //               value={paStatusView ? paStatusView?.name : ""}
      //               type="custom"
      //               cell={cell}
      //               CustomComponent={
      //                 <CustomDropdownWithAddButton
      //                   isShowAdd={true}
      //                   isPaymentStatus={true}
      //                   addNewItemData={addPayStatusData}
      //                   setIsPYStatus={setIsPYStatus}
      //                   setDeleteID={setDeleteID}
      //                   setOpenDeleteopup={setOpenDeleteopup}
      //                   initialValues={0} // TODO bypassing
      //                   dataList={paymentStatusList ? paymentStatusList : []}
      //                   setDataList={setPaymentStatusList}
      //                   title="Payment Status"
      //                   cell={cell}
      //                   data={data}
      //                   setData={setData}
      //                   setSelectedData={setPaymentStatusD} // TODO bypassing
      //                   // setSelectedData={() => 1} // TODO bypassing
      //                   selectedData={
      //                     paStatusView
      //                       ? paStatusView?.name
      //                       : paymentStatus?.name
      //                   }
      //                   isEditable={isEditable}
      //                 />
      //               }
      //             />
      //           </div>
      //         )}
      //       </>
      //     );
      //   },
      // }),
      columnHelper.accessor("maintenanceEndDate", {
        header: "Maintenance End Date",
        id: "maintenanceEndDate",
        cell: ({ getValue, cell }) => {
          // const paymentTerm = getValue();
          // var paymentDate = new Date();
          // if (paymentTerm) {
          //   paymentDate = new Date(paymentTerm);
          // }
          // var formatDate = renderDateFormatDMY(paymentDate, "/");

          const date = getValue();
          var joinDate: any = undefined;
          if (date) {
            joinDate = date !== undefined ? new Date(date) : undefined;
          }
          let formatDate =
            joinDate !== undefined ? renderDateFormatDMY(joinDate, "-") : "---";

          return (
            <div>
              <EditableCell
                setData={setData}
                value={
                  cell.row.original?.isNew
                    ? formatDate.toString()
                    : formatDate.toString()
                }
                type="custom"
                cell={cell}
                CustomComponent={
                  <DatePopup
                    dobDate={joinDate}
                    setDate={() => 1}
                    cell={cell}
                    data={data}
                    setData={setData}
                    hasMaxDate={false}
                    // minDate={new Date()}
                    hasMinDate={true}
                  />
                }
              />
            </div>
          );
        },
      }),
      columnHelper.accessor("launchDate", {
        header: "Launch Date",
        id: "launchDate",
        cell: ({ getValue, cell }) => {
          const date = getValue();
          var launchDate: any = undefined;
          if (date) {
            launchDate = date !== undefined ? new Date(date) : undefined;
          }
          let formatDate =
            launchDate !== undefined
              ? renderDateFormatDMY(launchDate, "-")
              : "---";

          return (
            <div className="flex justify-center">
              <span className="flex items-center">{formatDate}</span>
            </div>
          );
        },
      }),
      // columnHelper.accessor("projectCompleted", {
      //   header: "Project Completed",
      //   id: "projectCompleted",
      //   enableSorting: false,
      //   cell: ({ getValue, cell }) => {
      //     const projectCompleted = getValue();
      //     var isHaveNew = data?.find((x) => x.isNew == true);
      //     var disabledClass = isHaveNew ? "opacity-50 pointer-events-none" : "";
      //     const isNew: boolean = cell.row.original?.isNew;

      //     return (
      //       <div
      //         className={classNames(
      //           isNew ? "opacity-100" : "pointer-events-none opacity-40",
      //           " px-2"
      //         )}
      //       >
      //         <EnableSwitch
      //           initialState={projectCompleted}
      //           setState={setData}
      //           id={cell?.row?.original?.uniqeID}
      //         />
      //       </div>
      //     );
      //   },
      // }),
      columnHelper.accessor("remarks", {
        header: "Remarks",
        id: "remarks",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          var remarks = getValue();
          const rowId = cell.row.original.uniqeID;
          const isNew = cell.row.original?.isNew;
          return (
            <div className="">
              <button
                type="button"
                onClick={() => {
                  getPJScopeHandler(remarks);
                  setModalRow(rowId);
                  modalStateHandler(MODALS.REMARKS);
                }}
                className={classNames(
                  rowId == 0
                    ? "opacity-100"
                    : "text-vorpblue text-14 leading-5 mt-4"
                  // isNew //|| viewPermis
                  // ? "opacity-100"
                  // : "pointer-events-none opacity-50",
                )}
              >
                Click to view
              </button>
            </div>
          );
        },
      }),
      columnHelper.accessor("checkList", {
        header: "Reminder Checklist",
        id: "checklist",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          var checkList = getValue();
          const rowId = cell?.row?.original?.uniqeID;
          const isNew = cell.row.original?.isNew;

          return (
            <div className="">
              <button
                type="button"
                onClick={() => {
                  setCheckListData(checkList);
                  setModalRow(rowId);
                  modalStateHandler(MODALS.CHECKLIST);
                }}
                className={classNames(
                  rowId == 0
                    ? "opacity-100 pointer-events-none"
                    : "text-vorpblue text-14 leading-5 mt-4"

                  // isNew //| viewPermis
                  // ? "opacity-100"
                  // : "pointer-events-none opacity-50",
                )}
              >
                Click to view
              </button>
            </div>
          );
        },
      }),
      // columnHelper.display({
      columnHelper.accessor("keyDate", {
        header: "Important Key Dates",
        id: "keyDate",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          var key = getValue();
          const rowId = cell?.row?.original?.uniqeID;
          const isNew = cell.row.original?.isNew;

          return (
            <div className="">
              <button
                type="button"
                onClick={() => {
                  setModalRow(rowId);
                  modalStateHandler(MODALS.KEYDATES);
                }}
                className={classNames(
                  rowId == 0
                    ? "opacity-100 pointer-events-none"
                    : "text-vorpblue text-14 leading-5 mt-4"

                  // isNew //|| viewPermis
                  // ? "opacity-100"
                  // : "pointer-events-none opacity-50",
                )}
              >
                Click to view
              </button>
            </div>
          );
        },
      }),
      columnHelper.accessor("projectTypes", {
        header: "Tech Types",
        id: "projectTypes",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const projectT = getValue();

          let finalTags: any = [];
          projectTagList?.map(function (item: any) {
            projectT?.map((vtag: any, id: any) => {
              if (item.name == vtag.name) {
                // finalTags.push(item.id);
                finalTags.push(item.name);
              }
            });
          });

          const isEditable = cell.row.original?.isNew;
          return (
            <>
              {!isEditable ? (
                // <Tooltip
                //   arrow
                //   classes={{ tooltip: classes.tooltip }}
                //   enterTouchDelay={0}
                //   leaveTouchDelay={5000}
                //   placement="left"
                //   title={
                //     <>
                //       <div className="text-white text-[10px] hover:cursor-pointer leading-[15px] z-[2] rounded p-4 w-full bg-tooltipbg">
                //         <span className="text-[#fff] block">
                //           {projectT?.map((x: any) => x.name).join(", ")}
                //         </span>
                //       </div>
                //     </>
                //   }
                // >
                //   <div className="overflow-hidden px-2 min-w-[300px] ">
                //     <div className="overflow-hidden flex items-center">
                //       {projectT?.map((x: any, index: number) => {
                //         var idx = index;
                //         if (index >= 3) {
                //           idx = index % 3;
                //         }
                //         return (
                //           <div
                //             key={index}
                //             className="px-[5px] mr-[3px] rounded-[5px]"
                //             style={{
                //               backgroundColor: tagsColor[idx],
                //               height: "auto",
                //             }}
                //           >
                //             {x.name}
                //           </div>
                //         );
                //       })}
                //     </div>
                //   </div>
                // </Tooltip>
                <div className="overflow-hidden px-2 w-[97%]">
                  <div className="overflow-hidden flex items-center">
                    {projectT?.map((x: any, index: number) => {
                      var idx = index;
                      if (index >= 3) {
                        idx = index % 3;
                      }
                      return (
                        <div
                          key={index}
                          className="px-[5px] mr-[3px] rounded-[5px]"
                          style={{
                            backgroundColor: tagsColor[idx],
                            height: "auto",
                          }}
                        >
                          {x.name}
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : (
                <EditableCell
                  customDesign="tooltip"
                  hasPadding={true}
                  setData={setData}
                  value={projectT?.map((x: any) => x.name).join(",")}
                  type="custom"
                  cell={cell}
                  CustomComponent={
                    <Tooltip
                      arrow
                      classes={{ tooltip: classes.tooltip }}
                      enterTouchDelay={0}
                      leaveTouchDelay={5000}
                      placement="left"
                      title={
                        <>
                          <div className="text-white text-[10px] hover:cursor-pointer leading-[15px] z-[2] rounded p-4 w-full bg-tooltipbg">
                            <span className="text-[#fff] block">
                              {projectT?.map((x: any) => x.name)?.join(", ")}
                            </span>
                          </div>
                        </>
                      }
                    >
                      <button
                        onClick={() => {
                          setIsShow(true);
                          setCellData(cell);
                          setEditedTagData(projectT);
                        }}
                        className=" h-full font-poppins w-[90%] text-12 lg:text-14 overflow-hidden whitespace-nowrap text-ellipsis text-black2"
                      >
                        <div className=" overflow-hidden flex items-center w-[97%]">
                          {/* w-[97%] */}
                          {projectT?.map((x: any, index: number) => {
                            var idx = index;
                            if (index >= 3) {
                              idx = index % 3;
                            }
                            return (
                              <div
                                key={index}
                                className="px-[5px] mr-[3px] rounded-[5px]"
                                style={{
                                  backgroundColor: tagsColor[idx],
                                  height: "auto",
                                }}
                              >
                                {x.name}
                              </div>
                            );
                          })}
                        </div>
                      </button>
                    </Tooltip>
                  }
                />
              )}
            </>
          );
        },
      }),
      // columnHelper.display({
      //   header: "Project Review",
      //   id: "review",
      //   cell: ({ row }) => {
      //     const isProjectCompleted = row.getValue("projectCompleted");
      //     return (
      //       <div className="flex justify-center items-center max-w-[165px] min-w-[145px]">
      //         <button
      //           type="button"
      //           onClick={() => 1}
      //           className={`px-3 py-1  rounded-md text-white text-12 leading-[18px] font-semibold ${isProjectCompleted
      //             ? "hover:cursor-pointer bg-vorpblue"
      //             : "bg-vorpgraylight pointer-events-none"
      //             }`}
      //         >
      //           Review
      //         </button>
      //       </div>
      //     );
      //   },
      // }),
      columnHelper.accessor("developmentUrl", {
        header: "Development Url",
        id: "developmentUrl",
        cell: ({ getValue, cell }) => {
          const development_url = getValue();
          return (
            <div className="flex justify-center">
              {development_url ? (
                <a
                  href={development_url}
                  className="flex items-center underline text-vorpblue"
                >
                  {development_url ? development_url : "---"}
                </a>
              ) : (
                <span className="flex items-center">---</span>
              )}
            </div>
          );
        },
      }),
      // columnHelper.accessor("uat_url", {
      //   header: "UAT Url",
      //   id: "uat_url",
      //   cell: ({ getValue, cell }) => {
      //     const uat_url = getValue();
      //     return (
      //       <div className="flex justify-center">
      //         {uat_url?<a href={uat_url} className="flex items-center underline text-vorpblue">{uat_url ? uat_url : '---'}</a>:<span className="flex items-center">---</span>}
      //         </div>
      //     );
      //   },
      // }),
      columnHelper.accessor("liveUrl", {
        header: "Live Url",
        id: "liveUrl",
        cell: ({ getValue, cell }) => {
          const live_url = getValue();
          return (
            <div className="flex justify-center">
              {live_url ? (
                <a
                  href={live_url}
                  className="flex items-center underline text-vorpblue"
                >
                  {live_url ? live_url : "---"}
                </a>
              ) : (
                <span className="flex items-center">---</span>
              )}
            </div>
          );
        },
      }),
      columnHelper.accessor("currentWorkingStatus", {
        header: "Current Working Status",
        id: "currentWorkingStatus",
        cell: ({ getValue, cell }) => {
          const current_working_status = getValue();
          return (
            <div className="flex justify-center">
              <span className="flex items-center">
                {current_working_status ? current_working_status : "---"}
              </span>
            </div>
          );
        },
      }),
      columnHelper.accessor("currentStage", {
        header: "Current Stage",
        id: "currentStage",
        cell: ({ getValue, cell }) => {
          const currentStage = getValue();
          let currentStageName = null;
          if (currentStage) {
            currentStageName = projectStatusList?.find(
              (x) => x.id == currentStage
            );
          }

          return (
            <div className="flex justify-center">
              <span className="flex items-center">
                {currentStageName ? currentStageName.name : "---"}
              </span>
            </div>
          );
        },
      }),
      columnHelper.accessor("projectStageStatuses", {
        header: "Stage",
        id: "projectStageStatuses",
        cell: ({ getValue, cell }) => {
          const stages = getValue();
          return (
            <div className="text-left font-normal flex flex-wrap max-h-[22px] overflow-y-hidden overflow-x-auto items-center px-2">
              <Tooltip
                arrow
                className="customTooltip"
                classes={{ tooltip: classes.tooltip }}
                enterTouchDelay={1000}
                leaveTouchDelay={5000}
                placement="left"
                title={
                  <>
                    <div className="text-white text-[10px] hover:cursor-pointer leading-[15px] z-[2] rounded p-4 w-full bg-tooltipbg">
                      {stages?.map((x: any, index: number) => {
                        return (
                          <span key={index} className="w-full block">
                            {x.name}
                          </span>
                        );
                      })}
                    </div>
                  </>
                }
              >
                <div className="flex flex-wrap">
                  {stages?.length > 0 ? (
                    stages.map((stage: any, index: number) => {
                      return (
                        <span
                          className="bg-vorpgreen text-white px-[5px] rounded-[5px] mx-2 mb-1"
                          key={index}
                        >
                          {stage?.name}
                        </span>
                      );
                    })
                  ) : (
                    <span className="flex w-full justify-center h-full items-center">
                      ---
                    </span>
                  )}
                </div>
              </Tooltip>
            </div>
          );
        },
      }),
      columnHelper.accessor("brandLevel", {
        header: "Client Tier",
        id: "brandLevel",
        cell: ({ getValue, cell }) => {
          const brand_level = getValue();
          return (
            <div className="flex justify-center">
              <span className="flex items-center">
                {brand_level ? brand_level : "---"}
              </span>
            </div>
          );
        },
      }),
      columnHelper.accessor("projectLevel", {
        header: "Project Tier",
        id: "projectLevel",
        cell: ({ getValue, cell }) => {
          const project_level = getValue();
          return (
            <div className="flex justify-center">
              <span className="flex items-center">
                {project_level ? project_level : "---"}
              </span>
            </div>
          );
        },
      }),
      columnHelper.display({
        header: "Action",
        enableResizing: false,
        // id: `${
        //   data?.find((x) => x.isNew == true) || checkManagement
        //     ? //addNew
        //       "actions"
        //     : "none-actions"
        // }`,
        id: `${checkManagement ? "actions" : "none-actions"}`,
        cell: ({ cell }) => {
          var isHaveNew = data?.find((x) => x.isNew == true);
          var disabledClass = isHaveNew ? "opacity-50 pointer-events-none" : "";
          const isNew: boolean = cell.row.original?.isNew;
          const restoreOption: boolean = cell.row.original?.restoreOption;
          const getData = cell.row.original;

          return (
            <div
              className={classNames(
                styles.actionedBtn,
                "flex justify-center items-center "
              )}
            >
              {isNew ? (
                <>
                  <button
                    type="button"
                    onClick={() =>
                      saveProject(Number(cell?.row?.original?.uniqeID))
                    }
                    className={classNames(
                      isUploading ? " pointer-events-none opacity-40" : "",
                      "w-8 h-8 flex justify-center items-center bg-vorpgreen rounded-md"
                    )}
                  >
                    <TableSaveIcon />
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      canCeelRow(Number(cell?.row?.original?.uniqeID));
                    }}
                    className={classNames(
                      styles.customCloseIcon,
                      "w-8 h-8 ml-2 mr-2 flex justify-center items-center group rounded-md  bg-[#71717a] hover:bg-[#71717a] transition-all duration-300"
                    )}
                  >
                    <CancelIcon width={18} height={18} />
                  </button>
                </>
              ) : (
                <>
                  {checkManagement ? (
                    restoreOption == true ? (
                      <button
                        type="button"
                        onClick={() => {
                          setRestoreId(cell?.row?.original?.uniqeID);
                          restoreProject(cell?.row?.original?.uniqeID);
                        }}
                        className={classNames(
                          "w-8 h-8 mr-2 flex justify-center items-center group rounded-md bg-vorpblue hover:bg-annhoverbg transition-all duration-300"
                        )}
                      >
                        <ReloadIcon />
                      </button>
                    ) : (
                      <>
                        <button
                          disabled={isHaveNew ? true : false}
                          type="button"
                          onClick={() => {
                            setEditData(cell.row.original);
                            // editProject(Number(cell.row.id));
                            // setClientId(cell?.row?.original?.client?.client_id);
                            // setTagDate(cell?.row?.original?.projectTag);
                            setIsOpenPopup(true);
                            // setEditedData(getData);
                            // if (setIsEdit) {
                            //   setIsEdit(true);
                            // }
                          }}
                          className={classNames(
                            disabledClass,
                            "w-8 h-8 mr-2 flex justify-center items-center group rounded-md bg-vorpblue hover:bg-annhoverbg transition-all duration-300"
                          )}
                        >
                          <EditIcon />
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            setIsProj(true);
                            deleteProjects(
                              Number(cell.row.id),
                              Number(cell?.row?.original?.uniqeID)
                            );
                          }}
                          className={classNames(
                            disabledClass,
                            "w-8 h-8 mr-2 flex justify-center items-center group rounded-md bg-redPink hover:bg-redPinkLight transition-all duration-300"
                          )}
                        >
                          <TrashIcon />
                        </button>
                      </>
                    )
                  ) : null}
                </>
              )}
            </div>
          );
        },
      }),
    ],
    [
      columnHelper,
      hiddenFields,
      data,
      projectTypesList,
      regionList,
      projectStatusList,
      currencyLists,
      paymentStatusList,
    ]
  );

  const [columnOrder, setColumnOrder] = useState<string[]>(
    columns.map((column: ColumnDef<any, any>) => column.id as string)
  );

  const visibleColumns: { [key: string]: boolean } = {};

  hiddenFields?.map((x) => {
    visibleColumns[x] = true;
  });

  document.querySelectorAll("th,td").forEach((x) => {
    if (x.id.includes("none")) {
      x.classList.add("hidden");
    }
    var div = document.getElementById(x.id);
    if (div) {
      div.style.setProperty("left", "");
    }
    if (x.id.includes("action")) {
      x.classList.add("absolute");
      x.classList.remove("relative");
      x.classList.add("right-0");
      x.classList.remove("left-0");
    }
  });

  if (checkManagement) {
    visibleColumns["actions"] = true;
  } else {
    visibleColumns["actions"] = false;
  }

  if (hiddenFields?.length > 0) {
    columns.map((x: any) => {
      if (x.id == "actions" || x.id == "selection" || x.id == "lasted") {
        visibleColumns[x.id ? x.id : ""] = true;
      } else if (x.id == "uniqeID") {
        visibleColumns[x.id ? x.id : ""] = false;
      } else if (x.id?.includes("none")) {
        visibleColumns[x.id ? x.id : ""] = true;
      } else {
        var isHidden = hiddenFields.find((h) => h == x.id);
        if (isHidden) {
          visibleColumns[x.id ? x.id : ""] = true;
        } else {
          visibleColumns[x.id ? x.id : ""] = false;
        }
      }
    });
  }

  // const editCell = useCallback(
  //   (id: number, cell: Cell<unknown, unknown>, value: unknown) => {
  //     setData((prev: baseObj[]) => {
  //       return prev.map((data: baseObj, index: number) => {
  //         if (index == id) {
  //           return { ...data, [cell.column.id]: value };
  //         }
  //         return data;
  //       });
  //     });
  //   },
  //   [setData]
  // );

  const validateEmail = (email: any) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const checkIsValidate = (editedData: any) => {
    var msg: any = [];

    if (editedData.teamLeader == "") {
      msg.push("The teamLeader field is required!");
    }

    if (editedData.projectManager == "") {
      msg.push("The projectManager field is required!");
    }

    if (editedData.projectName == "") {
      msg.push("The projectName field is required!");
    }
    if (editedData.companyName == "") {
      msg.push("The companyName field is required!");
    }
    if (editedData.email == "" || !validateEmail(editedData.email)) {
      msg.push("The email not validate!");
    }
    if (editedData.contactPerson == "") {
      msg.push("The contactPerson field is required!");
    }
    if (editedData.financialContactPerson == "") {
      msg.push("The financialContactPerson field is required!");
    }
    if (editedData.region == "") {
      msg.push("The region field is required!");
    }
    if (editedData.projectType == "") {
      msg.push("The projectType field is required!");
    }
    // if (editedData.duration == "") {
    //   msg.push("The duration field is required!");
    // }
    // if (editedData.projectStatus == "") {
    //   msg.push("The projectStatus field is required!");
    // }
    // if (editedData.quotationNumber == "") {
    //   msg.push("The quotationNumber field is required!");
    // }
    if (editedData.currency == "") {
      msg.push("The currency field is required!");
    }
    // if (editedData.paymentTerm == "") {
    //   msg.push("The paymentTerm field is required!");
    // }
    // if (editedData.projectAmount == "") {
    //   msg.push("The projectAmount field is required!");
    // }
    // if (editedData.paymentStatus == "") {
    //   msg.push("The paymentStatus field is required!");
    // }

    return msg;
  };

  const saveProject = useCallback(
    (id: number) => {
      setIsUploading(true);
      if (isProjectAmount) {
        setErrorPopup(true);
        setIsUploading(false);
        setMessage(["Your amount less than Payment Amount."]);
      } else {
        var editedData = data.find((x) => x.uniqeID == id);
        if (editedData) {
          if (editedData.uniqeID == id) {
            var message: any = checkIsValidate(editedData);
            if (message.length > 0) {
              setIsUploading(false);
              setErrorPopup(true);
              setMessage(message);
              return;
            } else {
              // var isNew = true;
              let testType: any = [];
              projectTagList?.map(function (item: any) {
                chosenTags?.map((vtag: any, id: any) => {
                  if (item.name == vtag) {
                    testType.push(item.id);
                  }
                });
              });

              //for date
              var date = renderDateFormatYMD(new Date(), "-");
              if (editedData.date) {
                date = renderDateFormatYMD(new Date(editedData.date), "-");
              }

              var saveData = {
                // "projectCode": editedData.projectCode,
                projectCode: 0,
                projectName: DOMPurify.sanitize(editedData.projectName),
                companyName: DOMPurify.sanitize(editedData.companyName),
                email: editedData.email,
                contactPerson: DOMPurify.sanitize(editedData.contactPerson),
                financialContactPerson: DOMPurify.sanitize(
                  editedData.financialContactPerson
                ),
                date: date,
                region: DOMPurify.sanitize(editedData.region),
                projectType: DOMPurify.sanitize(editedData.projectType),
                duration: DOMPurify.sanitize(editedData.duration),
                projectStatus: DOMPurify.sanitize(editedData.projectStatus),
                confirmOn: editedData.confirmOn
                  ? renderDateFormatYMD(new Date(editedData.confirmOn), "-")
                  : renderDateFormatYMD(new Date(), "-"),
                endDate: editedData.endDate
                  ? renderDateFormatYMD(new Date(editedData.endDate), "-")
                  : renderDateFormatYMD(new Date(), "-"),
                remarks: DOMPurify.sanitize(editedData.remarks),
                projectScope: DOMPurify.sanitize(editedData.projectScope),
                paymentTerm: DOMPurify.sanitize(editedData.paymentTerm),
                paymentStatus: DOMPurify.sanitize(editedData.paymentStatus),
                currency: DOMPurify.sanitize(editedData.currency),
                projectAmount: DOMPurify.sanitize(editedData.projectAmount),
                projectCompleted: editedData.projectCompleted,
                projectTags: editedData.projectTag?.map((x: any) =>
                  parseInt(x.id)
                ),
                projectDepartments: [1, 2, 3],
                quotationNumber: DOMPurify.sanitize(editedData.quotationNumber),
                keyDate: DOMPurify.sanitize(editedData.keyDate),
                checkList: DOMPurify.sanitize(editedData.checkList),
                id: editedData.uniqeID,
                team_leader_id: editedData.teamLeader.id,
                project_manager_id: editedData.projectManager.id,
              };
              // console.log("save", saveData);

              if (editedData.uniqeID == 0) {
                // const sanitizedInput = DOMPurify.sanitize(saveData);
                createProject(saveData)
                  .unwrap()
                  .then((payload: any) => {
                    setIsEdit(false);
                    setSuccessPopup(true);
                    setMessage([payload?.message]);
                    setIsUploading(false);
                    toast("Successfully Created.", {
                      position: "top-right",
                      autoClose: 2000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      type: "success",
                      theme: "light",
                    });
                  })
                  .catch((error: any) => {
                    setIsUploading(false);
                    setErrorPopup(true);
                    setMessage(
                      error ? [error?.data?.message] : ["Something went wrong!"]
                    );
                  });
              } else {
                var updatedData = {
                  team_leader_id: editedData.teamLeader.id,
                  project_manager_id: editedData.projectManager.id,
                  projectCode: DOMPurify.sanitize(editedData.projectCode),
                  projectName: DOMPurify.sanitize(editedData.projectName),
                  companyName: DOMPurify.sanitize(editedData.companyName),
                  email: editedData.email,
                  contactPerson: DOMPurify.sanitize(editedData.contactPerson),
                  financialContactPerson: DOMPurify.sanitize(
                    editedData.financialContactPerson
                  ),
                  date: date,
                  region: DOMPurify.sanitize(editedData.region),
                  projectType: DOMPurify.sanitize(editedData.projectType),
                  duration: DOMPurify.sanitize(editedData.duration),
                  projectStatus: DOMPurify.sanitize(editedData.projectStatus),
                  confirmOn: renderDateFormatYMD(
                    new Date(editedData.confirmOn),
                    "-"
                  ),
                  endDate: renderDateFormatYMD(
                    new Date(editedData.endDate),
                    "-"
                  ),
                  remarks: DOMPurify.sanitize(editedData.remarks),
                  projectScope: DOMPurify.sanitize(editedData.projectScope),
                  paymentTerm: DOMPurify.sanitize(editedData.paymentTerm),
                  paymentStatus: DOMPurify.sanitize(editedData.paymentStatus),
                  currency: DOMPurify.sanitize(editedData.currency),
                  projectAmount: DOMPurify.sanitize(editedData.projectAmount),
                  projectCompleted: editedData.projectCompleted,
                  projectTags: editedData.projectTag?.map((x: any) =>
                    parseInt(x.id)
                  ),
                  projectDepartments: [1],
                  id: editedData.uniqeID,
                  client_id: clientId,
                  quotationNumber: DOMPurify.sanitize(
                    editedData.quotationNumber
                  ),
                };
                // console.log("upd", updatedData);
                //not selected for currency
                if (typeof editedData.currency !== "number") {
                  let Currency = currencyLists?.find(
                    (x: any) => x.id == editedData?.currency?.uniqeID
                  );
                  updatedData.currency = DOMPurify.sanitize(
                    Currency?.id?.toString() ?? ""
                  );
                }
                //not selected for region
                if (typeof editedData.region !== "number") {
                  let Region = regionList?.find(
                    (x: any) => x.id == editedData?.region?.id
                  );
                  updatedData.region = DOMPurify.sanitize(
                    Region?.id?.toString() ?? ""
                  );
                }

                //not selected for projectType
                if (typeof editedData.projectType !== "number") {
                  let projectType = projectTypesList?.find(
                    (x: any) => x.id == editedData?.projectType?.id
                  );
                  updatedData.projectType = DOMPurify.sanitize(
                    projectType?.id?.toString() ?? ""
                  );
                }

                //not selected for projectStatus
                if (typeof editedData.projectStatus !== "number") {
                  let projectStatus = projectStatusList?.find(
                    (x: any) => x.id == editedData?.projectStatus?.id
                  );
                  updatedData.projectStatus = DOMPurify.sanitize(
                    projectStatus?.id?.toString() ?? ""
                  );
                }

                //not selected for paymentStatus
                if (typeof editedData.paymentStatus !== "number") {
                  let paymentStatus = paymentStatusList?.find(
                    (x: any) => x.id == editedData?.paymentStatus?.id
                  );
                  updatedData.paymentStatus = DOMPurify.sanitize(
                    paymentStatus?.id?.toString() ?? ""
                  );
                }

                var existTags: any = [];
                const tag = tagData.forEach((t) => {
                  existTags.push(t.id);
                });
                //for project tags
                if (testType.length == 0) {
                  // updatedData.projectTags = existTags
                } else {
                  // updatedData.projectTags = testType
                }

                updateProject(updatedData)
                  .unwrap()
                  .then((payload: any) => {
                    setIsEdit(false);
                    setSuccessPopup(true);
                    setMessage([payload?.message]);
                    setIsUploading(false);
                    toast("Successfully Updated.", {
                      position: "top-right",
                      autoClose: 2000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      type: "success",
                      theme: "light",
                    });
                    setData((prev: baseObj[]) => {
                      return prev.map((data, index: number) => {
                        if (data.uniqeID == updatedData.id) {
                          return {
                            ...data,
                            isNew: false,
                            isEdit: false,
                          };
                        }
                        return data;
                      });
                    });

                    // isNew = false;
                  })
                  .catch((error: any) => {
                    setErrorPopup(true);
                    setIsUploading(false);
                    setMessage(
                      error ? [error?.data?.message] : ["Something went wrong!"]
                    );
                    // isNew = true;
                  });
              }
              saveProjectData(id);
            }
          }
        }
      }
    },
    [setData, data]
  );

  const saveProjectData = useCallback(
    (id: number) => {
      setData((prev: baseObj[]) => {
        return prev.map((data, index: number) => {
          if (data.uniqeID == id) {
            return {
              ...data,
              isNew: true,
            };
          }
          return data;
        });
      });
    },
    [setData]
  );

  const canCeelRow = (id: number) => {
    if (isEditing) {
      setIsEdit(false);

      setData((prev: baseObj[]) => {
        return prev.map((data, index: number) => {
          if (data.uniqeID == id) {
            return { ...data, isEdit: false, isNew: false };
          }
          return data;
        });
      });
    } else {
      const filterData = data?.filter((d: any) => d?.uniqeID !== id);
      setData(filterData);
    }
  };

  const assignedUsers = (selectedMember: number[]) => {
    var uniqueIds = selectedPwID.filter(
      (value, index, array) => array.indexOf(value) == index
    );

    if (uniqueIds.length <= 0) {
      var indexes = Object.keys(rowSelection);
      var ids = data
        .map((x, i: number) => {
          var id = indexes.includes(i.toString()) ? x.id : 0;
          return id;
        })
        .filter((x) => x != 0);
      uniqueIds = ids;
    }

    if (Number(selectedPwIDSingle) !== 0) {
      // var assignedSingleData = {
      //   ids: selectedPwIDSingle,
      //   employees: selectedMember,
      // };
      const selectedRoleData = pjDataClone
        ?.find((x: any) => x.uniqeID == selectedPwIDSingle)
        ?.employees?.map((e: any) => {
          return {
            employee_id: e.id,
            roles: e.project_roles
              ? e.project_roles?.map((x: any) => x.id)
              : [],
          };
        });
      let employees: any = {
        ids: selectedPwIDSingle,
        // employees: selectedRoleData,
        assign_to: selectedRoleData,
      };
      console.log(
        "pjDataClone ",
        pjDataClone?.find((x: any) => x.uniqeID == selectedPwIDSingle)
      );
      // if (selectedRoleData.length <= 0) {
      //   setCommonError("Please assign role");
      //   return;
      // }
      const emptyRole = selectedRoleData?.find(
        (x: any) => x.roles?.length <= 0
      );
      // if (emptyRole) {
      //   setCommonError("Please assign role");
      //   return;
      // }
      assignEmployee(employees)
        .unwrap()
        .then((payload: any) => {
          // console.log("payload", payload);

          if (payload.status) {
            localStorage.removeItem("selectedObj");
            setSelectedRoleFinal([]);

            setSuccessPopup(true);
            setMessage(payload ? [payload?.message] : ["Asign Successfully!"]);
            setOpenDeleteopup(false);
            setIsAssignModalOpen(false);
            setSelectedPwID([]);
            setSelectedSinglePwID(0);
            toast("Asign Successfully!", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              type: "success",
            });
          }
        })
        .catch((error: any) => {
          setErrorPopup(true);
          setMessage(
            error
              ? [error?.data?.message]
              : ["Something went wrong!Trying again."]
          );
        });
    } else {
      var assignedData = {
        ids: uniqueIds,
        employees: selectedMember,
      };

      // assignEmployee(assignedData)
      //   .unwrap()
      //   .then((payload: any) => {
      //     if (payload.status) {
      //       setSuccessPopup(true);
      //       setMessage(payload ? [payload?.message] : ["Asign Successfully!"]);
      //       setOpenDeleteopup(false);
      //       setIsAssignModalOpen(false);
      //       setSelectedPwID([]);
      //       setSelectedSinglePwID(0);
      //       toast("Asign Successfully!", {
      //         position: "top-right",
      //         autoClose: 2000,
      //         hideProgressBar: false,
      //         closeOnClick: true,
      //         pauseOnHover: true,
      //         draggable: true,
      //         progress: undefined,
      //         theme: "light",
      //         type: "success",
      //       });
      //       setRowSelection({});
      //     }
      //   })
      //   .catch((error: any) => {
      //     setErrorPopup(true);
      //     setMessage(
      //       error
      //         ? [error?.data?.message]
      //         : ["Something went wrong!Trying again."]
      //     );
      //   });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setSuccessPopup(false);
    }, 1000);
  }, [successPopup]);

  useEffect(() => {
    if (hiddenFields.length > 0) {
      setColumnOrder(["selection", ...hiddenFields]);
    }
  }, [hiddenFields]);

  return (
    <div
      className={classNames(
        // styles.employeeTable,
        styles.projectTable,
        "relative inline-block max-w-full w-full"
      )}
    >
      <Modal
        open={isAssignModalOpen}
        style={{
          background: "rgb(0 0 0 / 0.3)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            setIsAssignModalOpen(false);
          }
        }}
        aria-labelledby="password-add-modal"
        aria-describedby="password-add-modal"
        disableEnforceFocus={true}
      >
        <>
          <EmployeeAssignmentPopup
            setCheckDataLast={setCheckDataLast}
            existingMember={existingMember}
            employeeList={assignUserListData}
            rowList={rowList}
            assignedUsers={assignedUsers}
            modalType={modalType}
            setIsOpen={setIsAssignModalOpen}
            setSelectedMemberData={setSelectedMember}
            departmentList={departmentList}
            setAssignDepartmentId={setAssignDepartmentId}
            assignDepartmentId={assignDepartmentId}
            setAssignKeword={setAssignKeword}
            setPlacement={setPlacement}
            placement={placement}
            setData={setData}
            cell={cellData}
            setSelectedRole={setSelectedRole}
            isEditing={isEditing}
            initialValues={[]}
            selectedRole={selectedRole}
            setLastData={setLastData}
            lastData={lastData}
            setExitRolesEmp={setExitRolesEmp}
            pjDataClone={pjDataClone}
            setPjDataClone={setPjDataClone}
          />
        </>
      </Modal>
      <CommonErrorPopup
        show={commonError != "" ? true : false}
        setShow={() => setCommonError("")}
        message={commonError}
      />
      <ToastContainer className={"text-12 font-poppins font-normal"} />
      <ErrorPopup
        descText="Error Message!"
        confirmBtnText="Yes, Close."
        show={errorPopup}
        setShow={setErrorPopup}
        message={message}
        setMessage={setMessage}
        setIsProjectAmount={setIsProjectAmount}
      />

      <DeletePopup
        descText="Are you sure to delete?"
        confirmBtnText="Yes, delete."
        show={openDeletePopup}
        setShow={setOpenDeleteopup}
        confirmDeleteData={confirmDeleteData}
        setIsProj={setIsProj}
      />
      <DeletePopup
        descText="Are you sure to restore?"
        confirmBtnText="Yes, restore."
        show={openRestorePopup}
        setShow={setOpenRestorePopup}
        confirmDeleteData={restoreProjectConfirm}
      />
      <Modals
        addKeyDateData={addKeyDateData}
        addCheckListData={addCheckListData}
        addQuotationData={addQuotationData}
        removeQuotation={removeQuotation}
        removeKeyDates={removeKeyDates}
        state={modalState}
        setState={setModalState}
        rowId={modalRow}
        getPJScope={getPJScope}
        checkListData={checkListData}
        data={data}
        setData={setData}
        filterStates={filterStates}
        setFilterStates={setFilterStates}
        updateFilterState={updateFilterState}
        keyDateDataList={keyDateDataList}
        checkManagement={checkManagement}
      />
      <div className={classNames(styles1.projectTable, "relative  max-w-full")}>
        <LayoutAdjustableTable
          columns={columns}
          data={data}
          noPaddingCells={[
            "region",
            "projectType",
            "projectTag",
            "paymentStatus",
            "projectStatus",
            "date",
            "paymentTerm",
            "currency",
          ]}
          sorting={sorting}
          setSortingState={setSorting}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          columnOrder={columnOrder}
          setColumnOrder={setColumnOrder}
          unDraggableFields={["actions", "selection", "id"]}
          theaderColor="text-graydark"
          isLayoutControlOpen={isLayoutControlOpen}
          setIsLayoutControlOpen={setIsLayoutControlOpen}
          label="Project List"
          setItemCount={setItemCount}
          pagination={pagination}
          setPagination={setPagination}
          globalFilter={globalSearch}
          setGlobalFilter={setGlobalSearch}
          columnFilters={columnFilters}
          setColumnFilters={setColumnFilters}
          localModuleId={localModuleId}
          hideColumn={visibleColumns}
          setCols={setCols}
          setIsTemplate={setIsTemplate}
          setSuccessPopup={setSuccessPopup}
          setMessage={setMessage}
          setErrorPopup={setErrorPopup}
          setGetPlaceHolder={setGetPlaceHolder}
        />
      </div>

      {/* {selectRowsCount > 0 && (
        <div className="flex items-center text-14 leading-5 font-semibold text-black2 mt-3 mb-4 lg:mt-5 lg:mb-5 ">
          <p>{selectRowsCount} selected.</p>
          {checkManagement ? (
            <button
              type="button"
              onClick={() => deleteProjects()}
              className=" flex items-center ml-5 text-12 leading-[18px] font-semibold text-redPink"
            >
              <div className="w-6 h-6 bg-redPink flex items-center group justify-center rounded-md mr-[10px] hover:bg-redPinkLight  transition-all duration-300 ">
                <TrashIcon width={10} height={12} />
              </div>
              Delete
            </button>
          ) : null}
        </div>
      )} */}

      <div className="pt-2">
        <TablePaginator
          total={itemCount}
          pagination={pagination}
          setPagination={setPagination}
          itemCount={total}
          changePage={changePage}
          setCurrentPage={setCurrentPage}
          currentPageDefault={currentPage}
        />
      </div>

      <Modal
        open={isShow}
        style={{
          background: "rgb(0 0 0 / 0.5)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            // Set 'open' to false, however you would do that with your particular code.
            setIsShow(false);
          }
        }}
        aria-labelledby="Password Add Modal"
        aria-describedby="Add New Password Form Pop up"
      >
        <div className=" max-w-[558px] w-full m-auto">
          <AssignPopup
            createTagData={createTagData}
            editRowData={editedTagData}
            showTagPopup={showTagPopup}
            setIsOpen={setIsShow}
            modalType="assign"
            options={tags}
            cell={cellData}
            setTags={() => 1}
            data={data}
            setData={setData}
          />
        </div>
      </Modal>
    </div>
  );
};

export default ProjectsTable;
