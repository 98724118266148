import { twJoin } from "tailwind-merge";
import CommonLanguage from "../../components/common/CommonLanguage";
import Services from "../components/Services";
import { useState } from "react";
import ServiceSectionList from "./Services/ServiceSectionList";
import CustomerAirlineList from "./CustomerAirline/CustomerAirlineSectionList";
import AirLine from "../components/AirLine";
export interface IAirLineDataInfo {
  id: number;
  title_en: string;
  title_tc: string;
  title_sc: string;
  link_en: string;
  link_tc: string;
  link_sc: string;
  image: string;
}
const CustomerAirline = () => {
  const [activeLang, setActiveLang] = useState<string>("en");
  const [airlineData, setAirlineData] = useState<IAirLineDataInfo>({
    id:0,
    title_en:'',
    title_tc:'',
    title_sc:'',
    link_en:'',
    link_tc:'',
    link_sc:'',
    image:''
    });
  const [showList, setShowList] = useState<boolean>(true);
  const changeLanguage = (code: string) => {
    setActiveLang(code);
  };
  return (
    <div
      className={twJoin(
        "text-left px-2 sm:px-4 py-4 bg-white sm:mt-[25px] mt-0 font-poppins text-13 font-semibold text-[#858795] tracking-[-0.03rem] w-full h-full rounded-xl pt-3 pb-8 sm:pt-3 sm:pb-9 "
      )}
    >
      {showList == true
        ? <CustomerAirlineList setShowList={setShowList} setAirlineData={setAirlineData} changeLanguage={changeLanguage}/>
        : <div>
            <CommonLanguage
              changeLanguage={changeLanguage}
              activeLang={activeLang}
            />
            <AirLine
              activeLang={activeLang}
              changeLanguage={changeLanguage}
              initialData={airlineData}
              setValue={setAirlineData}
              setShowList={setShowList}
            />
          </div>}
    </div>
  );
};
export default CustomerAirline;
