import { ReactElement } from 'react';
import BasicLayout from '../layouts/BasicLayout';
import { Outlet } from 'react-router-dom';
import CompanyProfilePage from './Components/CompanyProfilePage';
import CompanyVisionMissionPage from './Components/CompanyVisionMissionPage';
import LeadershipPage from './Components/LeadershipPage';
import ExecutiveLeadership from './Components/ExecutiveLeadership';
import BoardOfDirectors from './Components/BoardOfDirectors';
import AwardList from './Components/Awards/AwardList';
import AwardAndCertificateList from './Components/Awards/AwardAndCertificateList';
import AwardsListPage from './Components/Awards/AwardsListPage';
import CertificateList from './Components/Awards/CertificateList';
import SustainableDevelopmentPage from './Components/Substainable/SustainableDevelopmentPage';
import SubstainableListPage from './Components/Substainable/SubstainableListPage';
import CustomerAirlineList from '../homepage/sections/CustomerAirline/CustomerAirlineSectionList';
import CustomerAirlinesPage from './Components/CustomerAirlines/CustomerAirlinesPage';
import CustomerAirline from '../homepage/sections/CustomerAirline';
import CustomerAirlines from './Components/CustomerAirlines/CustomerAirlines';
export const companyProfileList=[
    {
      id:1,
      path:'#',
      component:<CompanyProfilePage/>
    },
    {
      id:2,
      path:'our-story',
      component:<CompanyProfilePage/>
    },
    {
      id:3,
      path:'vision-mission',
      component:<CompanyVisionMissionPage/>
    },
    {
      id:3,
      path:'leadership',
      component:<LeadershipPage/>
    },
    {
      id:4,
      path:'board_of_directors',
      component:<BoardOfDirectors/>
    },
    {
      id:5,
      path:'executive_leadership',
      component:<ExecutiveLeadership/>
    },
    {
      id:6,
      path:'awards',
      component:<AwardList/>
    },
    {
      id:7,
      path:'awards_list',
      component:<AwardsListPage/>
    },
    {
      id:8,
      path:'certificate_list',
      component:<CertificateList/>
    },
    {
      id:9,
      path:'sustainable',
      component:<SustainableDevelopmentPage/>
    },
    {
      id:10,
      path:'sustainable_list',
      component:<SubstainableListPage/>
    },
    {
      id:11,
      path:'airlines-page',
      component:<CustomerAirlines/>
    },
    {
      id:12,
      path:'airlines',
      component:<CustomerAirlinesPage/>
    },
  ]
const CompanyProfilePageRoute = (props:any): ReactElement => {
    const data = props.loginData;
    const permissions = props.permissions;
    return  <BasicLayout>
    <Outlet />
  </BasicLayout>
}
export default CompanyProfilePageRoute;
