// import { Modal } from "@material-ui/core";
import { Modal, Fade } from "@mui/material"

import { Dispatch, FC, SetStateAction } from "react";
import { baseObj } from "../../../utils/constants";
import { EmployeeData } from "../../EmployeeTable/EmployeeTable";
import { TModalState } from "../../Projects/ProjectsTable";
import ShowPassword from "./ShowPassword";
import { MODALS } from "../../../passwords/views/PasswordList";

interface IModal {
  getAllSeePwd?: any;
  state: TModalState;
  setState: Dispatch<SetStateAction<TModalState>>;
  rowId: number;
  data: baseObj[];
  setData: Dispatch<SetStateAction<baseObj[]>>;
  setShowPasswords?: Dispatch<React.SetStateAction<boolean>>;
  isSuccess?: boolean;
  setIsSuccess?: Dispatch<React.SetStateAction<boolean>>;
  isError?: boolean;
  setIsError?: any
  showPasswords?: boolean;
}

export const ModalPwd: FC<IModal> = ({
  getAllSeePwd,
  state,
  setState,
  rowId,
  data,
  setData,
  isSuccess,
  setIsSuccess,
  isError,
  setIsError,
  setShowPasswords,
  showPasswords,
}) => {
  const targetId = Object.entries(state).filter(
    ([key, value]) => value
  )?.[0]?.[0];

  var opens = false;

  const closeModal = () => {
    setState((prev) => ({ ...prev, [targetId]: false }));
    if (setShowPasswords) {
      setShowPasswords(false)
    }
    opens = false;
  };

  if (state.showPassword) {
    opens = true
  } else {
    opens = false
  }

  return (
    <Modal
      open={Boolean(targetId)}
      style={{
        background: "rgb(0 0 0 / 0.5)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          closeModal();
        }
      }
      }
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableEnforceFocus={true}
    >
      <>
        {(() => {
          switch (targetId) {
            case MODALS.SHOW_PWD:
              return <>
                <ShowPassword
                  getAllSeePwd={getAllSeePwd}
                  closeModal={closeModal}
                  setData={setData}
                  rowId={rowId}
                  keyname={targetId}
                  isSuccess={isSuccess}
                  setIsSuccess={setIsSuccess}
                  isError={isError}
                  setIsError={setIsError}
                  opens={opens}
                />
              </>
            default:
              return <></>;
          }
        })()}
      </>
    </Modal>
  );
};

// TODO deleted after code refractoring
export const QuotationNumModal: FC<{
  state: boolean;
  setState: Dispatch<SetStateAction<boolean>>;
}> = ({ state, setState }) => {
  return <></>;
};
