import { FC, useEffect, useMemo, useState, useCallback } from "react";

export interface IOTData {
  id: number;
  employeeID: string;
  employeeName: string;
  department: string;
  date: string;
  fullHalfDay: string;
  timeIn: string;
  timeOut: string;
  otAmt: string;
}

interface IOT {}

export interface IDepartment {
  id: number;
name: string;
  label: string;
}

const defaultFields = [
  "code",
  "country",
  "name",
  "workingLocations",
  "working_days",
  "shift_timing",
  "working_hours_per_week",
  "employees",
  "remark",
  "actions",
];

export type TStatusListData = {
  id: number | string;
  name: string;
  label?: string;
};

const statusListData: TStatusListData[] = [
  {
    id: "All",
    name: "All",
  },
  {
    id: "standard",
    name: "Standard",
  },
  {
    id: "flexible",
    name: "Flexible",
  },
  {
    id: "customise",
    name: "Customise",
  },
];
const limitedData = [
  {
    id: "All",
    name: "All",
  },
  {
    id: 1,
    name: "Limited",
  },
  {
    id: 0,
    name: "Unlimited",
  },
];
const WorkingShift: FC<IOT> = () => {

  return (
    <div>
    </div>
  );
};
export default WorkingShift;
