import { FC } from "react";

interface ILabelAndSelect {
  label: string;
  value: string | undefined;
  options: { label: string; value: string }[];
  setValue: (value: React.SetStateAction<string>) => void;
}

const LabelAndSelect: FC<ILabelAndSelect> = ({ label, value, options, setValue }) => {
  return (
    <div className="flex  flex-col items-start space-y-4 mb-5">
      <label
        htmlFor="category"
        className="text-black2 text-15 font-medium font-primary block"
      >
        {label}
      </label>

      <div className="border w-full border-goalinputborder rounded py-3.5 px-3 h-[48px]">
        <select
          name="category"
          id="category"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          className="focus:outline-none w-full focus-visible:outline-none  text-black2 text-14 font-medium font-primary placeholder:text-graydark "
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default LabelAndSelect;
