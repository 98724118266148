import { twJoin } from "tailwind-merge";
import { useState } from "react";
import MediaReports, { MediaReportType } from "../components/MediaReport";
import MediaReportSectionList from "../components/media-report/MediaReportSectionList";

const MediaReportPage = () => {
  const [servicesData, setServicesData] = useState<MediaReportType>({
    id: 0,
    meta_title_en: "",
    meta_title_tc: "",
    meta_title_sc: "",
    meta_description_en: "",
    meta_description_tc: "",
    meta_description_sc: "",
    meta_image: "",
    category: "1",
    title_en: "",
    title_sc: "",
    title_tc: "",
    description_en: "",
    description_sc: "",
    description_tc: "",
    image: undefined,
    image_url: "",
  });
  const [showList, setShowList] = useState<boolean>(true);

  return (
    <div
      className={twJoin(
        "text-left px-2 sm:px-4 py-4 bg-white sm:mt-[25px] mt-0 font-poppins text-13 font-semibold text-[#858795] tracking-[-0.03rem] w-full h-full rounded-xl pt-3 pb-8 sm:pt-3 sm:pb-9 "
      )}
    >
      {showList ? (
        <MediaReportSectionList setShowList={setShowList} />
      ) : (
        <div>
          <MediaReports
            initialData={servicesData}
            setValue={setServicesData}
            setShowList={setShowList}
          />
        </div>
      )}
    </div>
  );
};
export default MediaReportPage;
