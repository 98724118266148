import { FC, useEffect, useRef, useState } from "react";
import CommonLanguage from "../../components/common/CommonLanguage";
import { twJoin } from "tailwind-merge";
import LabelAndTextbox from "../../components/SeoData/LabelAndTextbox";
import LabelAndImage from "../../components/SeoData/LabelAndImage";
import LabelAndEditor from "../../components/SeoData/LabelAndEditor";
export interface IOverviewData {
  overview: IOverview | undefined;
  activeLang: string;
  setActiveLang: React.Dispatch<React.SetStateAction<string>>;
}
export interface IOverview {
  sub_title_en: string;
  sub_title_tc: string;
  sub_title_sc: string;

  title_en: string;
  title_tc: string;
  title_sc: string;

  description_en: string;
  description_tc: string;
  description_sc: string;

  passenger_service_title_en: string;
  passenger_service_title_tc: string;
  passenger_service_title_sc: string;

  passenger_service_text_en: string;
  passenger_service_text_tc: string;
  passenger_service_text_sc: string;

  ramp_service_title_en: string;
  ramp_service_title_tc: string;
  ramp_service_title_sc: string;

  ramp_service_text_en: string;
  ramp_service_text_tc: string;
  ramp_service_text_sc: string;

  passenger_service_image: string;
  ramp_service_image: string;
}
const OverviewSection: FC<IOverviewData> = ({
  overview,
  activeLang,
  setActiveLang,
}) => {
  const description_en_Ref = useRef();
  const description_tc_Ref = useRef();
  const description_sc_Ref = useRef();
  const [data, setData] = useState<IOverview | undefined>();
  const changeLanguage = (code: string) => {
    setActiveLang(code);
  };
  useEffect(() => {
    if (overview) {
      setData(overview);
    }
  }, [overview]);
  const change_data = (value: any, key: string) => {
    if (data) {
      setData({ ...data, [key]: value });
    }
  };
  return (
    <div>
      <div className="mt-5">
        <div
          className={
            "space-y-5" +
            twJoin(
              activeLang == "en"
                ? ""
                : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
            )
          }
        >
          <LabelAndTextbox
            label="Sub Title (EN)"
            value={data?.sub_title_en}
            setValue={(value) => change_data(value, "sub_title_en")}
          />
          <LabelAndTextbox
            label="Title (EN)"
            value={data?.title_en}
            setValue={(value) => change_data(value, "title_en")}
          />
          <LabelAndEditor
            refId={description_en_Ref}
            label="Description (EN)"
            value={data?.description_en}
            setValue={(value) => change_data(value, "description_en")}
          />

          <LabelAndTextbox
            label="Passenger Service Title (EN)"
            value={data?.passenger_service_title_en}
            setValue={(value) =>
              change_data(value, "passenger_service_title_en")
            }
          />
          <LabelAndTextbox
            label="Passenger Service Text (EN)"
            value={data?.passenger_service_text_en}
            setValue={(value) =>
              change_data(value, "passenger_service_text_en")
            }
          />

          <LabelAndTextbox
            label="Ramp Service Title (EN)"
            value={data?.ramp_service_title_en}
            setValue={(value) => change_data(value, "ramp_service_title_en")}
          />
          <LabelAndTextbox
            label="Ramp Service Text (EN)"
            value={data?.ramp_service_text_en}
            setValue={(value) => change_data(value, "ramp_service_text_en")}
          />
        </div>

        <div
          className={twJoin(
            activeLang == "tc"
              ? ""
              : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
          )}
        >
          <LabelAndTextbox
            label="Title (TC)"
            value={data?.title_en}
            setValue={(value) => change_data(value, "title_tc")}
          />
          <LabelAndEditor
            refId={description_en_Ref}
            label="Description (TC)"
            value={data?.description_en}
            setValue={(value) => change_data(value, "description_tc")}
          />

          <LabelAndTextbox
            label="Passenger Service Title (TC)"
            value={data?.passenger_service_title_tc}
            setValue={(value) =>
              change_data(value, "passenger_service_title_tc")
            }
          />
          <LabelAndTextbox
            label="Passenger Service Text (TC)"
            value={data?.passenger_service_text_tc}
            setValue={(value) =>
              change_data(value, "passenger_service_text_tc")
            }
          />

          <LabelAndTextbox
            label="Ramp Service Title (TC)"
            value={data?.ramp_service_title_en}
            setValue={(value) => change_data(value, "ramp_service_title_tc")}
          />
          <LabelAndTextbox
            label="Ramp Service Text (TC)"
            value={data?.ramp_service_text_tc}
            setValue={(value) => change_data(value, "ramp_service_text_tc")}
          />
        </div>
        <div
          className={twJoin(
            activeLang == "sc"
              ? ""
              : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
          )}
        >
          <LabelAndTextbox
            label="Title (SC)"
            value={data?.title_sc}
            setValue={(value) => change_data(value, "title_sc")}
          />
          <LabelAndEditor
            refId={description_en_Ref}
            label="Description (SC)"
            value={data?.description_sc}
            setValue={(value) => change_data(value, "description_sc")}
          />

          <LabelAndTextbox
            label="Passenger Service Title (EN)"
            value={data?.passenger_service_title_en}
            setValue={(value) =>
              change_data(value, "passenger_service_title_en")
            }
          />
          <LabelAndTextbox
            label="Passenger Service Text (SC)"
            value={data?.passenger_service_text_sc}
            setValue={(value) =>
              change_data(value, "passenger_service_text_sc")
            }
          />

          <LabelAndTextbox
            label="Ramp Service Title (SC)"
            value={data?.ramp_service_title_sc}
            setValue={(value) => change_data(value, "ramp_service_title_sc")}
          />
          <LabelAndTextbox
            label="Ramp Service Text (SC)"
            value={data?.ramp_service_text_sc}
            setValue={(value) => change_data(value, "ramp_service_text_sc")}
          />
        </div>

        <LabelAndImage
          margin="mt-5"
          setFile={(value: any) =>
            change_data(value, "passenger_service_image")
          }
          label="Passenger Service Image"
          value={data?.passenger_service_image}
          setValue={(value: any) =>
            change_data(value, "passenger_service_image")
          }
        />
        <LabelAndImage
          margin="my-5"
          setFile={(value: any) => change_data(value, "ramp_service_image")}
          label="Ramp Service Image"
          value={data?.ramp_service_image}
          setValue={(value: any) => change_data(value, "ramp_service_image")}
        />
      </div>
    </div>
  );
};
export default OverviewSection;
