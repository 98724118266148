import { FC, useRef, useState } from "react";
import LabelAndTextbox from "../../components/SeoData/LabelAndTextbox";
import { CancelIcon, SaveIcon } from "../../components/common/Icons";
import LabelAndImage from "../../components/SeoData/LabelAndImage";
import { twJoin } from "tailwind-merge";
import classNames from "classnames";
import LabelAndEditor from "../../components/SeoData/LabelAndEditor";
import LabelAndSelect from "../../components/SeoData/LabelAndSelect";
import SeoData from "../../components/SeoData/SeoData";
import CommonLanguage from "../../components/common/CommonLanguage";
import { endpointUrl } from "../../app/services/api";
import axios from "../../axios";
import BannerSingle, { IBannerData } from "../../components/SeoData/BannerSingle";
import { toast } from "react-toastify";

interface IData {
  initialData: MediaReportType;
  setValue: React.Dispatch<any>;
  setShowList: React.Dispatch<React.SetStateAction<boolean>>;
}


export interface MediaReportType {
  id: number;
  meta_title_en: string;
  meta_title_tc: string;
  meta_title_sc: string;
  meta_description_en: string;
  meta_description_tc: string;
  meta_description_sc: string;
  meta_image: string;
  category: string;
  title_en: string;
  title_sc: string;
  title_tc: string;
  description_en: string;
  description_sc: string;
  description_tc: string;
  image: File | undefined;
  image_url: string;
}

const options = [
  {
    label: "Passenger services",
    value: "1",
  },
  {
    label: "Ramp Services",
    value: "2",
  },
  {
    label: "Load Control and Flight Operations ",
    value: "3",
  },
];

const MediaReport: FC<IData> = ({ setShowList, initialData }) => {
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [isSave, setIsSave] = useState<boolean>(false);
  const [activeLang, setActiveLang] = useState<string>("en");
  const [data, setData] = useState<MediaReportType>(initialData);
  const [metaTitle_en, setMetaTitleEn] = useState<string>(initialData.meta_title_en || "");
  const [metaTitle_zh, setMetaTitleZh] = useState<string>(
    initialData.meta_title_sc || ""
  );
  const [metaTitle_cn, setMetaTitleCn] = useState<string>(initialData.meta_title_tc || "");
  const [metaDescription_en, setMetaDescriptionEn] = useState<string>(initialData.meta_description_en || "");
  const [metaDescription_zh, setMetaDescriptionZh] = useState<string>(initialData.meta_description_sc || "");
  const [metaDescription_cn, setMetaDescriptionCn] = useState<string>(initialData.meta_description_tc || "");
  const [metaImage, setMetaImage] = useState<string>(initialData.meta_image || "");
  const [metaImageFile, setMetaImageFile] = useState<File | undefined>();  
  const description_en_Ref = useRef();
  const change_data = (id: number, value: any, key: string) => {
    if (data) {
      if(key=='image'){
        const url=value?URL.createObjectURL(value):'';
        setData((prev)=>{
          return {
            ...prev,
            image:value,
            image_url:url
          }
        });
        return;
      }
      setData({ ...data, [key]: value });
    }
  };

  const changeLanguage = (code: string) => {
    setActiveLang(code);
  };
  const handle_submit = async () => {
    setIsSave(true);
    const formData = new FormData();
    formData.append('meta_title_en', metaTitle_en ?? '');
    formData.append('meta_title_tc', metaTitle_zh ?? '');
    formData.append('meta_title_sc', metaTitle_cn ?? '');
    formData.append('meta_description_en', metaDescription_en ?? '');
    formData.append('meta_description_tc', metaDescription_zh ?? '');
    formData.append('meta_description_sc', metaDescription_cn ?? '');
    if (metaImageFile) {
      formData.append('meta_image', metaImageFile);
    }
    formData.append('id', data?.id?.toString() ?? '');
    formData.append('title_en', data?.title_en ?? '');
    formData.append('title_tc', data?.title_tc ?? '');
    formData.append('title_sc', data?.title_sc ?? '');
    formData.append('description_en', data?.description_en ?? '');
    formData.append('description_tc', data?.description_tc ?? '');
    formData.append('description_sc', data?.description_sc ?? '');
    formData.append('category_id', data?.category ?? '');
    formData.append('page_type', 'media-report');
    if (data.image) {
      formData.append('image', data.image as any);
    }
    const url=data.id?'update-media-content':'media-content';
    await axios(endpointUrl + url, {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    })
      .then((res: any) => {
        if (res?.data?.status == true) {
          toast(res?.data?.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            type: "success",
          });
        } else {
          setErrorPopup(true);
          setMessage(res?.data?.message);
        }
        setIsSave(false);
      })
      .catch((err) => {
        setIsSave(false);
        setErrorPopup(true);
        setMessage(err?.data?.message);
      });
  }
  return (
    <div>
      <h2 className="text-lg text-black font-bold text-left my-5">
        Media Report
      </h2>
      
      <div className="flex flex-col lg:flex-row gap-10">
      
        <div className="">
        <div className="mb-5">
            <CommonLanguage
              changeLanguage={changeLanguage}
              activeLang={activeLang}
            />
          </div>
          <LabelAndSelect
            label="Category"
            value={data?.category}
            options={options}
            setValue={(value) => change_data(data?.id, value, "category")}
          />

          <div
            className={twJoin(
              activeLang == "en"
                ? ""
                : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
            )}
          >
            <div>
              <div>
                <div className="grid grid-cols-1">
                  <LabelAndTextbox
                    label="Title (EN)"
                    value={initialData?.title_en}
                    setValue={(value: any) =>
                      change_data(data?.id, value, "title_en")
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className={twJoin(
              activeLang == "tc"
                ? ""
                : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
            )}
          >
            <div>
              <div className="grid grid-cols-1">
                <LabelAndTextbox
                  label="Title (TC)"
                  value={initialData?.title_tc}
                  setValue={(value: any) =>
                    change_data(data?.id, value, "title_tc")
                  }
                />
              </div>
            </div>
          </div>
          <div
            className={twJoin(
              activeLang == "sc"
                ? ""
                : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
            )}
          >
            <div>
              <div className="grid grid-cols-1">
                <LabelAndTextbox
                  label="Title (SC)"
                  value={initialData?.title_sc}
                  setValue={(value: any) =>
                    change_data(data?.id, value, "title_sc")
                  }
                />
              </div>
            </div>
          </div>
          <div
            className={twJoin(
              activeLang == "en"
                ? ""
                : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
            )}
          >
            <div className="mt-5">
              <LabelAndEditor
                refId={description_en_Ref}
                label="Description (EN)"
                value={data?.description_en}
                setValue={(value) =>
                  change_data(data?.id, value, "description_en")
                }
              />
            </div>
          </div>
          <div
            className={twJoin(
              activeLang == "tc"
                ? ""
                : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
            )}
          >
            <div className="mt-5">
              <LabelAndEditor
                refId={description_en_Ref}
                label="Description (TC)"
                value={data?.description_tc}
                setValue={(value) =>
                  change_data(data?.id, value, "description_tc")
                }
              />
            </div>
          </div>

          <div
            className={twJoin(
              activeLang == "sc"
                ? ""
                : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
            )}
          >
            <div className="mt-5">
              <LabelAndEditor
                refId={description_en_Ref}
                label="Description (SC)"
                value={data?.description_sc}
                setValue={(value) =>
                  change_data(data?.id, value, "description_sc")
                }
              />
            </div>
          </div>

          <div>
            <LabelAndImage
              margin="mt-5"
              setFile={(value: any) => change_data(data?.id, value, "image")}
              label="Image"
              value={data?.image_url}
              setValue={(value: any) =>
                change_data(data?.id, value, "image_url")
              }
            />
          </div>
        </div>

        <div className="">
          <h2 className="text-lg text-black font-bold text-left my-5">SEO</h2>
          <SeoData
            showPageTitle={false}
            setPageTitleEn={() => 1}
            setPageTitleZh={() => 1}
            setPageTitleCn={() => 1}
            metatitle_en={metaTitle_en}
            metatitle_zh={metaTitle_zh}
            metatitle_cn={metaTitle_cn}
            setMetaTitleEn={setMetaTitleEn}
            setMetaTitleZh={setMetaTitleZh}
            setMetaTitleCn={setMetaTitleCn}
            metadescription_en={metaDescription_en}
            metadescription_zh={metaDescription_zh}
            metadescription_cn={metaDescription_cn}
            setMetaDescriptionEn={setMetaDescriptionEn}
            setMetaDescriptionZh={setMetaDescriptionZh}
            setMetaDescriptionCn={setMetaDescriptionCn}
            meta_image={metaImage}
            setMetaImage={setMetaImage}
            setMetaImageFile={setMetaImageFile}
          />
        </div>
      </div>
      <div className="mt-5 last:mb-0 flex items-center">
        <button
          className={classNames(
             isSave ? "opacity-50 pointer-events-none" : "",
            "flex items-center justify-center bg-vorpblue mr-2.5 text-white font-primary text-14 font-medium rounded-md py-[8.5px] px-3"
          )}
          onClick={() => handle_submit()}
        >
          <SaveIcon className="mr-2" /> Save
        </button>
        <button
          className="flex items-center justify-center bg-offwhite font-primary text-14 font-medium text-black2 rounded-md py-[8.5px] px-3"
          onClick={() => {
            setShowList(true);
          }}
        >
          <CancelIcon className="mr-2" color="#9E3039" />
          Cancel
        </button>
      </div>
    </div>
  );
};
export default MediaReport;
