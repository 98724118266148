import { ReactElement } from "react";
import { lazy, Suspense } from "react";
import BasicLayout from "../layouts/BasicLayout";
import { Outlet } from "react-router-dom";
import EmployeeList from "./views/EmployeeList";
import ActiveTimeReport from "../active-time-report/views/ActiveTimeReport";
import LeaveApplication from "../leave-application/views/LeaveApplication";
import OTList from "../ot-list/views/OTList";
import PayRollList from "../payroll/views/PayRollList";
import PayslipPrevies from "../payroll/views/PayslipPrevies";
import EmployeeLeaveAllowance from "../employeeLeaveAllowance/views/EmployeeLeaveAllowance";
import Equipments from "../equipments/views/Equipments";
import Employees from "../employees/views/Employees";
// import Monitoring from "../monitoring/views/Monitoring";
// import Attendance from "../attendance/views/Attendance";

export const employeeRoutes = [
  { id: 1, path: "", component: <Employees /> },
  { id: 2, path: "equipments", component: <Equipments /> },
  { id: 3, path: "leave-allowance", component: <EmployeeLeaveAllowance /> },
  { id: 4, path: "leave", component: <LeaveApplication /> },
  { id: 5, path: "active-time-report", component: <ActiveTimeReport /> },
  { id: 6, path: "ot-list", component: <OTList /> },
  { id: 7, path: "payroll", component: <PayRollList /> },
  
  // { id: 6, path: "payrollPreview", component: <PayslipPrevies /> },
];

const EmployeeListRoute = (props: any): ReactElement => {
  const data = props.loginData;
  const permissions = props.permissions;

  return (
    <BasicLayout userData={data} permissions={permissions}>
      <Outlet />
    </BasicLayout>
  );
};

export default EmployeeListRoute;
