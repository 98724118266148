import { redirectToLogin } from "./annoucement-sheet";
import { api, language } from "./api";
import authHeader from "./auth-header";
export type Header = {
  id: number;
  title_en: string;
  title_tc: string;
  title_sc: string;
  type: string;
};
export type FooterData = {
  footer: {
    footer_description_en: string;
    footer_description_tc: string;
    footer_description_sc: string;
    location_en: string;
    location_tc: string;
    location_sc: string;
    copy_right_text_en: string;
    copy_right_text_tc: string;
    copy_right_text_sc: string;
    logo_image: string;
    phone_en: string;
    phone_tc: string;
    phone_sc: string;
    email_en: string;
    email_tc: string;
    email_sc: string;
    company_image_one: any;
    company_image_two: any;
    company_image_three: any;
  },
  footer_links: IFooterLink[],
  footer_social: []
}
export type IFooterLink = {
  "id": number,
  "title_en": string,
  "title_tc": string,
  "title_sc": string,
  "link_en": string,
  "link_tc": string,
  "link_sc": string,
}
export type PaginationData = {
  page: number;
  limit: number | string | undefined;
  search: string | number;
  sort?: string;
};
export type MenuData = {
  menu_list: Header[];
};

export const footerApi = api.injectEndpoints({
  endpoints: (build) => ({
    getFooter: build.query<FooterData, void>({
      query: () => ({
        url: `footer-page`,
        headers: authHeader(),
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      providesTags: () => [{ type: "Footer" as const, id: "LIST" }],
    }),
    updateFooter: build.mutation<Header, Header>({
      query(body) {
        return {
          headers: authHeader(),
          url: `footer-page`,
          body,
          method: "POST",
        };
      },
    }),
    getFooterData: build.mutation<FooterData, void>({
      query(body) {
        return {
          headers: authHeader(),
          url: `footer-page`,
          body,
          method: "GET",
        };
      },
      invalidatesTags: ['Footer']
    }),
    deleteFooterData: build.mutation<FooterData, number>({
      query(body) {
        return {
          headers: authHeader(),
          url: `delete-footer-link`,
          body: {
            id: body
          },
          method: "POST",
        };
      },
    }),
  }),
});

export const {
  useGetFooterQuery,
  useUpdateFooterMutation,
  useGetFooterDataMutation,
  useDeleteFooterDataMutation
} = footerApi;

export const {
  endpoints: { getFooter, updateFooter, getFooterData,deleteFooterData },
} = footerApi;
