import { twJoin } from "tailwind-merge";
import { useState } from "react";
import JobApplicationSectionList from "../components/job-application/JobApplicationSectionList";
import JobApplication, { JobApplicationType } from "../components/JobApplication";
const JobApplicationPage = () => {
  const [data, setData] = useState<JobApplicationType>({
    id: 1,
    job_id: 1,

    source_type: 1,
    expected_salary: "",
    date_of_avaibility: "",
    reference_in_Pre_offer_notice: "",

    category: "",
    first_name: "",
    last_name: "",
    first_name_in_chinese: "",
    last_name_in_chinese: "",
    gender: 1,
    dob: "",
    phone: "",
    email: "",
    address: "",
    are_you_pr_of_hk: false,
    hkid_or_passport: "",
    chinese_commercial_code: "",
    have_right_abode_in_hk: false,
    require_hk_work_visa: false,
    emergency_contact_name: "",
    emergency_contact_relationship: "",
    emergency_contact_call_number: "",

    can_work_overtime: false,
    can_work_overtime_with_short_notice: false,
    can_work_rotating_shift: false,
    can_work_at_night: false,
    can_work_night_shift: false,
    can_work_on_sat_sun_holiday: false,
    convicted_or_accused_of_criminal: false,
    declared_bankrupt_or_invlolved: false,
    previously_issued_ara_permit: false,
    have_rejected_for_applying_airpot_restricted_area_permit: false,

    working_experiences: "",
    professional_qualification: "",
    academic_background: "",

    educations: "",
    english: "",
    cantonese: "",
    mandarin: "",
    other_language: "",
    other_language_name: "",

    are_family_or_friends_employed_by_our_company: "",
    are_family_member_related_with_satshk: "",
    declare: false,
    authorize: false,
    have_read: false,
    application_signature: undefined,
  });
  const [showList, setShowList] = useState<boolean>(true);

  return (
    <div
      className={twJoin(
        "text-left px-2 sm:px-4 py-4 bg-white sm:mt-[25px] mt-0 font-poppins text-13 font-semibold text-[#858795] tracking-[-0.03rem] w-full h-full rounded-xl pt-3 pb-8 sm:pt-3 sm:pb-9 "
      )}
    >
      {showList ? (
        <JobApplicationSectionList setShowList={setShowList} />
      ) : (
        <div>
          <JobApplication
            initialData={data}
            setValue={setData}
            setShowList={setShowList}
          />
        </div>
      )}
    </div>
  );
};
export default JobApplicationPage;
