import { FC } from "react";
import BasicLayout from "../layouts/BasicLayout";
import { Outlet } from "react-router-dom";
import HeaderComponent from "./views/HeaderComponent";

interface IHeaderRoute {
  loginData: any,
  permissions: any;
}

export const headerRoutes = [{ id: 1, path: "", component: <HeaderComponent /> }];

const HeaderRoute: FC<IHeaderRoute> = ({ loginData, permissions }) => {
  const data = loginData;

  return (
    <BasicLayout userData={data} permissions={permissions}>
      <div className="pt-0 pb-[58px]">
        <Outlet />
      </div>
    </BasicLayout>
  );
};

export default HeaderRoute;
