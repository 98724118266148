import { useEffect, useState } from "react";
import { twJoin } from "tailwind-merge";
import SeoData from "../../../components/SeoData/SeoData";
import BannerSingle, { IBannerData } from "../../../components/SeoData/BannerSingle";
import { useGetAwardsQuery } from "../../../app/services/company/award";
import classNames from "classnames";
import { CancelIcon, SaveIcon } from "../../../components/common/Icons";
import { ToastContainer, toast } from "react-toastify";
import CommonErrorPopup from "../../../components/common/CommonErrorPopup";
import { endpointUrl } from "../../../app/services/api";
import axios from "../../../axios";

const AwardList = () => {
    const [metaTitle_en, setMetaTitleEn] = useState<string>("");
    const [metaTitle_zh, setMetaTitleZh] = useState<string>("");
    const [metaTitle_cn, setMetaTitleCn] = useState<string>("");
    const [metaDescription_en, setMetaDescriptionEn] = useState<string>("");
    const [metaDescription_zh, setMetaDescriptionZh] = useState<string>("");
    const [metaDescription_cn, setMetaDescriptionCn] = useState<string>("");
    const [metaImage, setMetaImage] = useState<string>("");
    const [metaImageFile, setMetaImageFile] = useState<File | undefined>();
    const [banner, setBanner] = useState<IBannerData | undefined>();
    const [show, setShow] = useState<boolean>(true);
    const [errorPopup, setErrorPopup] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [isSave, setIsSave] = useState<boolean>(false);
    const { data: awards } = useGetAwardsQuery();
    useEffect(() => {
        if (awards) {
            const info = awards.award_page;
            setMetaTitleEn(info.meta_title_en);
            setMetaTitleZh(info.meta_title_tc);
            setMetaTitleCn(info.meta_title_sc);
            setMetaDescriptionEn(info.meta_description_en);
            setMetaDescriptionZh(info.meta_description_tc);
            setMetaDescriptionCn(info.meta_description_sc);
            setMetaImage(info.meta_image as any);
            setMetaImageFile(info.meta_image as any);
            setBanner({
                title_en: info.title_en,
                title_tc: info.title_tc,
                title_sc: info.title_sc,
                image: info.banner_desktop_image as any,
                image_url: info.banner_desktop_image
            })
        }
    }, [awards]);
    const handle_submit = async() => {
        setIsSave(true);
        const formData=new FormData();
        formData.append('meta_title_en', metaTitle_en ?? '');
        formData.append('meta_title_tc', metaTitle_zh ?? '');
        formData.append('meta_title_sc', metaTitle_cn ?? '');
        formData.append('meta_description_en', metaDescription_en ?? '');
        formData.append('meta_description_tc', metaDescription_zh ?? '');
        formData.append('meta_description_sc', metaDescription_cn ?? '');
        if(metaImageFile){
            formData.append('meta_image', metaImageFile);
        }
        formData.append('page_title_en', banner?.title_en ?? '');
        formData.append('page_title_tc', banner?.title_tc ?? '');
        formData.append('page_title_sc', banner?.title_sc ?? '');
        if(banner?.image){
            formData.append('banner_desktop_image', banner?.image);
        }
        await axios(endpointUrl + "update-award-page", {
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: formData,
        })
        .then((res: any) => {
            if (res?.data?.status == true) {
                toast(res?.data?.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    type: "success",
                });
            } else {
                setErrorPopup(true);
                setMessage(res?.data?.message);
            }
            setIsSave(false);
        })
        .catch((err) => {
            setErrorPopup(true);
            setMessage(err?.data?.message);
        });
    }
    return (
        <div
            className={twJoin(
                "px-2 sm:px-4 py-4 bg-white sm:mt-[25px] mt-0 font-poppins text-13 font-semibold text-[#858795] tracking-[-0.03rem] w-full h-full rounded-xl pt-3 pb-8 sm:pt-3 sm:pb-9 "
            )}
        >
            <ToastContainer className={"text-12 font-poppins font-normal"} />
            <CommonErrorPopup
                show={errorPopup}
                setShow={setErrorPopup}
                message={message}
                setMessage={setMessage}
            />
            <div className="flex">
                <div className="text-left w-1/2 mr-5">
                    <SeoData
                        showPageTitle={false}
                        setPageTitleEn={() => 1}
                        setPageTitleZh={() => 1}
                        setPageTitleCn={() => 1}
                        metatitle_en={metaTitle_en}
                        metatitle_zh={metaTitle_zh}
                        metatitle_cn={metaTitle_cn}
                        setMetaTitleEn={setMetaTitleEn}
                        setMetaTitleZh={setMetaTitleZh}
                        setMetaTitleCn={setMetaTitleCn}
                        metadescription_en={metaDescription_en}
                        metadescription_zh={metaDescription_zh}
                        metadescription_cn={metaDescription_cn}
                        setMetaDescriptionEn={setMetaDescriptionEn}
                        setMetaDescriptionZh={setMetaDescriptionZh}
                        setMetaDescriptionCn={setMetaDescriptionCn}
                        meta_image={metaImage}
                        setMetaImage={setMetaImage}
                        setMetaImageFile={setMetaImageFile}

                    />

                </div>
                <div className="w-1/2 text-left">
                    <BannerSingle banner={banner} setBanner={setBanner} />
                </div>
            </div>
            <div className="mt-5 last:mb-0 flex items-center">
                <button
                    className={classNames(
                        isSave ? "opacity-50 pointer-events-none" : "",
                        "flex items-center justify-center bg-vorpblue mr-2.5 text-white font-primary text-14 font-medium rounded-md py-[8.5px] px-3"
                    )}
                    onClick={() => handle_submit()}
                >
                    <SaveIcon className="mr-2" /> Save
                </button>
                <button
                    className="flex items-center justify-center bg-offwhite font-primary text-14 font-medium text-black2 rounded-md py-[8.5px] px-3"
                    onClick={() => {

                    }}
                >
                    <CancelIcon className="mr-2" color="#9E3039" />
                    Cancel
                </button>
            </div>
        </div>
    );
};
export default AwardList;
