import { FC, useEffect, useState } from "react";
import FooterSocialMediaTable from "./FooterSocialMediaTable";
import { ColumnFiltersState, PaginationState } from "@tanstack/react-table";
import { baseObj } from "../../utils/constants";
import { templateList } from "../../active-time-report/views/ActiveTimeReport";
import Button from "../../components/common/Button";
import FooterSocialMediaInput from "./FooterSocialMediaInput";
export interface ISocialMediaData {
  social_media: ISocialMedia[];
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  setDetailData: React.Dispatch<React.SetStateAction<ISocialMedia | undefined>>;
}
export interface ISocialMedia {
  id?: number;
  image: File | undefined;
  image_url: string | undefined;
  link: string | undefined;
}
const FooterSocialMedia: FC<ISocialMediaData> = ({
  social_media,
  setShow,
  setDetailData
}) => {
  // const [data, setData] = useState<ISocialMedia[]>(social_media);
  const [editRowData, setEditRowData] = useState<any>();
  const [showList, setShowList] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [viewCols, setCols] = useState<any>([]);
  const [isLayoutControlOpen, setIsLayoutControlOpen] = useState<boolean>(
    false
  );
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  let [localModuleId, setLocalModuleId] = useState<number>(0);
  const [data, setData] = useState<baseObj[]>(social_media);
  const [itemCount, setItemCount] = useState<number>(0);
  const [tempList, setTempList] = useState<any>(templateList);
  const [hiddenFields, setHiddenFields] = useState<string[]>([]);
  const [globalSearch, setGlobalSearch] = useState<string>("");
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20
  });
  useEffect(
    () => {
      if (social_media) {
        setData(social_media);
      }
    },
    [social_media]
  );
  const clear_data = () => {
    setDetailData({
      id: 0,
      link: "",
      image: undefined,
      image_url: ""
    });
  };
  return (
    <div>
      <div className="items-center xs:mt-1 sm:mt-1 flex justify-end mb-3">
        <Button
          label="+ Add New"
          onClick={() => {clear_data();setShow(true)}}
          type="button"
          variant="primary"
          customClass="py-[3px] my-1 px-2 focus:outline-none hover:bg-vorpmyanmar hover:border-vorpmyanmar hover:text-vorpblue 2xl:text-[14px] text-12 leading-5 font-medium text-white"
          size="base"
        />
      </div>
      <div className="text-left">
        <FooterSocialMediaTable
          // columnsData={columnsData}
          isLayoutControlOpen={isLayoutControlOpen}
          setIsLayoutControlOpen={setIsLayoutControlOpen}
          globalSearch={globalSearch}
          setGlobalSearch={setGlobalSearch}
          columnFilters={columnFilters}
          setColumnFilters={setColumnFilters}
          pagination={pagination}
          data={data}
          setData={setData}
          setPagination={setPagination}
          hiddenFields={hiddenFields ? hiddenFields : []}
          setHiddenFields={setHiddenFields}
          checkManagement={true}
          total={itemCount}
          localModuleId={localModuleId}
          // visArray={visArray}
          templateList={tempList}
          setCurrentView={() => 1}
          setCols={setCols}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          setEditRowData={setEditRowData}
          editRowData={editRowData}
          setShowList={setShow}
          setDetailData={setDetailData}
        />
      </div>
    </div>
  );
};
export default FooterSocialMedia;
