import { FC, useEffect, useRef, useState } from "react";
import { twJoin } from "tailwind-merge";
import LabelAndEditor from "../../components/SeoData/LabelAndEditor";
import LabelAndTextbox from "../../components/SeoData/LabelAndTextbox";
import LabelAndNumber from "../../components/SeoData/LabelAndNumber";
import { IAirlineInfo, ITitleDescription, IWhyChoose } from "../../app/services/home/home";
interface IData {
    activeLang: string;
    initialData: any;
    setValue: React.Dispatch<React.SetStateAction<ITitleDescription>>;
    title:string;
  }
const TitleDescriptionBox: FC<IData> = ({ activeLang, initialData,title,setValue }) => {
  const [data,setData]=useState<ITitleDescription>({
    description_en:'',
    description_tc:'',
    description_sc:'',
    sub_title_en:'',
    sub_title_tc:'',
    sub_title_sc:'',
    title_en:'',
    title_tc:'',
    title_sc:'',
  });
  const DescriptionRef_en: any = useRef(null);
  const DescriptionRef_tc: any = useRef(null);
  const DescriptionRef_sc: any = useRef(null);
  useEffect(()=>{
    if(initialData){
      setData(initialData);
    }
  },[initialData]);
  const change_data=(value:any,key:string)=>{
    if(data){
      setData({...data,[key]:value});
    }
    if(initialData){
      if(setValue){
        setValue({...initialData,[key]:value});
      }      
    }
  }
  return (
    <div>
      <h2 className="text-15 text-black font-bold text-left my-5">
        {title}
      </h2>
      <div className="mt-5">
        <div
          className={twJoin(
            activeLang == "en"
              ? ""
              : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
          )}
        >          
          <LabelAndTextbox
            label="Title (EN)"
            value={data?.title_en}
            setValue={(value)=>change_data(value,'title_en')}
          />
          <LabelAndEditor
            name="description"
            refId={DescriptionRef_en}
            label="Description (EN)"
            value={data?.description_en}
            setValue={(value)=>change_data(value,'description_en')}
          />          
        </div>
        <div
          className={twJoin(
            activeLang == "tc"
              ? ""
              : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
          )}
        >
            <LabelAndTextbox
            label="Title (Zh)"
            value={data?.title_tc}
            setValue={(value)=>change_data(value,'title_tc')}
          />
          <LabelAndEditor
            name="description"
            refId={DescriptionRef_tc}
            label="Description (Zh)"
            value={data?.description_tc}
            setValue={(value)=>change_data(value,'description_tc')}
          />          
        </div>
        <div
          className={twJoin(
            activeLang == "sc"
              ? ""
              : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
          )}
        >
             <LabelAndTextbox
            label="Title (CN)"
            value={data?.title_sc}
            setValue={(value)=>change_data(value,'title_sc')}
          />
          <LabelAndEditor
            name="description"
            refId={DescriptionRef_sc}
            label="Description (CN)"
            value={data?.description_sc}
            setValue={(value)=>change_data(value,'description_sc')}
          />         
        </div>
      </div>
    </div>
  );
};
export default TitleDescriptionBox;
