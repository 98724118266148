import { ReactElement } from "react";
import BasicLayout from "../layouts/BasicLayout";
import { Outlet } from "react-router-dom";
import OverViewPage from "./sections/OverviewPage";
import ResourcesPage from "./sections/ResourcesPage";
import DownloadItemPage from "./sections/DownloadItemPage";
export const MediaResourcesPagesList = [
  {
    id: 1,
    path: "#",
    component: <OverViewPage />,
  },
  {
    id: 2,
    path: "overview",
    component: <OverViewPage />,
  },
  {
    id: 2,
    path: "resources",
    component: <ResourcesPage />,
  },
  {
    id: 2,
    path: "download-items",
    component: <DownloadItemPage />,
  },
];
const MediaResourcesPageRoute = (props: any): ReactElement => {
  const data = props.loginData;
  const permissions = props.permissions;
  return (
    <BasicLayout>
      <Outlet />
    </BasicLayout>
  );
};
export default MediaResourcesPageRoute;
