import { FC, useEffect, useState } from "react";
import LabelAndImage from "../../components/SeoData/LabelAndImage";
import { ISocialMedia } from "./FooterSocialMedia";
import LabelAndTextbox from "../../components/SeoData/LabelAndTextbox";
import { twJoin } from "tailwind-merge";
import { CancelIcon, SaveIcon } from "../../components/common/Icons";
interface ISocialMediaInput {
    social: ISocialMedia | undefined,
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    handle_submit: (social: ISocialMedia|undefined) => void
    setSocialDetail: React.Dispatch<React.SetStateAction<ISocialMedia | undefined>>;
}
const FooterSocialMediaInput: FC<ISocialMediaInput> = ({
    social, setShow,handle_submit,
    setSocialDetail
}) => {
    const [data, setData] = useState<ISocialMedia | undefined>(social);
    const [image_url,setImageUrl]=useState<string>('');
    const change_data = (value: any, key: string) => {        
        if (data) {
            setData({ ...data, [key]: value });
            if(key=="image"){
                const url=value?URL.createObjectURL(value):'';     
                setImageUrl(url)
            }
        }
        if(social){
           // setSocialDetail({...social,[key]:value})
        }
    };
    useEffect(()=>{
        if(social){
            setData(social);            
            setImageUrl(social?.image_url??'');
        }
    },[social]);
    return <div>
        <LabelAndImage
            margin="mb-5"            
            label="Image"
            value={image_url}
            //setValue={()=>1}
            setValue={(value: any) => 1}
            setFile={(value: any) => change_data(value, 'image')}
        />
        <LabelAndTextbox
            label="Link"
            value={data?.link}
            setValue={(value: any) => change_data(value, 'link')}
        />
        <div className="mt-5 last:mb-0 flex items-center">
            <button
                className={twJoin(
                    "flex items-center justify-center bg-vorpblue mr-2.5 text-white font-primary text-14 font-medium rounded-md py-[8.5px] px-3"
                )}
                onClick={()=>handle_submit(data)}
            >
                <SaveIcon className="mr-2" /> Save
            </button>
            <button
                className="flex items-center justify-center bg-offwhite font-primary text-14 font-medium text-black2 rounded-md py-[8.5px] px-3"
                onClick={() => {
                    setShow(false);
                }}
            >
                <CancelIcon className="mr-2" color="#9E3039" />
                Cancel
            </button>
        </div>
    </div>
}
export default FooterSocialMediaInput;