
import { ReactElement } from 'react';
import { Outlet } from 'react-router-dom';
import BasicLayout from '../../layouts/BasicLayout';
import CompanyHistoryList from './CompanyHistoryList';

const CompanyHistoryRoute = (props:any): ReactElement => {
  const data=props.loginData;

  const permissions = props.permissions;

 return (
    <BasicLayout userData={data} permissions={permissions}>
      <CompanyHistoryList/>
      <Outlet />
    </BasicLayout>
  )
};

export default CompanyHistoryRoute;
