import { ReactElement } from "react";
import BasicLayout from "../layouts/BasicLayout";
import { Outlet } from "react-router-dom";
import OverViewPage from "./sections/OverviewPage";
import JobOpeningPage from "./sections/JobOpeningPage";
import JobApplicationPage from "./sections/JobApplicationsPage";
import JobCategoryPage from "./sections/JobCategoryPage";
import JobDepartmentPage from "./sections/JobDepartment";
export const jobOpportunitiesPagesList = [
  {
    id: 1,
    path: "#",
    component: <OverViewPage />,
  },
  {
    id: 2,
    path: "overview",
    component: <OverViewPage />,
  },
  {
    id: 3,
    path: "job-openings",
    component: <JobOpeningPage />,
  },
  {
    id: 4,
    path: "job-applications",
    component: <JobApplicationPage />,
  },
  {
    id: 4,
    path: "job-category",
    component: <JobCategoryPage />,
  },
  {
    id: 4,
    path: "job-department",
    component: <JobDepartmentPage />,
  },
];
const JobOpportunitiesPageRoute = (props: any): ReactElement => {
  const data = props.loginData;
  const permissions = props.permissions;
  return (
    <BasicLayout>
      <Outlet />
    </BasicLayout>
  );
};
export default JobOpportunitiesPageRoute;
