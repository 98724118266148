import { useEffect, useState } from "react";
import { baseObj } from "../../../utils/constants";
import AwardAndCertificateList from "./AwardAndCertificateList";
import { useGetAwardsQuery } from "../../../app/services/company/award";

const AwardsListPage = ({
}) => {
    const [data,setData]=useState<baseObj[]>([]);
   
    return <AwardAndCertificateList data_list={data} showData={true}/>;
}
export default AwardsListPage;