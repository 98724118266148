import { useState } from "react";
import { twJoin } from "tailwind-merge";
import CommonLanguage from "../../components/common/CommonLanguage";
import { CancelIcon, SaveIcon } from "../../components/common/Icons";
import classNames from "classnames";
import BannerSingle, {
  IBannerData,
} from "../../components/SeoData/BannerSingle";
import SeoData from "../../components/SeoData/SeoData";
import OverviewSection, { IOverview } from "../components/Overview";

const OverViewPage = () => {
    const [metaTitle_en, setMetaTitleEn] = useState<string>("");
    const [metaTitle_zh, setMetaTitleZh] = useState<string>("");
    const [metaTitle_cn, setMetaTitleCn] = useState<string>("");
    const [metaDescription_en, setMetaDescriptionEn] = useState<string>("");
    const [metaDescription_zh, setMetaDescriptionZh] = useState<string>("");
    const [metaDescription_cn, setMetaDescriptionCn] = useState<string>("");
    const [metaImage, setMetaImage] = useState<string>("");
    const [overview, setOverview] = useState<IOverview | undefined>();

    const [metaImageFile, setMetaImageFile] = useState<File | undefined>();
    const [banner, setBanner] = useState<IBannerData | undefined>();
    const [activeLang, setActiveLang] = useState<string>("en");
    const changeLanguage = (code: string) => {
        setActiveLang(code);
    };
    const [homeData, setHomeData] = useState<any>();
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { target } = event;
        setHomeData((prevState: any) => ({
            ...prevState,
            [target.name]: target.value
        }));
    };
    return (
      <div
        className={twJoin(
          "px-2 sm:px-4 py-4 bg-white sm:mt-[25px] mt-0 font-poppins text-13 font-semibold text-[#858795] tracking-[-0.03rem] w-full h-full rounded-xl pt-3 pb-8 sm:pt-3 sm:pb-9 "
        )}
      >
        <div className="flex">
          <div className="text-left w-1/2 mr-5">
            <SeoData
              showPageTitle={false}
              setPageTitleEn={() => 1}
              setPageTitleZh={() => 1}
              setPageTitleCn={() => 1}
              metatitle_en={metaTitle_en}
              metatitle_zh={metaTitle_zh}
              metatitle_cn={metaTitle_cn}
              setMetaTitleEn={setMetaTitleEn}
              setMetaTitleZh={setMetaTitleZh}
              setMetaTitleCn={setMetaTitleCn}
              metadescription_en={metaDescription_en}
              metadescription_zh={metaDescription_zh}
              metadescription_cn={metaDescription_cn}
              setMetaDescriptionEn={setMetaDescriptionEn}
              setMetaDescriptionZh={setMetaDescriptionZh}
              setMetaDescriptionCn={setMetaDescriptionCn}
              meta_image={metaImage}
              setMetaImage={setMetaImage}
              setMetaImageFile={setMetaImageFile}
            />
          </div>
          <div className="w-1/2 text-left">
            <BannerSingle banner={banner} setBanner={setBanner} />
          </div>
        </div>
        <div className="text-left">
          <h2 className="text-lg text-left font-bold mb-5 mt-5 text-black">
            Overview
          </h2>
          <div className="mt-5">
            <CommonLanguage
              changeLanguage={changeLanguage}
              activeLang={activeLang}
            />
            <OverviewSection
              overview={overview}
              activeLang={activeLang}
              setActiveLang={setActiveLang}
            />
          </div>
        </div>

        <div className="mt-5 last:mb-0 flex items-center">
          <button
            className={classNames(
              // saving ? "opacity-50 pointer-events-none" : "",
              "flex items-center justify-center bg-vorpblue mr-2.5 text-white font-primary text-14 font-medium rounded-md py-[8.5px] px-3"
            )}
            onClick={() => 1}
          >
            <SaveIcon className="mr-2" /> Save
          </button>
          <button
            className="flex items-center justify-center bg-offwhite font-primary text-14 font-medium text-black2 rounded-md py-[8.5px] px-3"
            onClick={() => {}}
          >
            <CancelIcon className="mr-2" color="#9E3039" />
            Cancel
          </button>
        </div>
      </div>
    );
};
export default OverViewPage;
