import { FC, useEffect, useState } from "react";
import LabelAndTextbox from "../../components/SeoData/LabelAndTextbox";
import { CancelIcon, SaveIcon } from "../../components/common/Icons";
import LabelAndImage from "../../components/SeoData/LabelAndImage";
import { twJoin } from "tailwind-merge";
import classNames from "classnames";
import LabelAndSelect from "../../components/SeoData/LabelAndSelect";
import { IServicePageData } from "../sections/ServicePageList";
import CommonLanguage from "../../components/common/CommonLanguage";

interface IData {
  initialData: IServicePageData;
  setValue: React.Dispatch<any>;
  setShowList: React.Dispatch<React.SetStateAction<boolean>>;
  handle_submit: (data: IServicePageData) => Promise<void>;
}
export interface ServiceType {
  id: number;
  category: string;
  title_en: string;
  title_sc: string;
  title_tc: string;
  icon: File | undefined;
  icon_url: string;
  image: File | undefined;
  image_url: string;
}

const options = [
  {
    label: "Passenger services",
    value: "passenger-service",
  },
  {
    label: "Ramp Services",
    value: "ramp-service",
  },
  {
    label: "Load Control and Flight Operations ",
    value: "flight-operations",
  },
];

const Service: FC<IData> = ({ setShowList, initialData,handle_submit }) => {
  const [activeLang, setActiveLang] = useState<string>("en");
  const [data, setData] = useState<IServicePageData>({
    id: 0,
    title_en: '',
    title_tc: '',
    title_sc: '',
    image: undefined,
    image_url: '',
    icon: undefined,
    icon_url: '',
    page_type: 'passenger-service'
  });
  const change_data = (id: number, value: any, key: string) => {
    if (data) {
      if(key=="icon"){
        const url=value?URL.createObjectURL(value):'';
        setData((prev)=>{
          return {
            ...prev,
            icon:value,
            icon_url:url
          }
        });
        return;
      }
      if(key=="image"){
        const url=value?URL.createObjectURL(value):'';
        setData((prev)=>{
          return {
            ...prev,
            image:value,
            image_url:url
          }
        });
        return;
      }
      setData({ ...data, [key]: value });
    }
  };
  const changeLanguage = (code: string) => {
    setActiveLang(code);
  };
  useEffect(()=>{
    if(initialData){
      setData({
        id:initialData.id,
        title_en:initialData.title_en,
        title_tc:initialData.title_tc,
        title_sc:initialData.title_sc,
        image:undefined,
        image_url:initialData.image as any,
        icon:undefined,
        icon_url:initialData.icon as any,
        page_type:initialData.page_type??"passenger-service"
      })
    }
  },[initialData])
  return (
    <div>
      <h2 className="text-lg text-black font-bold text-left my-5">Services</h2>
      <CommonLanguage
            changeLanguage={changeLanguage}
            activeLang={activeLang}
          />
      <div className="space-y-4 items-center my-5">
        <LabelAndSelect
          label="Category"
          value={data?.page_type}
          options={options}
          setValue={(value) => change_data(data?.id, value, "page_type")}
        />
      </div>
      <div
        className={twJoin(
          activeLang == "en"
            ? ""
            : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
        )}
      >
        <div>
          <div>
            <div className="grid grid-cols-1">
              <LabelAndTextbox
                label="Title (En)"
                value={initialData?.title_en}
                setValue={(value: any) =>
                  change_data(data?.id, value, "title_en")
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className={twJoin(
          activeLang == "tc"
            ? ""
            : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
        )}
      >
        <div>
          <div className="grid grid-cols-1">
            <LabelAndTextbox
              label="Title (TC)"
              value={initialData?.title_tc}
              setValue={(value: any) => change_data(data?.id, value, "title_tc")}
            />
          </div>
        </div>
      </div>
      <div
        className={twJoin(
          activeLang == "sc"
            ? ""
            : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
        )}
      >
        <div>
          <div className="grid grid-cols-1">
            <LabelAndTextbox
              label="Title (SC)"
              value={initialData?.title_sc}
              setValue={(value: any) =>
                change_data(data?.id, value, "title_sc")
              }
            />
          </div>
        </div>
      </div>
      <div>
        <LabelAndImage
          margin="mt-5"
          setFile={(value: any) => change_data(data?.id, value, "icon")}
          label="Icon"
          value={data?.icon_url}
          setValue={(value: any) => change_data(data?.id, value, "icon_url")}
        />
      </div>
      <div>
        <LabelAndImage
          margin="mt-5"
          setFile={(value: any) => change_data(data?.id, value, "image")}
          label="Image"
          value={data?.image_url}
          setValue={(value: any) => change_data(data?.id, value, "image_url")}
        />
      </div>
      <div className="mt-5 last:mb-0 flex items-center">
        <button
          className={classNames(
            // saving ? "opacity-50 pointer-events-none" : "",
            "flex items-center justify-center bg-vorpblue mr-2.5 text-white font-primary text-14 font-medium rounded-md py-[8.5px] px-3"
          )}
          onClick={() => handle_submit(data)}
        >
          <SaveIcon className="mr-2" /> Save
        </button>
        <button
          className="flex items-center justify-center bg-offwhite font-primary text-14 font-medium text-black2 rounded-md py-[8.5px] px-3"
          onClick={() => {
            setShowList(true);
          }}
        >
          <CancelIcon className="mr-2" color="#9E3039" />
          Cancel
        </button>
      </div>
    </div>
  );
};
export default Service;
