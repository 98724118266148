import { FC, useState } from "react";
import CustomDatePicker from "../common/FullWidthDatePicker";
interface ILabelAndDate {
  label: string;
  value: Date | undefined;
  setValue: (value: React.SetStateAction<Date|undefined>) => void;
}
const LabelAndDate: FC<ILabelAndDate> = ({ label, value, setValue }) => {
  const handleEditorChange = (event: any, editor: any) => {
    setValue(editor.getData());
  };
  return (
    <div className="mb-5 last:mb-0">
      <label className="text-black2 text-15 font-medium font-primary mb-[0.688rem] block">
        {label}
      </label>
      <CustomDatePicker
        placeholder={label}
        date={value}
        setDate={value => setValue(value)}
        
      />
    </div>
  );
};
export default LabelAndDate;
