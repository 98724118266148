import { redirectToLogin } from "../annoucement-sheet";
import { api } from "../api";
import authHeader from "../auth-header";
export interface IMediaData {
    "media_page": IMediaDataInfo,
    "media_section":any;
}
export interface IMediaDataInfo {
    "id": number,
    "sub_menu_id": number,
    "menu_id": number,
    "slug": string,
    "title_en": string,
    "title_tc": string,
    "title_sc": string,
    "meta_title_en": string,
    "meta_title_tc": string,
    "meta_title_sc": string,
    "meta_description_en": string,
    "meta_description_tc": string,
    "meta_description_sc": string,
    "meta_image": string,
    "banner_mobile_image": string,
    "banner_desktop_image": string,
}

export type TData = {
    data: [];
    total: number;
};
export type PaginationData = {
    page: number;
    limit: number | string | undefined;
    search: string | number;
    sort?: string;
    type?: string;
};
export const mediaApi = api.injectEndpoints({
    endpoints: (build) => ({
        getMediasData: build.query<IMediaData, string>({
            query: (page_type) => ({
                url: `fetch-media-page?page_type=${page_type}`,
                headers: authHeader()
            }),
            keepUnusedDataFor: 0,
            transformErrorResponse: (err) => {
                if (err?.status === 401) {
                    redirectToLogin();
                }
            },
            providesTags: () => [{ type: "MediaData" as const, id: "LIST" }]
        }),
        getMediaList: build.query<TData, PaginationData>({
            query: ({ limit, page, search,type }) => ({
                url: `media-content?per_page=${limit}&page=${page}&search=${search}&page_type=${type}`,
                headers: authHeader()
            }),
            keepUnusedDataFor: 0,
            transformErrorResponse: (err) => {
                if (err?.status === 401) {
                    redirectToLogin();
                }
            },
            providesTags: () => [{ type: "MediaData" as const, id: "LIST" }]
        }),
        getMediaInfo: build.mutation<TData, PaginationData>({
            query({ limit, page, search, type }) {
                return {
                    headers: authHeader(),
                    url: `media-content?type=${type}&per_page=${limit}&page=${page}&search=${search}`,
                    method: "GET",
                };
            },
            invalidatesTags: ['MediaData']
        }),
        deleteMedia: build.mutation<TData, number>({
            query(body) {
                return {
                    headers: authHeader(),
                    url: `delete-media-content`,
                    body: {
                        id: body
                    },
                    method: "POST",
                };
            },
            invalidatesTags: ['MediaData']
        }),
    })
});

export const { useGetMediasDataQuery,useGetMediaListQuery,useGetMediaInfoMutation,useDeleteMediaMutation } = mediaApi;

export const {
    endpoints: { getMediasData,getMediaList,getMediaInfo,deleteMedia }
} = mediaApi;
