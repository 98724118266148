import { FC, useEffect, useRef, useState } from "react";
import LabelAndTextbox from "../../components/SeoData/LabelAndTextbox";
import { AddIcon, CancelIcon, SaveIcon } from "../../components/common/Icons";
import LabelAndImage from "../../components/SeoData/LabelAndImage";
import { twJoin } from "tailwind-merge";
import LabelAndEditor from "../../components/SeoData/LabelAndEditor";
import classNames from "classnames";
import { WhyChooseUsInfo } from "../sections/WhyChooseUsSection";
import axios from "../../axios";
import { endpointUrl } from "../../app/services/api";
import { ToastContainer, toast } from "react-toastify";
import ErrorPopup from "../../components/ErrorPopup/ErrorPopup";

interface IData {
  activeLang: string;
  initialData: WhyChooseUsInfo;
  setValue: React.Dispatch<any>;
  setShowList: React.Dispatch<React.SetStateAction<boolean>>;
  setActiveLang: React.Dispatch<React.SetStateAction<string>>;
}
interface WhyChooseUs {
  id: number;
  title_en: string;
  image: File | undefined;
  image_url: string;
  title_zh: string;
  title_cn: string;
  card_title_en: string;
  card_title_zh: string;
  card_title_cn: string;
  card_description_en: string;
  card_description_zh: string;
  card_description_cn: string;
  card_image: File | undefined;
  card_image_url: string;
}
interface WhyChooseUsData {
  title: string;
  description: string;
}
const WhyChooseUs: FC<IData> = ({
  activeLang,
  setShowList,
  initialData,
  setValue,
  setActiveLang
}) => {
  const [isSave, setIsSave] = useState<boolean>(false);
  const [isRequired, setIsRequired] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [data, setData] = useState<WhyChooseUs>({
    id: 0,
    title_en: '',
    title_zh: '',
    title_cn: '',
    card_description_en: '',
    card_description_zh: '',
    card_description_cn: '',
    card_title_en: '',
    card_title_zh: '',
    card_title_cn: '',
    image: undefined,
    image_url: '',
    card_image: undefined,
    card_image_url: ''
  });
  const descriptionRef_en: any = useRef(null);
  const descriptionRef_zh: any = useRef(null);
  const descriptionRef_cn: any = useRef(null);
  const change_data = (value: any, key: string) => {
    if (data) {
      if (key == "image") {
        const url=value?URL.createObjectURL(value):'';
        setData((prev) => ({
          ...prev,
          image: value,
          image_url: url
        }))
        return;
      }
      if (key == "card_image") {
        const url=value?URL.createObjectURL(value):'';
        setData((prev) => ({
          ...prev,
          card_image: value,
          card_image_url: url
        }));
        return;
      }
      setData({ ...data, [key]: value });
    }
  };
  const check_validation=()=>{
    if(data?.title_en && data?.title_zh && data?.title_cn &&
      data?.card_title_en && data?.card_title_zh && data?.card_title_cn &&
      data?.card_description_en && data?.card_description_zh && data?.card_description_cn &&
      data?.image_url && data?.card_image_url
      ){
      return true;
    }
    return false;
  }
  const handle_submit = async () => {
    setIsSave(true);
    setIsRequired(false);
    if(check_validation()==false){
      setIsRequired(true);
      setIsSave(false);
      return;
    }
    const formData = new FormData();
    const image: any = data?.image;
    const icon: any = data?.card_image;
    formData.append("id", data.id.toString()??0);
    formData.append("title_en", data.title_en);
    formData.append("title_tc", data.title_zh);
    formData.append("title_sc", data.title_cn);
    formData.append("card_title_en", data.card_title_en);
    formData.append("card_title_tc", data.card_title_zh);
    formData.append("card_title_sc", data.card_title_cn);
    formData.append("card_description_en", data.card_description_en);
    formData.append("card_description_tc", data.card_description_zh);
    formData.append("card_description_sc", data.card_description_cn);
    formData.append("image", image);
    formData.append("icon", icon);
    const url = data?.id ? 'update-home-choose-us' : 'home-choose-us';
    await axios(endpointUrl + url, {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    })
      .then((res: any) => {
        if (res?.data?.status == true) {
          toast(res?.data?.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            type: "success",
          });
          setActiveLang('en');
        }else{
          setErrorPopup(true);
          setMessage(res?.data?.message)
        }
        setShowList(true);
        setIsSave(false);
      })
      .catch((err) => {
        console.log('error ', err);
        setIsSave(false);
        setErrorPopup(true);
        setMessage(err?.response?.data?.message)
      });
  }
  useEffect(() => {
    if (initialData) {
      setData({
        id: initialData?.id ?? 0,
        title_en: initialData?.title_en,
        title_zh: initialData?.title_tc,
        title_cn: initialData?.title_sc,
        card_description_en: initialData?.card_description_en,
        card_description_zh: initialData?.card_description_tc,
        card_description_cn: initialData?.card_description_sc,
        card_title_en: initialData?.card_title_en,
        card_title_zh: initialData?.card_title_tc,
        card_title_cn: initialData?.card_title_sc,
        image: undefined,
        image_url: initialData?.image,
        card_image: undefined,
        card_image_url: initialData?.icon
      });
    }
  }, [initialData])
  return (
    <div>
      <ToastContainer className={"text-12 font-poppins font-normal"} />
      <ErrorPopup
        descText="Error Message!"
        confirmBtnText="Yes, Close."
        show={errorPopup}
        setShow={setErrorPopup}
        message={message}
        setMessage={setMessage}
      />
      <h2 className="text-lg text-black font-bold text-left my-5">Why Choose Us</h2>
      <div
        className={twJoin(
          activeLang == "en"
            ? ""
            : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
        )}
      >

        <div>
          <div>
            <div className="grid grid-cols-1">
              <LabelAndTextbox
                label="Title (En)"
                value={data?.title_en}
                setValue={(value: any) => change_data(value, 'title_en')}
              />
              {(isRequired==true && !data?.title_en)?<span className="text-12 text-red-500 font-normal mb-5">Title is required.</span>:null}
              <LabelAndTextbox
                label="Card Title (EN)"
                value={data?.card_title_en}
                setValue={(value: any) => change_data(value, 'card_title_en')}
              />
              {(isRequired==true && !data?.card_title_en)?<span className="text-12 text-red-500 font-normal mb-5">Card Title is required.</span>:null}
              <LabelAndEditor
                refId={descriptionRef_en}
                label="Card Description (EN)"
                value={data?.card_description_en}
                setValue={(value: any) => change_data(value, 'card_description_en')}
              />
              {(isRequired==true && !data?.card_description_en)?<span className="text-12 text-red-500 font-normal">Card Description is required.</span>:null}
            </div>
          </div>
        </div>
      </div>
      <div
        className={twJoin(
          activeLang == "tc"
            ? ""
            : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
        )}
      >
        <div>

          <div className="grid grid-cols-1">
            <LabelAndTextbox
              label="Title (TC)"
              value={data?.title_zh}
              setValue={(value: any) => change_data(value, 'title_zh')}
            />
            {(isRequired==true && !data?.title_zh)?<span className="text-12 text-red-500 font-normal mb-5">Title is required.</span>:null}
            <LabelAndTextbox
              label="Card Title (TC)"
              value={data?.card_title_zh}
              setValue={(value: any) => change_data(value, 'card_title_zh')}
            />
            {(isRequired==true && !data?.card_title_zh)?<span className="text-12 text-red-500 font-normal mb-5">Card Title is required.</span>:null}
            <LabelAndEditor
              refId={descriptionRef_cn}
              label="Card Description (TC)"
              value={data?.card_description_zh}
              setValue={(value: any) => change_data(value, 'card_description_zh')}
            />
            {(isRequired==true && !data?.card_description_zh)?<span className="text-12 text-red-500 font-normal">Card Description is required.</span>:null}
          </div>
        </div>
      </div>
      <div
        className={twJoin(
          activeLang == "sc"
            ? ""
            : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
        )}
      >
        <div>
          <div className="grid grid-cols-1">
            <LabelAndTextbox
              label="Title (CN)"
              value={data?.title_cn}
              setValue={(value: any) => change_data(value, 'title_cn')}
            />
            {(isRequired==true && !data?.title_cn)?<span className="text-12 text-red-500 font-normal mb-5">Title is required.</span>:null}
            <LabelAndTextbox
              label="Card Title (CN)"
              value={data?.card_title_cn}
              setValue={(value: any) => change_data(value, 'card_title_cn')}
            />
            {(isRequired==true && !data?.card_title_cn)?<span className="text-12 text-red-500 font-normal mb-5">Card Title is required.</span>:null}
            <LabelAndEditor
              refId={descriptionRef_zh}
              label="Card Description (CN)"
              value={data?.card_description_cn}
              setValue={(value: any) => change_data(value, 'card_description_cn')}
            />
            {(isRequired==true && !data?.card_description_cn)?<span className="text-12 text-red-500 font-normal">Card Description is required.</span>:null}
          </div>
        </div>
      </div>
      <div>
        <LabelAndImage
          margin="mt-5"
          setFile={(value: any) => change_data(value, 'image')}
          label="Icon"
          value={data?.image_url}
        // setValue={(value: any) => change_data(value, 'image_url')}
        />
        {(isRequired==true && !data?.image_url)?<span className="text-12 text-red-500 font-normal my-5 block">Icon is required.</span>:null}
        <LabelAndImage
          margin="mt-5"
          setFile={(value: any) => change_data(value, 'card_image')}
          label="Image"
          value={data?.card_image_url}
        // setValue={(value: any) => change_data(value, 'card_image_url')}
        />
        {(isRequired==true && !data?.card_image_url)?<span className="text-12 text-red-500 font-normal my-5 block">Card Image is required.</span>:null}
      </div>
      <div className="mt-5 last:mb-0 flex items-center">
        <button
          className={classNames(
            isSave ? "opacity-50 pointer-events-none" : "",
            "flex items-center justify-center bg-vorpblue mr-2.5 text-white font-primary text-14 font-medium rounded-md py-[8.5px] px-3"
          )}
          onClick={() => handle_submit()}
        >
          <SaveIcon className="mr-2" /> Save
        </button>
        <button
          className="flex items-center justify-center bg-offwhite font-primary text-14 font-medium text-black2 rounded-md py-[8.5px] px-3"
          onClick={() => {
            setShowList(true);
          }}
        >
          <CancelIcon className="mr-2" color="#9E3039" />
          Cancel
        </button>
      </div>
    </div>
  );
};
export default WhyChooseUs;
